import { Output, Message, IdRefWidget } from "deblank-api-types"
import { selector, selectorFamily } from "recoil"
import { atomConversationsRecord } from "../Atom"
import { prefixRecoilKey } from "./Common"


const messagesInCurrentConversation = selector<Message[] | null>({
	key: prefixRecoilKey("messagesInCurrentConversation"),
	get: ({ get, }) => {
		const { activeConversationId, conversations, } = get(atomConversationsRecord)
		if (!activeConversationId) {
			return null
		}
		const currentConversation = conversations[activeConversationId]

		const messages = currentConversation
			? Object.values(conversations[activeConversationId].messages)
			: null

		return messages && messages.length
			? messages
			: null
	},
})

const someMessageIsLoading = selector<boolean>({
	key: prefixRecoilKey("someMessageIsLoading"),
	get: ({ get, }) => {
		const {
			activeConversationId, conversations,
		} = get(atomConversationsRecord)

		if (!activeConversationId) {
			return false
		}

		const currentConversations = conversations[activeConversationId]

		const someMessageIsLoading = currentConversations
			? Object.values(currentConversations.messages)
				.some(message => message.state === "loading")
			: false

		return someMessageIsLoading
	},
})

const messageById = selectorFamily<Message, string>({
	key: prefixRecoilKey("messageById"),
	get: (id) => ({ get, }) => {
		const { activeConversationId, conversations, } = get(atomConversationsRecord)
		const currentConversation = conversations[activeConversationId!]!

		return currentConversation.messages[id]
	},
})

const getMessageDataByMessageId = selector<Message & { outputs: Output[], }>({
	key: prefixRecoilKey("getTypesByMessageId"),
	get: ({ get, }) => {
		const { activeConversationId, conversations, } = get(atomConversationsRecord)
		const pendingMessageId = conversations[activeConversationId!]?.pendingMessage?.messageId
		const message = conversations[activeConversationId!]?.messages[pendingMessageId!]

		return {
			...message,
			outputs: message ? message.outputsIdsByIterations
				.flatMap(iteration => conversations[activeConversationId!]?.outputs![iteration.outputId])
				: [],
		}
	},
})

const isThisMessageLast = selectorFamily<boolean, string>({
	key: prefixRecoilKey("isThisMessageLast"),
	get: (id) => ({ get, }) => {
		const { activeConversationId, conversations, } = get(atomConversationsRecord)
		if (!activeConversationId) {
			return false
		}
		const currentConversation = conversations[activeConversationId]

		const messages = Object.values(currentConversation.messages)
		const lastMessage = messages[messages.length - 1]

		return lastMessage.id === id
	},
})

const getLastMessage = selector<Message | null>({
	key: prefixRecoilKey("getLastMessage"),
	get: ({ get, }) => {
		const { activeConversationId, conversations, } = get(atomConversationsRecord)
		if (!activeConversationId) {
			return null
		}
		const currentConversation = conversations[activeConversationId]
		const messages = currentConversation
			? Object.values(conversations[activeConversationId].messages)
			: null

		const lastMessage = messages ? messages.slice(-1) : null

		return lastMessage ? lastMessage[0] : null
	},
})

const getNumberOfViewsShowed = selectorFamily<number, {
	idRef: IdRefWidget,
	outputsIdsByIterationIndex: number,
}>({
	key: prefixRecoilKey("getNumberOfViewsShowed"),
	get: (params: {
		idRef: IdRefWidget,
		outputsIdsByIterationIndex: number,
	}) => ({ get, }) => {
		const { activeConversationId, conversations, } = get(atomConversationsRecord)
		const currentConversation = conversations[activeConversationId!]

		const message = currentConversation
			.messages[params.idRef.messageId]
			.outputsIdsByIterations[params.outputsIdsByIterationIndex]

		return message.numberOfViewsShowed
	},
})


export const selectorsMessages = {
	messagesInCurrentConversation,
	messageById,
	someMessageIsLoading,
	getLastMessage,
	getMessageDataByMessageId,
	getNumberOfViewsShowed,
	isThisMessageLast,
}
