/* eslint-disable max-lines-per-function */
import React, { useContext, useMemo } from "react"
import Styles from "./ViewImageModal.module.scss"
import CrossIcon from "deblank-common/src/assets/images/icon-library/x.svg"
import DownloadIcon from "deblank-common/src/assets/images/icon-library/download.svg"
import UnionIcon from "deblank-common/src/assets/images/icon-library/repeat-prompt.svg"
import { useWindowSize } from "@/hooks/useWindowsSize"
import ButtonIcon from "@/components/Buttons/ButtonIcon/ButtonIcon"
import classNames from "classnames"
import { m } from "framer-motion"
import { imageMotionProps, } from "./Animations"
import { DisplayWidgetType, SearchFrom } from "deblank-api-types"
import DribbbleIcon from "deblank-common/src/assets/images/external-brands/dribbble.svg"
import FreefloIcon from "deblank-common/src/assets/images/external-brands/freeflo.svg"
import { TrackConversationEventContext } from "../../TrackConversationEventProvider"
import usePresetActions from "@/components/Actions/usePresetActions"
import { useRecoilValue } from "recoil"
import { DownloadHelper } from "@/utils/exportHelper"
import { useEscKey } from "deblank-common/src/hooks/useEscKey"
import { selectorsCommon } from "@/recoil/ConversationsRecord/Selectors/Common"
import { settersCommon } from "@/recoil/ConversationsRecord/Setters/Common"
import ImageByWidgetType from "@/components/ImageByWidgetType/ImageByWidgetType"
import ImageManager from "@/utils/ImageManager"
import { selectorsConversations } from "@/recoil/ConversationsRecord/Selectors/Conversations"
import Tooltip from "@/components/Tooltip/Tooltip"


export const ViewImageModal = () => {

	const widget = useRecoilValue(selectorsCommon.imageModalWidget)
	const activeConversationId = useRecoilValue(selectorsConversations.activeConversationId)
	const widgetSelectData = useMemo(() => (widget), [])

	const setImageModalIdRef = settersCommon.useSetImageModalIdRef()
	const { trackConversationEvent, } = useContext(TrackConversationEventContext)
	const { isTablet, isMobile, } = useWindowSize()
	useEscKey({ onPress: () => handleOnClose(), })

	const { pinPresetAction, sendToChatPresetAction, } = usePresetActions({
		idRef: widgetSelectData!.idRef,
		widgetType: widgetSelectData!.type,
	})

	if (!widgetSelectData) {
		return null
	}

	const showMobile = isTablet || isMobile

	const searchFrom = widgetSelectData && widgetSelectData.type === DisplayWidgetType.Searches
		&& widgetSelectData.widgetData.searchFrom

	const handleOnClose = () => {
		setImageModalIdRef({ idRef: null, })
	}

	const handleOnSendToChat = () => {
		sendToChatPresetAction.onClick()
		handleOnClose()
		trackConversationEvent({
			eventName: "SendToChat",
			outputId: widgetSelectData.idRef.widgetId,
			widgetType: widgetSelectData.type,
			responseId: widgetSelectData.idRef.messageId,
		})
	}

	const handleOnPin = () => {
		pinPresetAction.onClick()
		handleOnClose()
	}

	const handleDownloadOnModal = async () => {
		const processImageUrl = async () => {
			if (widgetSelectData.type === DisplayWidgetType.Searches) {
				return widgetSelectData.widgetData.imageUrl
			} else if (widgetSelectData.type === DisplayWidgetType.Brands) {
				return widgetSelectData.widgetData.logoUrl
			}
			else {
				const getSignedUrl = await ImageManager.getImageUrl({
					imageFileName: widgetSelectData.widgetData.imageFileName,
					conversationId: activeConversationId!,
				})
				return getSignedUrl.signedUrl
			}
		}
		const imageUrl = await processImageUrl()

		await DownloadHelper.fromImage(imageUrl)

		trackConversationEvent({
			eventName: "Download",
			widgetType: widgetSelectData.type,
			responseId: widgetSelectData.idRef.messageId,
			outputId: widgetSelectData.idRef.widgetId,
		})
	}

	const commonActions = () => {
		return <ul className={Styles.actions_background_neutral}>
			{widgetSelectData.type !== DisplayWidgetType.Brands && <li>
				<Tooltip
					tooltipContent={<>Send to chat</>}
					customStyles={{ position: "bottom", }}
				>
					<ButtonIcon
						customStyles={{ variant: showMobile ? "secondary" : "ghost", }}
						type="button"
						onClick={handleOnSendToChat}
						iconSVGComponent={UnionIcon}
						disabled={sendToChatPresetAction.isDisabled}
					/>
				</Tooltip>
			</li>}
			{(!searchFrom || searchFrom !== SearchFrom.Dribbble)
				&& (widgetSelectData.type !== DisplayWidgetType.Brands) && <li >
					<Tooltip
						tooltipContent={<>Download</>}
						customStyles={{ position: "bottom", }}
					>
						<ButtonIcon
							customStyles={{ variant: showMobile ? "secondary" : "ghost", }}
							type="button"
							onClick={handleDownloadOnModal}
							iconSVGComponent={DownloadIcon}
						/>
					</Tooltip>
				</li >}
			<li >
				<Tooltip
					tooltipContent={<>Pin</>}
					customStyles={{ position: "bottom", }}
				>
					<ButtonIcon
						customStyles={{ variant: showMobile ? "secondary" : "ghost", }}
						type="button"
						onClick={handleOnPin}
						iconSVGComponent={pinPresetAction.icon}
						disabled={pinPresetAction.isDisabled}
					/>
				</Tooltip>
			</li>
		</ul>
	}

	const stylesHeader = classNames(Styles.header, {
		[Styles.header_position]: widgetSelectData.type !== DisplayWidgetType.Searches,
		[Styles.header_position_with_title]: showMobile || widgetSelectData.type === DisplayWidgetType.Searches,
	}
	)

	const renderSearchComponent = () => {
		return <div className={Styles.external_search_wrapper}>
			<div className={Styles.external_search_icon_wrapper}>
				{searchFrom === SearchFrom.Dribbble
					? <DribbbleIcon
						className={`${Styles.external_search_icon} disabled_custom_color`}
					/>
					: <FreefloIcon
						className={`${Styles.external_search_icon} disabled_custom_color`}
					/>
				}
			</div>
			<p className={Styles.external_search_text}>
				Images from <b className={Styles.external_search_text_bold}>{searchFrom}</b>
			</p>
		</div>
	}

	return (
		<>
			<div className={stylesHeader}>
				{!showMobile && searchFrom && renderSearchComponent()}
				{showMobile
					&& <nav className={Styles.actions}>
						{searchFrom
							&& <div className={Styles.external_search_icon_wrapper}>
								{searchFrom === SearchFrom.Dribbble
									? <DribbbleIcon
										className={`${Styles.external_search_icon} disabled_custom_color`} />
									: <FreefloIcon
										className={`${Styles.external_search_icon} disabled_custom_color`} />
								}
							</div>
						}
						{commonActions()}
					</nav>
				}
				<nav className={Styles.actions}>
					<ul>
						{!showMobile && commonActions()}
						<li>
							<ButtonIcon
								customStyles={{ variant: showMobile ? "secondary" : "ghost", }}
								type="button"
								onClick={handleOnClose}
								iconSVGComponent={CrossIcon}
							/>
						</li>
					</ul>
				</nav>
			</div>
			<div className={Styles.image_wrapper}>
				<m.figure className={Styles.image_inner}
					{...imageMotionProps}
				>
					<ImageByWidgetType widgetType={widgetSelectData.type}
						widgetDetails={widgetSelectData.widgetData}
					/>
				</m.figure>
			</div>
		</>
	)
}
