import React, { useContext } from "react"
import Styles from "./MockupImage.module.scss"
import Actions from "@/components/Actions/Actions"
import usePresetActions from "@/components/Actions/usePresetActions"
import classNames from "classnames"
import OpenIcon from "deblank-common/src/assets/images/icon-library/maximize.svg"
import DownloadIcon from "deblank-common/src/assets/images/icon-library/download.svg"
import { TrackConversationEventContext } from "@/pages/AssistantPage/TrackConversationEventProvider"
import {
	DisplayWidgetType, EntryMockupColorsResponse, EntryMockupFontsResponse, IdRefWidgetWithPaginationItem
} from "deblank-api-types"
import { DownloadHelper } from "@/utils/exportHelper"
import { settersCommon } from "@/recoil/ConversationsRecord/Setters/Common"
import { widgetAction } from "@/pages/AssistantPage/components/CurrentConversation/MessageList/WidgetSelector/WidgetsTypes"
import ImageByWidgetType from "@/components/ImageByWidgetType/ImageByWidgetType"
import { selectorsConversations } from "@/recoil/ConversationsRecord/Selectors/Conversations"
import { useRecoilValue } from "recoil"
import ImageManager from "@/utils/ImageManager"


const MockupImage = (props: {
	widgetType: DisplayWidgetType,
	widgetDetails: EntryMockupColorsResponse | EntryMockupFontsResponse,
	idRef: IdRefWidgetWithPaginationItem,
}) => {

	const [actionsOpen, setActionsOpen,] = React.useState(false)

	const { trackConversationEvent, } = useContext(TrackConversationEventContext)
	const setImageModal = settersCommon.useSetImageModalIdRef()
	const activeConversationId = useRecoilValue(selectorsConversations.activeConversationId)

	const { pinPresetAction, sendToChatPresetAction, } = usePresetActions({
		idRef: props.idRef,
		widgetType: props.widgetType,
	})

	const handleOpenModal = () => {
		trackConversationEvent({
			eventName: "OpenImageModal",
			widgetType: props.widgetType,
			responseId: props.idRef.widgetId,
		})
		setImageModal({ idRef: props.idRef, })
	}

	const handleDownload = async () => {
		const getSignedUrl = await ImageManager.getImageUrl({
			imageFileName: props.widgetDetails.imageFileName,
			conversationId: activeConversationId!,
		})

		await DownloadHelper.fromImage(getSignedUrl.signedUrl)

		trackConversationEvent({
			eventName: "Download",
			widgetType: props.widgetType,
			responseId: props.idRef.widgetId,
			outputId: props.idRef.messageId,
		})
	}

	const actionList: widgetAction[] = [
		{
			text: "Open",
			icon: OpenIcon,
			onClick: handleOpenModal,
		},
		pinPresetAction,
		sendToChatPresetAction,
		{
			text: "Download",
			icon: DownloadIcon,
			onClick: () => handleDownload(),
		},
	]

	const wrapperClasses = classNames(
		Styles.wrapper,
		"actions_handler",
		{
			[Styles.actions_open]: actionsOpen,
		}
	)

	return (
		<figure className={wrapperClasses} >
			<button type="button"
				onClick={handleOpenModal}
				className={Styles.image}
			>
				<ImageByWidgetType widgetType={props.widgetType}
					widgetDetails={props.widgetDetails}
				/>
			</button>

			<Actions
				actionList={actionList}
				onSetActionsOpen={setActionsOpen}
				customStyles={{
					actionType: "images",
				}}
			/>
		</figure>
	)
}

export default MockupImage
