import { DebugEntry, PendingNotification, PendingMessage } from "deblank-api-types"
import { selector } from "recoil"
import { atomConversationsRecord } from "../Atom"
import { prefixRecoilKey } from "./Common"


const debugInCurrentConversation = selector<DebugEntry[] | null>({
	key: prefixRecoilKey("debugInCurrentConversation"),
	get: ({ get, }) => {
		const { activeConversationId, conversations, debugConversations, } = get(atomConversationsRecord)
		if (!activeConversationId) {
			return null
		}
		const debugData = debugConversations[conversations[activeConversationId].id].entries

		return debugData
			? debugData
			: null
	},
})

const showDebugPanel = selector<boolean>({
	key: prefixRecoilKey("showDebugPanel"),
	get: ({ get, }) => {
		const { activeConversationId, debugConversations, showNewChatView, } = get(atomConversationsRecord)
		if (!activeConversationId) {
			return false
		}
		if (showNewChatView) {
			return false
		}

		const debugData = debugConversations[activeConversationId]

		return debugData && debugData.entries.length > 0
	},
})

const currentPendingMessage = selector<PendingMessage | null>({
	key: prefixRecoilKey("currentPendingMessages"),
	get: ({ get, }) => {
		const { activeConversationId, conversations, } = get(atomConversationsRecord)
		if (!activeConversationId) {
			return null
		}
		return conversations[activeConversationId]
			? conversations[activeConversationId].pendingMessage
			: null
	},
})

const currentPendingNotifications = selector<PendingNotification[] | null>({
	key: prefixRecoilKey("currentPendingNotifications"),
	get: ({ get, }) => {
		const { activeConversationId, conversations, } = get(atomConversationsRecord)
		if (!activeConversationId) {
			return null
		}
		return conversations[activeConversationId]
			? conversations[activeConversationId].pendingNotifications
			: null
	},
})


export const selectorsAssistantResponseTools = {
	debugInCurrentConversation,
	currentPendingMessage,
	currentPendingNotifications,
	showDebugPanel,
}
