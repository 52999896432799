import {
	DisplayWidgetType, EntryFontsResult, EntryMockupFontsResult,
	Output, OutputsById
} from "deblank-api-types"

export const extractSlugsFromWidgets = (params: {
	widgets: OutputsById,
}) => {
	const widgets: Output[] = Object.values(params.widgets)

	const fontWidgets = widgets.filter((output) => (
		output.type === DisplayWidgetType.Fonts
	)) as EntryFontsResult[]

	const getFontWidgetsSlugs = fontWidgets
		.flatMap((widget) => (widget.data.pages))
		.flatMap((page) => (page.results))
		.map((result) => (result.slug))

	const getFontWidgetsMetadataSlugs = fontWidgets
		.flatMap((widget) => (widget.metadata))
		.flatMap((metadata) => (metadata.baseFont ? [metadata.baseFont,] : []))


	const mockupFontsWidgets = widgets.filter((output) => (
		output.type === DisplayWidgetType.MockupFonts
	)) as EntryMockupFontsResult[]

	const getMockupFontsWidgetsSlugs = mockupFontsWidgets
		.flatMap((widget) => (widget.metadata))
		.flatMap((metadata) => ([metadata.bodyFontSlug, metadata.titleFontSlug,]))

	const getMockupFontMetadataSlugs = mockupFontsWidgets
		.flatMap((widget) => (widget.metadata))
		.flatMap((metadata) => ([metadata.bodyFontSlug, metadata.titleFontSlug,]))


	const uniqueSlugs = new Set([
		...getFontWidgetsSlugs,
		...getFontWidgetsMetadataSlugs,
		...getMockupFontsWidgetsSlugs,
		...getMockupFontMetadataSlugs,
	])

	const slugs = Array.from(uniqueSlugs)

	return slugs.length > 0 ? slugs : null
}
