import { Variants, HTMLMotionProps } from "framer-motion"

const variants: Variants = {
	hidden: {
		opacity: 0,
		scale: 0.6,
	},
	visible: {
		opacity: 1,
		scale: 1,
	},
	exit: {
		opacity: 0,
	},
}

const transition: HTMLMotionProps<"section">["transition"] = {
	ease: [0.4, 0.14, 0.3, 1,],
	duration: 0.2,
}

export const historyItemMotionProps = {
	variants: variants,
	initial: "hidden",
	animate: "visible",
	exit: "exit",
	transition: transition,
	layout: true,
}
