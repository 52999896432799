import { useCallback, useEffect, useMemo, useState } from "react"
import { useRequestState } from "./useRequestState"
import { EmptyData, GetDataResponse, GetDataResponseWithoutError, emptyData } from "./useGetDataTypes"
import { getResponse } from "./useGetDataUtils"

type Params<T, Q> = {
	getData: (query: Q) => Promise<T>,
	key?: string,
	query: Q,
	onRegisterError: (error: unknown) => void,
}

export const useGetData = <T, Q>(params: Params<T, Q>
): GetDataResponse<T> => {
	const [mutationCount, setMutationCount,] = useState<number>(0)
	const [response, setResponse,] = useState<T | EmptyData>(emptyData)
	const {
		error,
		loading,
		handleSuccess,
		handleStart,
		handleError,
		handleClear,
	} = useRequestState()

	useEffect(() => {
		let cancel = false;
		(async () => {
			try {
				handleStart()
				const r = await params.getData(params.query)
				if (!cancel) {
					setResponse(r)
					handleSuccess()
				}
			} catch (e) {
				params.onRegisterError(e)
				handleError()
			}
		})()
		return () => {
			cancel = true
			setResponse(emptyData)
			handleClear()
		}
	}, [params.key ? params.key : params.query, mutationCount,])

	const mutate = useCallback(() => {
		setMutationCount(s => s + 1)
	}, [])

	return useMemo(() => {
		return getResponse({
			error,
			loading,
			mutate,
			response,
		})
	}, [error, loading, mutate, response,])
}

export const useGetDataThrowError
	= <T, Q>(params: Params<T, Q>): GetDataResponseWithoutError<T> => {
		const ret = useGetData(params)

		if (ret.error) {
			throw new Error("Error requesting: Unknown")
		} else {
			return ret
		}
	}
