/* eslint-disable jsx-a11y/heading-has-content */
/* eslint-disable max-lines-per-function */
import React, { useContext, useEffect, useMemo, useRef, } from "react"
import Styles from "./FontsPlayground.module.scss"
import CrossIcon from "deblank-common/src/assets/images/icon-library/x.svg"
import DownloadIcon from "deblank-common/src/assets/images/icon-library/download.svg"
import UnionIcon from "deblank-common/src/assets/images/icon-library/repeat-prompt.svg"
import ButtonIcon from "@/components/Buttons/ButtonIcon/ButtonIcon"
import {
	DisplayWidgetType, EntryFontsResult, Pairing
} from "deblank-api-types"
import { TrackConversationEventContext } from "../../TrackConversationEventProvider"
import { useSanitizedStates } from "@/hooks/useSanitazedTexts"
import TextPlayground from "./TextPlayground/TextPlayground"
import { useRecoilValue } from "recoil"
import { selectorsFontCache } from "@/recoil/FontCache/Selectors"
import { getBodyDefaulState, getSubtitleDefaulState, getTitleDefaulState } from "./FontsPlaygroundHelper"
import { DownloadHelper } from "@/utils/exportHelper"
import usePresetActions from "@/components/Actions/usePresetActions"
import { produce } from "immer"
import { selectorsCommon } from "@/recoil/ConversationsRecord/Selectors/Common"
import { selectorsWidgets } from "@/recoil/ConversationsRecord/Selectors/Widgets"
import { settersAssistantResponseTools } from "@/recoil/ConversationsRecord/Setters/AssistantResponseTools"
import { settersCommon } from "@/recoil/ConversationsRecord/Setters/Common"
import Tooltip from "@/components/Tooltip/Tooltip"

export const FontsPlayground = () => {

	const widget = useRecoilValue(selectorsCommon.fontPlaygroundWidget)
	const widgetSelectData = useMemo(() => (widget), [])
	const output = useRecoilValue(selectorsWidgets.outputById(widgetSelectData!.idRef.widgetId)) as EntryFontsResult

	const widgetType = DisplayWidgetType.Fonts
	const ref = useRef<HTMLDivElement>(null)
	const titleRef = useRef<HTMLHeadingElement>(null)
	const { trackConversationEvent, } = useContext(TrackConversationEventContext)
	const fontDetails = useRecoilValue(selectorsFontCache.fontFromCache(widgetSelectData?.widgetData.slug))
	const baseFontDetails = useRecoilValue(selectorsFontCache.fontFromCache(widgetSelectData?.metadata.baseFont || undefined))
	const setFontPlaygroundIdRef = settersCommon.useSetFontPlaygroundIdRef()

	const updateNotification = settersAssistantResponseTools.useDispatchUpdateNotifications()
	const { pinPresetAction, sendToChatPresetAction, } = usePresetActions({
		idRef: widgetSelectData!.idRef,
		widgetType: widgetType,
	})

	useEffect(() => {
		if (titleRef.current) {
			const range = document.createRange()
			const sel = window.getSelection()

			range.setStart(titleRef.current.childNodes[0], widgetSelectData!.metadata.title.length)
			range.collapse(true)

			sel?.removeAllRanges()
			sel?.addRange(range)


			titleRef.current.focus()
		}
	}, [])

	const { bodyProps, titleProps, subtitleProps,
		handleBodyOnBlur, handleTitleOnBlur,
		handleSubtitleOnBlur, bodyValue, subtitleValue, titleValue,
	} = useSanitizedStates(
		widgetSelectData!.metadata.title,
		widgetSelectData!.metadata.description,
		widgetSelectData!.metadata.bodyText,
	)



	if (!widgetSelectData) {
		return null
	}

	const handleOnClose = () => {
		const newOutput = produce(output, (draft) => {
			draft.metadata.title = titleValue
			draft.metadata.description = subtitleValue
			draft.metadata.bodyText = bodyValue
		})
		updateNotification({
			notifications: [{
				outputs: [newOutput,],
			},],
		})
		setFontPlaygroundIdRef({ idRef: null, })
	}

	const handleDownload = async () => {
		await DownloadHelper.fromRefTemplate(ref)
		trackConversationEvent({
			eventName: "Download",
			widgetType: widgetType,
			responseId: widgetSelectData.idRef.messageId,
			outputId: widgetSelectData.idRef.widgetId,
		})
	}

	const commonActions = () => {
		return (
			<ul className={Styles.common_actions_container}>
				<li>
					<Tooltip
						tooltipContent={<>Send to chat</>}
						customStyles={{ position: "bottom", }}
					>
						<ButtonIcon
							customStyles={{ variant: "ghost", }}
							type="button"
							onClick={() => {
								trackConversationEvent({
									eventName: "SendToChat",
									outputId: widgetSelectData.idRef.widgetId,
									widgetType: DisplayWidgetType.Fonts,
									responseId: widgetSelectData.idRef.messageId,
								})
								sendToChatPresetAction.onClick()
								handleOnClose()
							}}
							iconSVGComponent={UnionIcon}
							disabled={sendToChatPresetAction.isDisabled}
						/>
					</Tooltip >
				</li>
				<li>
					<Tooltip
						tooltipContent={<>Download</>}
						customStyles={{ position: "bottom", }}
					>
						<ButtonIcon
							customStyles={{ variant: "ghost", }}
							type="button"
							onClick={handleDownload}
							iconSVGComponent={DownloadIcon}
						/>
					</Tooltip>
				</li>
				<li>
					<Tooltip
						tooltipContent={<>Pin</>}
						customStyles={{ position: "bottom", }}
					>
						<ButtonIcon
							customStyles={{ variant: "ghost", }}
							type="button"
							onClick={() => {
								pinPresetAction.onClick()
								handleOnClose()
							}}
							iconSVGComponent={pinPresetAction.icon}
							disabled={pinPresetAction.isDisabled}
						/>
					</Tooltip>
				</li>
			</ul>
		)
	}



	return (

		<>
			<nav className={Styles.actions_container}>
				<ul className={Styles.action_list}>
					{commonActions()}
					<li className={Styles.close_button_container}>
						<ButtonIcon
							customStyles={{ variant: "ghost", }}
							type="button"
							onClick={handleOnClose}
							iconSVGComponent={CrossIcon}
						/>
					</li>
				</ul>
			</nav>
			<section className={Styles.wrapper}>
				<div className={Styles.container}>
					<div className={Styles.content} ref={ref}>
						<TextPlayground
							messageId={widgetSelectData.idRef.messageId}
							font={widgetSelectData.metadata.pairing === Pairing.paragraph
								? baseFontDetails!
								: fontDetails!
							}
							defaultState={getTitleDefaulState(widgetSelectData.metadata.pairing === Pairing.paragraph
								? baseFontDetails!.metadata.weightsCode
								: fontDetails!.metadata.weightsCode)}
						>
							<h2
								ref={titleRef}
								className={`${Styles.extend_styles} ${Styles.editable_text}`}
								style={widgetSelectData.metadata.pairing === Pairing.paragraph
									? baseFontDetails!.fontCSSProperties
									: fontDetails!.fontCSSProperties}
								contentEditable={true}
								{...titleProps}
								spellCheck={false}
								onBlur={(e) => {
									trackConversationEvent({
										eventName: "ChangeText",
										textType: "title",
										fontSlug: widgetSelectData.widgetData.slug,
									})
									handleTitleOnBlur(e)
								}}
							/>
						</TextPlayground>

						<TextPlayground
							messageId={widgetSelectData.idRef.messageId}
							font={widgetSelectData.metadata.pairing === Pairing.title
								? baseFontDetails!
								: fontDetails!}
							defaultState={getSubtitleDefaulState(widgetSelectData.metadata.pairing === Pairing.title
								? baseFontDetails!.metadata.weightsCode
								: fontDetails!.metadata.weightsCode)}
						>
							<h3
								className={`${Styles.extend_styles} ${Styles.editable_text}`}
								style={widgetSelectData.metadata.pairing === Pairing.title
									? baseFontDetails!.fontCSSProperties
									: fontDetails!.fontCSSProperties}
								contentEditable={true}
								{...subtitleProps}
								spellCheck={false}
								onBlur={(e) => {
									trackConversationEvent({
										eventName: "ChangeText",
										textType: "subtitle",
										fontSlug: widgetSelectData.widgetData.slug,
									})
									handleSubtitleOnBlur(e)
								}}
							/>
						</TextPlayground>

						<TextPlayground
							messageId={widgetSelectData.idRef.messageId}
							font={widgetSelectData.metadata.pairing === Pairing.title
								? baseFontDetails!
								: fontDetails!}
							defaultState={getBodyDefaulState(widgetSelectData.metadata.pairing === Pairing.title
								? baseFontDetails!.metadata.weightsCode
								: fontDetails!.metadata.weightsCode)}
						>
							<p
								className={`${Styles.body} ${Styles.editable_text}`}
								style={widgetSelectData.metadata.pairing === Pairing.title
									? baseFontDetails!.fontCSSProperties
									: fontDetails!.fontCSSProperties}
								contentEditable={true}
								spellCheck={false}
								{...bodyProps}
								onBlur={(e) => {
									trackConversationEvent({
										eventName: "ChangeText",
										textType: "body",
										fontSlug: widgetSelectData.widgetData.slug,
									})
									handleBodyOnBlur(e)
								}}
							/>
						</TextPlayground>
					</div>
				</div>
			</section>
		</>
	)
}
