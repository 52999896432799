/* eslint-disable max-lines-per-function */
import React from "react"
import { CommonWidgetMockupsProps } from "../WidgetMockupsTypes"
import MockupImage from "./MockupImage/MockupImage"
import LoadingMockups from "@/assets/loadingMockups.svg"
import Spinner, { SpinnerSize } from "@/components/Spinner/Spinner"
import Styles from "./WidgetConversationMockups.module.scss"
import { IdRefType } from "deblank-api-types"

const WidgetConversationMockups = (props: {
	activePageIndex: number,
} & CommonWidgetMockupsProps) => {

	const currentPage = props.widgetDetails.data.pages.length > 0 && props.widgetDetails.data.pages[props.activePageIndex].results

	return (
		<>
			{(currentPage)
				? currentPage.map((mockupsPage, index) => (
					<MockupImage
						key={mockupsPage.id}
						widgetType={props.widgetDetails.type}
						widgetDetails={mockupsPage}
						idRef={{
							type: IdRefType.WidgetWithPaginationItem,
							messageId: props.idRef.messageId,
							widgetId: props.idRef.widgetId,
							widgetPageIndex: props.activePageIndex,
							widgetItemIndex: index,
							widgetItemId: mockupsPage.id,
						}}
					/>
				))
				: <figure className={Styles.loading_container}>
					<span className={Styles.loading_spinner}>
						<Spinner darkMode size={SpinnerSize.Medium} />
					</span>
					<LoadingMockups className="disabled_custom_color" />
				</figure>
			}

		</>)
}

export default WidgetConversationMockups
