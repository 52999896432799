import React, { useContext, } from "react"
import Styles from "./ExplanationCard.module.scss"
import CopyIcon from "deblank-common/src/assets/images/icons/copy.svg"
import CheckIcon from "deblank-common/src/assets/images/icons/check.svg"
import { useCopyToClipboard } from "@/hooks/useCopyToClipboard"
import { TrackConversationEventContext } from "@/pages/AssistantPage/TrackConversationEventProvider"
import { ColorUtils } from "@/utils/ColorUtils"
import { IdRefWidgetWithPaginationItem, EntryColorsExplanationResponse, DisplayWidgetType } from "deblank-api-types"


type Props = {
	idRef: IdRefWidgetWithPaginationItem,
	widgetData: EntryColorsExplanationResponse,
	type: DisplayWidgetType,
}

const ExplanationCard = (props: Props) => {

	const { copyToClipboard, contentCopied, } = useCopyToClipboard()
	const { trackConversationEvent, } = useContext(TrackConversationEventContext)

	function handleCopyToClipboard(color: string, id: string) {
		trackConversationEvent({
			eventName: "CopyColor",
			colorCode: color,
		})
		copyToClipboard({ text: color, id: id, })
	}

	return (
		<div className={Styles.wrapper}>
			<article className={Styles.container}>
				<div className={Styles.palette}>
					{props.widgetData.palette.map((color, index) => (
						<div
							key={index}
							className={Styles.color_item}
							style={{ "--current_color": color, } as React.CSSProperties}
						>
							<button
								type="button"
								className={Styles.copy_button}
								onClick={() => handleCopyToClipboard(color, index.toString())}
								style={{ "--fg_color": ColorUtils.getForegroundColor(color), } as React.CSSProperties}
							>
								{contentCopied === index.toString()
									? <CheckIcon />
									: <CopyIcon />
								}
							</button>
						</div>
					))}

				</div>

				<div className={Styles.explanation}>
					{props.widgetData.explanation}
				</div>
			</article>

		</div>
	)
}

export default ExplanationCard
