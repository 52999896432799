import React from "react"
import Styles from "./PinnedListEmptyMessage.module.scss"
import DotsIcon from "deblank-common/src/assets/images/icon-library/more.svg"
import PinIcon from "deblank-common/src/assets/images/icon-library/pin.svg"
import OpenExternalIcon from "deblank-common/src/assets/images/icon-library/repeat-prompt.svg"
import DownloadIcon from "deblank-common/src/assets/images/icon-library/download.svg"

const PinnedListEmptyMessage = () => {
	return (
		<>
			<p className={Styles.message_description}>
				<strong>
					There are no pinned elements yet.
				</strong>
				Pin your favorite inspiration to save it in your personal space.
			</p>

			<div className={Styles.wrapper_actions}>
				<div className={Styles.content_pallete_button}>
					<div className={Styles.fake_color_pallete}>
						<span className={Styles.fake_color_pallete_item} />
						<span className={Styles.fake_color_pallete_item} />
						<span className={Styles.fake_color_pallete_item} />
						<span className={Styles.fake_color_pallete_item} />
					</div>
					<div className={Styles.fake_button}>
						<DotsIcon />
					</div>
				</div>
				<div className={Styles.fake_menu}>
					<span className={Styles.fake_menu_item}>
						<PinIcon />
						Pin
					</span>
					<span className={Styles.fake_menu_item}>
						<OpenExternalIcon />
						Send to chat
					</span>
					<span className={Styles.fake_menu_item}>
						<DownloadIcon />
						Download
					</span>
				</div>
			</div>

		</>
	)
}

export default PinnedListEmptyMessage
