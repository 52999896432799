/* eslint-disable max-statements */
/* eslint-disable max-len */
/* eslint-disable max-lines-per-function */
import React from "react"
import * as PIXI from "pixi.js"
import { ColorPackagingMockupID } from "deblank-api-types"
import { MockupUtils } from "@/utils/mockupBuilder/utils/MockupUtils"
import { GetMockupImageProps, Mockup, OpenAiCosts } from "@/utils/mockupBuilder/types/MockupTypes"
import { ColorsTemplateMockupParams } from "@/utils/mockupBuilder/types/ColorsMockupTypes"
import { Mockup_SVG } from "./Mockup_SVG"
import { ColorUtils } from "@/utils/mockupBuilder/utils/ColorUtils"
import BagLights from "./images/bag_lights.png"
import BagMask from "./images/bag_mask.png"
import BagShadows from "./images/bag_shadows.png"
import Placeholder from "./images/placeholder.svg"

// ------- DESCRIPTION ------- //
//- Figma link: https://www.figma.com/file/DYWqSJfZnrSqdOmweKf6Qk/FontMuse-(new)?type=design&node-id=822-1351&mode=design&t=QDo8iGvib0i6nIP2-0

const SCALE = 0.5

const mockupWidth = 3000 * SCALE
const mockupHeight = 2250 * SCALE

const mockupId = ColorPackagingMockupID.colors_packaging_mockup_1

type ColorAssignmentType = {
	design: { background: string, colorA: string, colorB: string, textColor: string, },
	background: { color: string, },
}

const getColors = async (params: {
	mockupParams: ColorsTemplateMockupParams,
	minColors: number,
}): Promise<{ colorAssignment: ColorAssignmentType, openAiCosts: OpenAiCosts, }> => {
	const { getBackgroundColor, getColorPalette, } = ColorUtils
	const { getGroupedColors, type, ...colorPalette } = params.mockupParams
	const { usage, ...colorP } = colorPalette
	const colors = Object.values(colorP).filter(c => c)

	const backgroundColor = getBackgroundColor({
		colorPalette: colors,
		backgroundLuminance: 1,
	})

	const designColorPalette = getColorPalette({
		colors: colors,
		backgroundColor: backgroundColor,
		minColors: params.minColors,
	})

	const colorPaletteWithoutContrastColor = designColorPalette.filter((color) => color !== backgroundColor)

	const palette = await getGroupedColors({
		palette: colorPaletteWithoutContrastColor,
		imageSvg: Mockup_SVG.simpleDesignSvg,
		backgroundColor: backgroundColor,
		numberOfGroups: params.minColors,
	},)

	return {
		colorAssignment: {
			design: { background: palette.groupA!, colorA: palette.groupC!, colorB: palette.groupB!, textColor: backgroundColor, },
			background: { color: backgroundColor, },
		},
		openAiCosts: {
			completion_cost: palette.usage!.completion_cost,
			prompt_cost: palette.usage!.prompt_cost,
		},
	}
}

const getMockupImage = async (props: GetMockupImageProps<ColorsTemplateMockupParams>) => {

	const { app, semaphore, } = props

	const ret = await semaphore.add(async () => {

		const { colorAssignment, openAiCosts, } = await getColors({
			mockupParams: props.params,
			minColors: 4,
		})

		const [bagShadowsSprite,
			bagLightsSprite,
			bagMaskSprite,] = await MockupUtils.loadAssets([
				BagShadows, BagLights, BagMask,])

		// Create mockup container
		const mockupContainer = new PIXI.Container()
		app.stage.addChild(mockupContainer)

		mockupContainer.width = mockupWidth
		mockupContainer.height = mockupHeight

		const backgroundShape = MockupUtils.drawRect({
			color: colorAssignment.background.color,
			width: mockupWidth,
			height: mockupHeight,
		})

		mockupContainer.addChild(backgroundShape)

		//Add bag
		const bagDesignContainer = new PIXI.Container()
		const bagDesignTexture = await PIXI.Texture.fromURL(Mockup_SVG.designSVG(colorAssignment.design))
		const bagDesignSprite = new PIXI.Sprite(bagDesignTexture)
		bagDesignContainer.addChild(bagDesignSprite)

		const bagProjection = MockupUtils.project({
			app: app,
			container: bagDesignContainer,
			projectionPoints: {
				topLeft: { x: 918.38 * SCALE, y: 632.6 * SCALE, },
				topRight: { x: 1731.2 * SCALE, y: 330.87 * SCALE, },
				bottomRight: { x: 2195.97 * SCALE, y: 1583.7 * SCALE, },
				bottomLeft: { x: 1383.15 * SCALE, y: 1885.45 * SCALE, },
			},
		})

		//Add mask
		const bagContainerMask = MockupUtils.mask({
			mask: bagMaskSprite,
			elements: [bagProjection,],
		})

		mockupContainer.addChild(bagContainerMask)

		//Add shadowSprite
		bagShadowsSprite.blendMode = PIXI.BLEND_MODES.MULTIPLY
		mockupContainer.addChild(bagShadowsSprite)

		//Add lightsSprite
		bagLightsSprite.blendMode = PIXI.BLEND_MODES.SCREEN
		mockupContainer.addChild(bagLightsSprite)


		const imageData = MockupUtils.exportImage({
			app: app,
			container: mockupContainer,
			height: mockupHeight,
			width: mockupWidth,
			imageCosts: openAiCosts,
		})

		return imageData
	})

	return ret!
}

export const Color_Mockup_Packaging_1: Mockup<ColorsTemplateMockupParams> = {
	placeholder: <Placeholder className="disabled_custom_color" />,
	getMockupImage,
	mockupId,
	description: `The mockup showcases a package for jelly beans with a distinctive, abstract pattern. The package features the brand name "Bies" prominently in large, bold text, with "jelly beans" written beneath it in a smaller font. The design includes rounded, organic shapes that create a dynamic and playful look.
	 The package is set against a background that complements the design with similar abstract shapes.`,
}
