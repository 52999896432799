import { selector } from "recoil"
import { atomConversationsRecord } from "../Atom"
import {
	DisplayWidgetType, IdRefWidgetWithPaginationItem,
	EntryColorsResponse, ImageResponse, FontsMetadata, FontResponse, SearchResponse,
	EntryMockupColorsResponse, EntryMockupFontsResponse,
	EntryBrandsResponse
} from "deblank-api-types"

export const prefixRecoilKey = (key: string) => (`Conversations_Record_${key}`)

const fontPlaygroundWidget = selector<{
	idRef: IdRefWidgetWithPaginationItem,
	widgetData: FontResponse,
	metadata: FontsMetadata,
} | null>({
	key: prefixRecoilKey("fontPlaygroundIdRef"),
	get: ({ get, }) => {
		const { activeConversationId, conversations, } = get(atomConversationsRecord)
		if (!activeConversationId) {
			return null
		}
		const currentConversation = conversations[activeConversationId]
		if (!currentConversation || !currentConversation.fontPlaygroundIdRef) {
			return null
		}
		const widget = currentConversation.outputs![currentConversation.fontPlaygroundIdRef.widgetId]

		if (widget.type !== DisplayWidgetType.Fonts) {
			return null
		}
		const widgetModalIdRef = currentConversation.fontPlaygroundIdRef!
		return {
			idRef: widgetModalIdRef,
			widgetData: widget.data.pages[widgetModalIdRef.widgetPageIndex].results[widgetModalIdRef.widgetItemIndex],
			metadata: widget.metadata,
		}
	},
})

const colorPlaygroundWidget = selector<{ widgetData: EntryColorsResponse, idRef: IdRefWidgetWithPaginationItem, } | null>({
	key: prefixRecoilKey("colorPlaygroundIdRef"),
	get: ({ get, }) => {
		const { activeConversationId, conversations, } = get(atomConversationsRecord)
		if (!activeConversationId) {
			return null
		}
		const currentConversation = conversations[activeConversationId]
		if (!currentConversation || !currentConversation.colorsPlaygroundIdRef) {
			return null
		}
		const widget = currentConversation.outputs![currentConversation.colorsPlaygroundIdRef.widgetId]

		if (widget.type !== DisplayWidgetType.Colors) {
			throw new Error(`The current widget type ${widget.type} is not suportted in Colors Playground`)
		}
		const widgetModalIdRef = currentConversation.colorsPlaygroundIdRef!
		return ({
			idRef: widgetModalIdRef,
			widgetData: widget.data.pages[widgetModalIdRef.widgetPageIndex].results[widgetModalIdRef.widgetItemIndex],
		})
	},
})

const imageModalWidget = selector<(
	{ type: DisplayWidgetType.Images, idRef: IdRefWidgetWithPaginationItem, widgetData: ImageResponse, }
	| { type: DisplayWidgetType.Searches, idRef: IdRefWidgetWithPaginationItem, widgetData: SearchResponse, }
	| { type: DisplayWidgetType.MockupFonts, idRef: IdRefWidgetWithPaginationItem, widgetData: EntryMockupFontsResponse, }
	| { type: DisplayWidgetType.MockupColors, idRef: IdRefWidgetWithPaginationItem, widgetData: EntryMockupColorsResponse, }
	| { type: DisplayWidgetType.Brands, idRef: IdRefWidgetWithPaginationItem, widgetData: EntryBrandsResponse, }
) | null>({
	key: prefixRecoilKey("imageModalIdRef"),
	get: ({ get, }) => {

		const { activeConversationId, conversations, } = get(atomConversationsRecord)
		if (!activeConversationId) {
			return null
		}
		const currentConversation = conversations[activeConversationId]
		if (!currentConversation || !currentConversation.imageModalIdRef) {
			return null
		}
		const widget = currentConversation.outputs![currentConversation.imageModalIdRef.widgetId]

		if (widget.type !== DisplayWidgetType.Images
			&& widget.type !== DisplayWidgetType.Searches
			&& widget.type !== DisplayWidgetType.MockupFonts
			&& widget.type !== DisplayWidgetType.MockupColors
			&& widget.type !== DisplayWidgetType.Brands
		) {
			throw new Error(`The current widget type ${widget.type} is not suportted in Image Modal`)
		}
		const widgetModalIdRef = currentConversation.imageModalIdRef!

		if (widget.type === DisplayWidgetType.Images) {
			return ({
				type: DisplayWidgetType.Images,
				idRef: widgetModalIdRef,
				widgetData: widget.data.pages[widgetModalIdRef.widgetPageIndex].results[widgetModalIdRef.widgetItemIndex],
			})
		} else if (widget.type === DisplayWidgetType.Searches) {
			return ({
				type: DisplayWidgetType.Searches,
				idRef: widgetModalIdRef,
				widgetData: widget.data.results[widgetModalIdRef.widgetItemIndex],
			})
		} else if (widget.type === DisplayWidgetType.MockupFonts) {
			return ({
				type: DisplayWidgetType.MockupFonts,
				idRef: widgetModalIdRef,
				widgetData: widget.data.pages[widgetModalIdRef.widgetPageIndex].results[widgetModalIdRef.widgetItemIndex],
			})
		} else if (widget.type === DisplayWidgetType.MockupColors) {
			return ({
				type: DisplayWidgetType.MockupColors,
				idRef: widgetModalIdRef,
				widgetData: widget.data.pages[widgetModalIdRef.widgetPageIndex].results[widgetModalIdRef.widgetItemIndex],
			})
		} else if (widget.type === DisplayWidgetType.Brands) {
			return ({
				type: DisplayWidgetType.Brands,
				idRef: widgetModalIdRef,
				widgetData: widget.data.pages[widgetModalIdRef.widgetPageIndex].results[widgetModalIdRef.widgetItemIndex],
			})

		} else {
			return null
		}
	},
})


export const selectorsCommon = {
	colorPlaygroundWidget,
	fontPlaygroundWidget,
	imageModalWidget,
}
