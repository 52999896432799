/* eslint-disable max-len */
import React, { useContext } from "react"
import Styles from "./ExternalLinkModal.module.scss"
import Button from "@/components/Buttons/Button/Button"
import { TrackConversationEventContext } from "@/pages/AssistantPage/TrackConversationEventProvider"
import ModalWrapper from "@/components/ModalWrapper/ModalWrapper"
import { useEscKey } from "deblank-common/src/hooks/useEscKey"

type Props = {
	closeModal: () => void,
	url: string,
}

export const ExternalLinkModal = (props: Props) => {
	const { trackConversationEvent, } = useContext(TrackConversationEventContext)

	const handleOnClickExternalLink = () => {
		window.open(props.url, "_blank", "noopener,noreferrer")
		trackConversationEvent({
			eventName: "ClickOnVisitExternalLink",
			link: props.url,
		})
		props.closeModal()
	}

	const handleOnClickCancel = () => {
		trackConversationEvent({
			eventName: "ClickOnCancelExternalLink",
			link: props.url,
		})
		props.closeModal()
	}

	useEscKey({ onPress: handleOnClickCancel, })

	return <ModalWrapper hasFooter={true} backgroundWithBlur={true}>
		<div className={Styles.wrapper}>
			<section className={Styles.modal}>
				<section className={Styles.texts}>
					<p className={Styles.title}>You are about to leave Deblank</p>
					<p className={Styles.body}>
						{"You're about to visit an external site that we do not manage or endorse, and we cannot guarantee its security."}
					</p>
					<section className={Styles.link_wrapper}>
						<p className={Styles.body}>URL:</p>
						<p className={Styles.link} >{props.url}</p>
					</section>

				</section>
				<section className={Styles.actions}>
					<Button
						onClick={() => handleOnClickCancel()}
						type="button"
						customStyles={{ variant: "ghost", size: "large", }}
					>
						Cancel
					</Button>
					<Button
						onClick={() => handleOnClickExternalLink()}
						type="button"
						customStyles={{ variant: "primary", size: "large", }}
					>
						Visit site
					</Button>
				</section>
			</section>
		</div>
	</ModalWrapper>
}
