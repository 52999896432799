/* eslint-disable max-lines-per-function */
import React, { useContext } from "react"
import Styles from "./ColorItem.module.scss"
import { useCopyToClipboard } from "@/hooks/useCopyToClipboard"
import CopyIcon from "deblank-common/src/assets/images/icon-library/copy.svg"
import CheckIcon from "deblank-common/src/assets/images/icon-library/check.svg"
import DragIcon from "deblank-common/src/assets/images/icon-library/drag.svg"
import DeleteIcon from "deblank-common/src/assets/images/icon-library/Delete.svg"
import ShadesIcon from "deblank-common/src/assets/images/icon-library/shades.svg"
import { ColorUtils } from "@/utils/ColorUtils"
import classNames from "classnames"
import { ColorWithId, } from "../ColorsPlayground"
import { getColorName } from "@/utils/ColorNameUtils"
import { ColorInputPicker } from "@/components/ColorPicker/ColorPicker"
import { useClickOutside } from "deblank-common/src/hooks/useClickOutside"
import ButtonIcon from "@/components/Buttons/ButtonIcon/ButtonIcon"
import AddIcon from "deblank-common/src/assets/images/icon-library/plus.svg"
import { AnimatePresence, Reorder, motion, useDragControls } from "framer-motion"
import { shadesModalItemMotionProps, shadesModalMotionProps } from "./Animations"
import Tooltip from "@/components/Tooltip/Tooltip"
import { TrackConversationEventContext } from "@/pages/AssistantPage/TrackConversationEventProvider"
import { useWindowSize } from "@/hooks/useWindowsSize"


type Props = {
	id: string,
	colorValue: ColorWithId,
	deleteDisabled: boolean,
	onAddColorOnTheLeft?: () => void,
	onAddColorOnTheRight?: () => void,
	onDeleteColor: () => void,
	onChangeColor: (newColor: string) => void,
	onChangeColorShade: (newColor: string) => void,
}

const ColorItem = (props: Props) => {
	const colorValue = props.colorValue.value.color
	const { trackConversationEvent, } = useContext(TrackConversationEventContext)
	const { copyToClipboard, contentCopied, } = useCopyToClipboard()
	const controls = useDragControls()
	const windowSize = useWindowSize()
	const isMobile = windowSize.isTablet || windowSize.isMobile

	const {
		isOpen: colorPickerOpen,
		elementRef: colorPickerContainerRef,
		toggleIsOpen: toggleColorPickerOpen,
	} = useClickOutside()

	const {
		isOpen: shadesModalOpen,
		elementRef: shadesModalContainerRef,
		toggleIsOpen: toggleShadesModalOpen,
	} = useClickOutside()


	const handleCopy = (color: string, id: string) => {
		trackConversationEvent({
			eventName: "CopyColor",
			colorCode: color,
		})
		copyToClipboard({ text: color, id: id, })
	}

	const handleViewShades = () => {
		toggleShadesModalOpen()
	}


	const renderShadesModal = () => {

		return (
			<motion.div
				className={Styles.shades_modal}
				{...shadesModalMotionProps}
			>
				{props.colorValue.value.shades.map((shade, index) => {
					const shadeClass = classNames({
						[Styles.shade_color]: true,
						[Styles.is_selected]: shade === colorValue,
					})
					return (
						<motion.button
							{...shadesModalItemMotionProps}
							key={index}
							className={shadeClass}
							onClick={() => {
								props.onChangeColorShade(shade)
								toggleShadesModalOpen()
							}}
							style={{
								backgroundColor: shade,
								"--fg_color": ColorUtils.getForegroundColor(shade),
							} as React.CSSProperties}

						/>
					)
				})}

			</motion.div>
		)
	}

	const renderColorPicker = () => {
		return <ColorInputPicker
			colorHex={colorValue}
			onChange={props.onChangeColor}
			positionStyles={{
				bottom: isMobile ? "16px" : "86px",
				left: "50%",
				transform: "translateX(-50%)",
			}}
		/>
	}

	const renderAddButons = () => {
		return (
			<>
				{props.onAddColorOnTheLeft && <div className={`${Styles.add_button_container} ${Styles.left}`}>
					<ButtonIcon
						type="button"
						onClick={props.onAddColorOnTheLeft}
						iconSVGComponent={AddIcon}
						customStyles={{
							variant: "secondary",
						}}
					/>
				</div>}
				{props.onAddColorOnTheRight && <div className={`${Styles.add_button_container} ${Styles.right}`}>
					<ButtonIcon
						type="button"
						onClick={props.onAddColorOnTheRight}
						iconSVGComponent={AddIcon}
						customStyles={{
							variant: "secondary",
						}}
					/>
				</div>}
			</>
		)
	}

	const itemClasses = classNames({
		[Styles.wrapper]: true,
		[Styles.is_active]: colorPickerOpen || shadesModalOpen,
	})

	return (
		<Reorder.Item
			value={props.colorValue}
			id={props.id}
			initial={{ opacity: 0, }}
			animate={{
				opacity: 1,
				transition: { duration: 0.1, },
			}}
			exit={{ opacity: 0, transition: { duration: 0.1, }, }}
			className={Styles.item_wrapper}
			dragListener={false}
			dragControls={controls}
		>
			<motion.div
				ref={shadesModalContainerRef}
				className={itemClasses}
				style={{
					backgroundColor: colorValue,
				}}
				initial={false}
			>
				{renderAddButons()}

				<div className={Styles.actions_container}>
					<Tooltip
						tooltipContent={"Copy"}
						customStyles={{
							position: isMobile ? "top" : "right",
							animationDelay: "long",
						}}
					>
						<button
							type="button"
							className={Styles.button_action}
							onClick={() => handleCopy(colorValue, `color-${colorValue}`)}
							style={{
								"--fg_color": ColorUtils.getForegroundColor(colorValue),
							} as React.CSSProperties}
						>
							{contentCopied === `color-${colorValue}` ? <CheckIcon /> : <CopyIcon />}
						</button>
					</Tooltip>
					<Tooltip
						tooltipContent={"Shades"}
						customStyles={{
							position: isMobile ? "top" : "right",
							animationDelay: "long",
						}}
					>
						<button
							type="button"
							className={Styles.button_action}
							onClick={() => handleViewShades()}
							style={{
								"--fg_color": ColorUtils.getForegroundColor(colorValue),
							} as React.CSSProperties}
						>
							<ShadesIcon />
						</button>
					</Tooltip>
					{!isMobile && <Tooltip
						tooltipContent={"Drag"}
						customStyles={{
							position: "right",
							animationDelay: "long",
						}}
					>
						<button
							type="button"
							onPointerDown={(e) => controls.start(e)}
							className={classNames({
								[Styles.button_action]: true,
								[Styles.button_action_can_drag]: true,
							})}
							style={{
								"--fg_color": ColorUtils.getForegroundColor(colorValue),
							} as React.CSSProperties}
						>
							<DragIcon />
						</button>
					</Tooltip>}
					<Tooltip
						tooltipContent={"Delete"}
						customStyles={{
							position: isMobile ? "top" : "right",
							animationDelay: "long",
						}}
					>
						<button
							type="button"
							className={Styles.button_action}
							style={{
								"--fg_color": ColorUtils.getForegroundColor(colorValue),
							} as React.CSSProperties}
							disabled={props.deleteDisabled}
							onClick={props.onDeleteColor}
						>
							<DeleteIcon />
						</button>
					</Tooltip>
				</div>

				<div className={Styles.color_picker_container} ref={colorPickerContainerRef}>
					{colorPickerOpen && renderColorPicker()}
					<Tooltip
						tooltipContent={"Color picker"}
						customStyles={{
							position: "top",
							animationDelay: "long",
						}}
					>
						<button
							className={Styles.button_color_picker}
							style={{
								"--fg_color": ColorUtils.getForegroundColor(colorValue),
							} as React.CSSProperties}
							onClick={toggleColorPickerOpen}
						>
							<span className={Styles.color_name}>{getColorName(colorValue)}</span>
							<span className={Styles.color_hex}>{colorValue}</span>
						</button>
					</Tooltip>
				</div>

				<AnimatePresence>
					{shadesModalOpen && renderShadesModal()}
				</AnimatePresence>
			</motion.div>

		</Reorder.Item >
	)
}

export default ColorItem
