import React from "react"
import Styles from "./ModalWrapper.module.scss"
import { m } from "framer-motion"
import { usePreventScroll } from "deblank-common/src/hooks/usePreventScroll"
import classNames from "classnames"

type Props = {
	children: React.ReactNode,
	hasFooter: boolean,
	backgroundWithBlur: boolean,
}

const ModalWrapper = (props: Props) => {
	usePreventScroll({ prevent: true, })

	const wrapperClasses = classNames(
		Styles.modal_wrapper,
		{
			[Styles.modal_with_footer]: props.hasFooter,
			[Styles.modal_without_footer]: !props.hasFooter,
			[Styles.background_with_blur]: props.backgroundWithBlur,
		}
	)

	return (
		<m.section
			className={wrapperClasses}
			initial={{ opacity: 0, scale: 0.9, }}
			animate={{ opacity: 1, scale: 1, }}
			exit={{ opacity: 0, scale: 0.9, }}
		>
			{props.children}
		</m.section>
	)
}

export default ModalWrapper
