/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useContext, useState } from "react"
import Styles from "./WidgetPinnedFonts.module.scss"
import { useRecoilValue } from "recoil"
import { selectorsFontCache } from "@/recoil/FontCache/Selectors"
import classNames from "classnames"
import Actions from "../../../Actions/Actions"
import usePresetActions from "../../../Actions/usePresetActions"
import LinkIcon from "deblank-common/src/assets/images/icon-library/link.svg"
import DownloadIcon from "deblank-common/src/assets/images/icon-library/download.svg"
import { CommonWidgetFontsItemProps } from "../WidgetFontsTypes"
import { FontDetailsByPairing, formatFontNamePairing } from "../_helper/FontDetailsByPairing"
import { TrackConversationEventContext } from "@/pages/AssistantPage/TrackConversationEventProvider"
import { DownloadHelper } from "@/utils/exportHelper"
import { ScreenshotIdManager, WidgetUiSectionId } from "@/utils/ScreenshotIdManager"
import { widgetAction } from "@/pages/AssistantPage/components/CurrentConversation/MessageList/WidgetSelector/WidgetsTypes"
import OpenIcon from "deblank-common/src/assets/images/icon-library/maximize.svg"
import { settersCommon } from "@/recoil/ConversationsRecord/Setters/Common"
import { selectorsMessages } from "@/recoil/ConversationsRecord/Selectors/Messages"
import { selectorsConversations } from "@/recoil/ConversationsRecord/Selectors/Conversations"


const WidgetPinnedFonts = (props: CommonWidgetFontsItemProps) => {

	const [actionsOpen, setActionsOpen,] = useState<boolean>(false)

	const { trackConversationEvent, } = useContext(TrackConversationEventContext)
	const { pinPresetAction, sendToChatPresetAction, } = usePresetActions({
		idRef: props.idRef,
		widgetType: props.widgetType,
	})

	const fontSlugsByPairing = FontDetailsByPairing({
		pairing: props.metadata.pairing,
		baseFontSlug: props.metadata.baseFont || null,
		fontSlug: props.widgetItemDetails.slug,
	})
	const setFontPlayground = settersCommon.useSetFontPlaygroundIdRef()

	const areMessagesLoading = useRecoilValue(selectorsMessages.someMessageIsLoading)
	const isSavingNewConversationName = useRecoilValue(selectorsConversations.isSavingNewConversationName)
	const fontMainDetails = useRecoilValue(selectorsFontCache.fontFromCache(fontSlugsByPairing.titleSlug))
	const fontPairingDetails = useRecoilValue(selectorsFontCache.fontFromCache(fontSlugsByPairing.paringSlug || undefined))

	const getFontNames = formatFontNamePairing({
		pairingFontName: fontPairingDetails?.metadata.name,
		mainFontName: fontMainDetails?.metadata.name || "",
		pairing: props.metadata.pairing,
	})

	const handleDownload = () => {
		setTimeout(async () => {
			const widgetIdDom = ScreenshotIdManager.getElementFromDocument({
				widgetUiSection: WidgetUiSectionId.Pin,
				idRef: props.idRef,
			})!
			await DownloadHelper.fromTemplate(widgetIdDom)
			trackConversationEvent({
				eventName: "Download",
				widgetType: props.widgetType,
				responseId: props.idRef.messageId,
				outputId: props.idRef.widgetId,
			})
		}, 200)
	}

	const handleGetFont = (url: string, fontName: string) => {
		window.open(url, "_blank")
		trackConversationEvent({
			eventName: "ClickGetFont",
			fontName: fontName,
			responseId: props.idRef.messageId,
		})
	}

	const handleOpenPlayground = () => {
		if (!areMessagesLoading && !isSavingNewConversationName) {
			trackConversationEvent({
				eventName: "OpenPlayground",
				widgetType: props.widgetType,
				responseId: props.idRef.messageId,
			})
			setFontPlayground({
				idRef: props.idRef,
			})
		}
	}

	const actionList: widgetAction[] = [
		{
			text: "Open",
			icon: OpenIcon,
			onClick: handleOpenPlayground,
			isDisabled: areMessagesLoading || isSavingNewConversationName,
		},
		pinPresetAction,
		sendToChatPresetAction,
		{
			text: fontPairingDetails ? "Get title font" : "Get font",
			icon: LinkIcon,
			onClick: () => handleGetFont(fontMainDetails!.metadata.linkToVendor, fontMainDetails!.metadata.name),
		},
	]

	if (fontPairingDetails) {
		actionList.push({
			text: "Get body font",
			icon: LinkIcon,
			onClick: () => handleGetFont(fontPairingDetails.metadata.linkToVendor, fontPairingDetails.metadata.name),
		})
	}

	actionList.push({
		text: "Download",
		icon: DownloadIcon,
		onClick: handleDownload,
	})

	const wrapperClasses = classNames(
		Styles.wrapper,
		"actions_handler",
		{
			[Styles.is_open]: actionsOpen,
		}
	)

	return (<>
		<article className={wrapperClasses}
			id={
				ScreenshotIdManager.assignId({
					widgetUiSection: WidgetUiSectionId.Pin,
					idRef: props.idRef,
				})
			}
			onClick={handleOpenPlayground}
		>
			<span className={Styles.font_name}>
				{getFontNames}
			</span>
			{fontMainDetails
				&& <h3 className={Styles.title}
					style={fontMainDetails.fontCSSProperties}>
					{props.metadata.title}
				</h3>
			}
			{fontPairingDetails
				&& <p className={Styles.paragraph}
					style={fontPairingDetails.fontCSSProperties}>
					{props.metadata.description}
				</p>
			}
			<div onClick={e => {
				e.preventDefault()
				e.stopPropagation()
			}}>
				<Actions
					actionList={actionList}
					onSetActionsOpen={setActionsOpen}
					customStyles={{
						actionType: "pinItem",
					}}
				/>
			</div>
		</article>
	</>
	)
}

export default WidgetPinnedFonts
