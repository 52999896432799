import React, { useMemo } from "react"
import Styles from "./ConversationHistory.module.scss"
import HistoryItem from "./HistoryItem/HistoryItem"
import { useRecoilValue } from "recoil"
import { selectorsConversations } from "@/recoil/ConversationsRecord/Selectors/Conversations"
import { getConversationHistoryGroupedByDate } from "./ConversationHistoryHelper"
import { AnimatePresence } from "framer-motion"

const ConversationHistory = () => {

	const conversationsList = useRecoilValue(selectorsConversations.conversationList)
	const activeConversationId = useRecoilValue(selectorsConversations.activeConversationId)
	const showNewChat = useRecoilValue(selectorsConversations.showNewChat)

	const historyGroupedByDate = useMemo(() => (
		getConversationHistoryGroupedByDate(conversationsList || [])
	), [
		conversationsList,
	])

	return (
		<div className={Styles.list}>

			{(historyGroupedByDate.length > 0)
				? <AnimatePresence mode="popLayout">
					{historyGroupedByDate.map((group) => (
						group.items.length > 0
							? (
								<div key={`${group.label}-items`} className={Styles.history_group}>
									<p className={Styles.label}>{group.label}</p>
									<ul className={Styles.history_items} >
										<AnimatePresence mode="popLayout">
											{group.items.map((conversation, i) => {

												return (
													<HistoryItem
														key={`${group.label}-${conversation.id}-${i}`}
														data={conversation}
														selected={(conversation.id === activeConversationId) && !showNewChat}
													/>
												)
											})}
										</AnimatePresence>
									</ul>
									<hr className={Styles.divider} />
								</div>
							)
							: null
					))}
				</AnimatePresence>
				: <p className={Styles.empty_message}>
					Start exploring to watch your creative journey unfold here.
				</p>
			}
		</div>
	)
}

export default ConversationHistory
