import { IdRefWidgetWithPaginationItem, Output } from "deblank-api-types"
import { selector, selectorFamily } from "recoil"
import { atomConversationsRecord } from "../Atom"
import { prefixRecoilKey } from "./Common"



const pinListInCurrentConversation = selector<IdRefWidgetWithPaginationItem[] | null>({
	key: prefixRecoilKey("pinListInCurrentConversation"),
	get: ({ get, }) => {
		const { activeConversationId, conversations, } = get(atomConversationsRecord)
		if (!activeConversationId) {
			return null
		}

		return conversations[activeConversationId]
			&& conversations[activeConversationId].pinnedItems.length > 0
			? conversations[activeConversationId].pinnedItems
			: null
	},
})

const pinListOutputsInCurrentConversation = selector<Output[] | null>({
	key: prefixRecoilKey("pinListOutputsInCurrentConversation"),
	get: ({ get, }) => {
		const { activeConversationId, conversations, } = get(atomConversationsRecord)
		if (!activeConversationId) {
			return null
		}
		if (conversations[activeConversationId]
			&& conversations[activeConversationId].pinnedItems.length > 0
			&& conversations[activeConversationId].outputs) {
			return conversations[activeConversationId].pinnedItems
				.map(item => conversations[activeConversationId].outputs![item.widgetId]) || []
		}
		return []


	},
})

const isOutputPinned = selectorFamily<boolean, { idRef: IdRefWidgetWithPaginationItem, }>({
	key: prefixRecoilKey("isOutputPinned"),
	get: ({ idRef, }) => ({ get, }) => {
		const { activeConversationId, conversations, } = get(atomConversationsRecord)
		if (!activeConversationId) {
			return false
		}
		const currentConversation = conversations[activeConversationId]

		return currentConversation.pinnedItems.some(item => (
			(item.widgetItemId === idRef.widgetItemId)
		))
	},
})

export const selectorsPinned = {
	isOutputPinned,
	pinListInCurrentConversation,
	pinListOutputsInCurrentConversation,
}
