import React from "react"
import Styles from "./GradientLoadingAnimation.module.scss"

const GradientLoadingAnimation = () => {
	return (
		<div className={Styles.loading_animated_background} />
	)
}

export default GradientLoadingAnimation
