type GetDataMutate = {
	mutate: () => void,
}
export type GetDataResponseWithoutError<T>
	= (
		{
			loading: true,
			data: null,
		}
		|
		{
			loading: false,
			data: T,
		}
	)
	&
	GetDataMutate

export type GetDataResponseError
	= {
		loading: false,
		error: true,
		data: null,
	}
	&
	GetDataMutate

export type GetDataResponse<T> =
	GetDataResponseError
	|
	(
		GetDataResponseWithoutError<T> & {
			error: false,
		}
	)

export const emptyData = Symbol("emptyData")

export type EmptyData = typeof emptyData
