import { atom } from "recoil"
import { AtomConversationsRecord } from "./Types"


const defaultValue: AtomConversationsRecord = {
	activeConversationId: null,
	conversations: {},
	userHistoryConversations: {},
	debugConversations: {},
	showNewChatView: true,
	conversationStateSyncStatus: "idle",
	loadActiveConversation: false,
	isSavingNewConversationName: false,
}

export const atomConversationsRecord = atom<AtomConversationsRecord>({
	key: "ConversationsRecord",
	default: defaultValue,
})
