/* eslint-disable max-lines */
/* eslint-disable max-statements */
/* eslint-disable max-len */
/* eslint-disable max-lines-per-function */
import * as PIXI from "pixi.js"
import { FontsUIMockupID } from "deblank-api-types"
import { MockupUtils } from "@/utils/mockupBuilder/utils/MockupUtils"
import { GetMockupImageProps, Mockup } from "@/utils/mockupBuilder/types/MockupTypes"
import { FontsTemplateMockupParams } from "@/utils/mockupBuilder/types/FontsMockupTypes"
import LeftMask from "./images/left_mask.png"
import LeftPhone from "./images/left_phone.png"
import LogoMask from "./images/logo_mask.png"
import LogoMaskMini from "./images/logo_mask_mini.png"
import RightMask from "./images/right_mask.png"
import RightPhone from "./images/right_phone.png"
import TopBar from "./images/top_bar.png"
import Placeholder from "./images/placeholder.svg"
import React from "react"

const SCALE = 0.25

const mockupWidth = 4000 * SCALE
const mockupHeight = 2277 * SCALE

// ------- DESCRIPTION ------- //
//- Figma link: https://www.figma.com/file/DYWqSJfZnrSqdOmweKf6Qk/FontMuse-(new)?type=design&node-id=794-1995&mode=design&t=cfG394ABreTodwsS-0

const mockupId = FontsUIMockupID.fonts_ui_mockup_1

const getLeftScreen = async (params: {
	screenWidth: number,
	screenHeight: number,
	mask: PIXI.Sprite,
	logoMask: PIXI.Sprite,
	app: PIXI.Application,
	color1: string,
	color2: string,
} & Omit<FontsTemplateMockupParams, "type">) => {

	const leftScreenContainer = new PIXI.Container()
	leftScreenContainer.width = params.screenWidth
	leftScreenContainer.height = params.screenHeight

	const leftScreenBackgroundShape = MockupUtils.drawRect({
		color: params.color1,
		width: params.screenWidth,
		height: params.screenHeight,
	})

	leftScreenContainer.addChild(leftScreenBackgroundShape)

	//Logo
	const logoShape = MockupUtils.drawRect({
		color: params.color2,
		width: params.screenWidth,
		height: params.screenHeight,
	})

	const logoMask = new PIXI.Container()
	logoMask.addChild(params.logoMask)
	logoMask.addChild(logoShape)
	logoMask.mask = params.logoMask

	leftScreenContainer.addChild(logoMask)

	//Brand text
	const brandTextLeftRightPadding = 24 * SCALE

	const brandText = await MockupUtils.createText({
		text: "Pelago",
		fontFamily: params.title.font,
		fontWeights: params.title.weights,
		fontWeight: 700,
		color: params.color2,
		fontSize: 219 * SCALE,
		fitText: {
			containerWidth: params.screenWidth - (brandTextLeftRightPadding * 2),
			respectTextMaxSize: true,
		},
	})

	const brandTextBottomPadding = 936 * SCALE
	brandText.position.set(((params.screenWidth - brandText.width) / 2), params.screenHeight - brandText.height - brandTextBottomPadding)

	leftScreenContainer.addChild(brandText)

	const subtitleText = await MockupUtils.createText({
		text: "Substance use support customized for you",
		fontFamily: params.title.font,
		fontWeights: params.title.weights,
		fontWeight: 400,
		color: params.color2,
		fontSize: 61 * SCALE,
		wrapText: true,
		wordWrapWidth: 675 * SCALE,
		align: "center",
	})

	const subtitleTextBottomPadding = 677 * SCALE

	subtitleText.position.set(((params.screenWidth - subtitleText.width) / 2), params.screenHeight - subtitleText.height - subtitleTextBottomPadding)

	leftScreenContainer.addChild(subtitleText)


	//Buttons
	//Login button
	const buttonWidth = 767 * SCALE
	const buttonHeight = 123 * SCALE
	const buttonRadius = 90 * SCALE
	const loginButtonContainer = new PIXI.Container()
	loginButtonContainer.width = buttonWidth
	loginButtonContainer.height = buttonHeight

	const loginButtonBackgroundShape = MockupUtils.drawRect({
		color: params.color2,
		width: buttonWidth,
		height: buttonHeight,
		rounding: buttonRadius,
	})
	loginButtonContainer.addChild(loginButtonBackgroundShape)

	const loginButtonText = await MockupUtils.createText({
		text: "Login",
		fontFamily: params.title.font,
		fontWeights: params.title.weights,
		fontWeight: 400,
		color: params.color1,
		fontSize: 54 * SCALE,
		align: "center",
	})
	loginButtonText.position.set((buttonWidth - loginButtonText.width) / 2, (buttonHeight - loginButtonText.height) / 2)
	loginButtonContainer.addChild(loginButtonText)

	const loginButtonBottomPadding = 301 * SCALE

	loginButtonContainer.position.set((params.screenWidth - loginButtonContainer.width) / 2, params.screenHeight - loginButtonContainer.height - loginButtonBottomPadding)

	leftScreenContainer.addChild(loginButtonContainer)

	//Signup button
	const signupButtonContainer = new PIXI.Container()
	signupButtonContainer.width = buttonWidth
	signupButtonContainer.height = buttonHeight

	const signupButtonBackgroundShape = MockupUtils.drawRect({
		color: params.color1,
		width: buttonWidth,
		height: buttonHeight,
		rounding: buttonRadius,
	})
	signupButtonContainer.addChild(signupButtonBackgroundShape)


	const signupButtonText = await MockupUtils.createText({
		text: "Signup",
		fontFamily: params.title.font,
		fontWeights: params.title.weights,
		fontWeight: 400,
		color: params.color2,
		fontSize: 54 * SCALE,
		align: "center",
	})

	signupButtonText.position.set((buttonWidth - signupButtonText.width) / 2, (buttonHeight - signupButtonText.height) / 2)
	signupButtonContainer.addChild(signupButtonText)

	const signupButtonBottomPadding = 116 * SCALE
	signupButtonContainer.position.set((params.screenWidth - signupButtonContainer.width) / 2, params.screenHeight - signupButtonContainer.height - signupButtonBottomPadding)

	leftScreenContainer.addChild(signupButtonContainer)

	//Projection
	const leftScreenSprite = MockupUtils.project({
		app: params.app,
		container: leftScreenContainer,
		projectionPoints: {
			topLeft: { x: 528.6 * SCALE, y: -406.6 * SCALE, },
			topRight: { x: 1397.5 * SCALE, y: -489.7 * SCALE, },
			bottomRight: { x: 2248.35 * SCALE, y: 1462.55 * SCALE, },
			bottomLeft: { x: 1388.6 * SCALE, y: 1616.1 * SCALE, },
		},
	})

	const leftScreenMask = new PIXI.Container()
	leftScreenMask.addChild(params.mask)
	leftScreenMask.addChild(leftScreenSprite)
	leftScreenMask.mask = params.mask

	return leftScreenMask
}

const getRightScreen = async (params: {
	screenWidth: number,
	screenHeight: number,
	mask: PIXI.Sprite,
	topBar: PIXI.Sprite,
	logoMaskMini: PIXI.Sprite,
	app: PIXI.Application,
	color1: string,
	color2: string,
} & Omit<FontsTemplateMockupParams, "type">) => {

	const rightScreenContainer = new PIXI.Container()
	rightScreenContainer.width = params.screenWidth
	rightScreenContainer.height = params.screenHeight

	const rightScreenBackgroundShape = MockupUtils.drawRect({
		color: params.color2,
		width: params.screenWidth,
		height: params.screenHeight,
	})

	rightScreenContainer.addChild(rightScreenBackgroundShape)

	//Top bar
	const topBarContainer = new PIXI.Container()
	topBarContainer.addChild(params.topBar)
	topBarContainer.position.set(0, 0)

	const brandContainer = new PIXI.Container()
	const profileContainer = new PIXI.Container()

	const profileShape = MockupUtils.drawRect({
		color: params.color1,
		width: params.screenWidth,
		height: params.screenHeight,
	})

	profileContainer.addChild(profileShape)
	profileContainer.addChild(params.logoMaskMini)
	profileContainer.mask = params.logoMaskMini
	profileContainer.position.set(0, 0)


	const brandText = await MockupUtils.createText({
		text: "pelago",
		fontFamily: params.body.font,
		fontWeights: params.body.weights,
		fontWeight: 400,
		color: params.color1,
		fontSize: 48 * SCALE,
	})

	brandText.position.set(174 * SCALE, 300 * SCALE)
	topBarContainer.addChild(brandText)


	rightScreenContainer.addChild(profileContainer)

	brandContainer.position.set((72 + 36) * SCALE, params.topBar.height + (100 * SCALE))

	topBarContainer.addChild(brandContainer)
	rightScreenContainer.addChild(topBarContainer)

	//Quote
	const quoteContainer = new PIXI.Container()
	const quoteWidth = 873 * SCALE
	quoteContainer.width = quoteWidth
	const quotationMarkText = await MockupUtils.createText({
		text: "“",
		fontFamily: params.body.font,
		fontWeights: params.body.weights,
		fontWeight: 700,
		color: "#FFFFFF",
		fontSize: 126 * SCALE,
		position: { x: 0, y: 0, },
	})
	quoteContainer.addChild(quotationMarkText)

	const quoteText = await MockupUtils.createText({
		text: "Great design is making something memorable and meaningful.",
		fontFamily: params.title.font,
		fontWeights: params.title.weights,
		fontWeight: 700,
		color: params.color1,
		fontSize: 126 * SCALE,
		position: { x: 105 * SCALE, y: 0 * SCALE, },
		wrapText: true,
		wordWrapWidth: 800 * SCALE,
	})
	quoteContainer.addChild(quoteText)

	const authorText = await MockupUtils.createText({
		text: "Dieter Rams",
		fontFamily: params.body.font,
		fontWeights: params.body.weights,
		fontWeight: 400,
		color: params.color1,
		fontSize: 72 * SCALE,
	})
	authorText.position.set(105 * SCALE, quoteText.height + (86 * SCALE))
	quoteContainer.addChild(authorText)

	quoteContainer.position.set(((params.screenWidth - quoteWidth) * SCALE), 723 * SCALE)

	rightScreenContainer.addChild(quoteContainer)

	//Projection
	const rightScreenSprite = MockupUtils.project({
		app: params.app,
		container: rightScreenContainer,
		projectionPoints: {
			topLeft: { x: 1801.7 * SCALE, y: 691.8 * SCALE, },
			topRight: { x: 2660.4 * SCALE, y: 605.3 * SCALE, },
			bottomRight: { x: 3530.7 * SCALE, y: 2561 * SCALE, },
			bottomLeft: { x: 2664.2 * SCALE, y: 2707.1 * SCALE, },
		},
	})

	const rightScreenMask = new PIXI.Container()
	rightScreenMask.addChild(params.mask)
	rightScreenMask.addChild(rightScreenSprite)
	rightScreenMask.mask = params.mask

	return rightScreenMask
}


const getMockupImage = async (props: GetMockupImageProps<FontsTemplateMockupParams>) => {
	const { params, app, } = props

	const ret = await props.semaphore.add(async () => {
		const [
			leftMobilePhoneSprite,
			rightMobilePhoneSprite, rightMaskSprite,
			leftMaskSprite, topBarSprite, logoMaskSprite,
			logoMaskMiniSprite,
		] = await MockupUtils.loadAssets([
			LeftPhone, RightPhone, RightMask, LeftMask,
			TopBar, LogoMask, LogoMaskMini,])

		// Create mockup container
		const mockupContainer = new PIXI.Container()
		app.stage.addChild(mockupContainer)

		mockupContainer.width = mockupWidth
		mockupContainer.height = mockupHeight

		const maskGraphics = MockupUtils.drawRect({
			color: "#FFFFFF",
			width: mockupWidth,
			height: mockupHeight,
			rounding: 16,
		})

		// Use the rounded rectangle as a mask for the container
		mockupContainer.addChild(maskGraphics)
		mockupContainer.mask = maskGraphics

		const backgroundShape = MockupUtils.drawRect({
			color: "#FFFFFF",
			width: mockupWidth,
			height: mockupHeight,
			rounding: 16,
		})
		mockupContainer.addChild(backgroundShape)

		const screenWidth = 1125 * SCALE
		const screenHeight = 2438 * SCALE

		//Left screen
		mockupContainer.addChild(leftMobilePhoneSprite)

		const leftScreen = await getLeftScreen({
			screenHeight,
			screenWidth,
			mask: leftMaskSprite,
			logoMask: logoMaskSprite,
			app,
			color1: "#D6F06B",
			color2: "#244421",
			...params,
		})
		mockupContainer.addChild(leftScreen)

		//Right screen
		mockupContainer.addChild(rightMobilePhoneSprite)
		const rightScreen = await getRightScreen({
			screenHeight,
			screenWidth,
			mask: rightMaskSprite,
			topBar: topBarSprite,
			logoMaskMini: logoMaskMiniSprite,
			app,
			color1: "#D6F06B",
			color2: "#244421",
			...params,
		})
		mockupContainer.addChild(rightScreen)

		const imageData = MockupUtils.exportImage({
			app: app,
			container: mockupContainer,
			width: mockupWidth,
			height: mockupHeight,
			imageCosts: null,
		})

		return imageData
	})

	return ret!




}

export const Fonts_Mockup_UI_1: Mockup<FontsTemplateMockupParams> = {
	placeholder: <Placeholder className="disabled_custom_color" />,
	getMockupImage,
	mockupId,
	description: `
	The mockup displays two smartphones angled next to each other, showcasing app screens.

- The phone on the left has a light green screen with a dark green button centered horizontally toward the bottom. The upper section of the screen is plain, providing a spacious and clean design.

- The phone on the right has a dark green screen. The screen's design is more focused on text elements, with white text prominently displayed against the dark green background. There is a status bar visible at the top, including signal strength, WiFi, and battery indicators.

Both designs highlight a contrast between light and dark green, maintaining a cohesive color scheme across the two screens. The overall aesthetic is sleek, modern, and minimalistic.`,
}
