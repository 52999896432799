/* eslint-disable max-lines-per-function */
/* eslint-disable max-len */
/* eslint-disable max-statements */
import * as PIXI from "pixi.js"
import { FontsPackagingMockupID } from "deblank-api-types"
import { MockupUtils } from "@/utils/mockupBuilder/utils/MockupUtils"
import { GetMockupImageProps, Mockup } from "@/utils/mockupBuilder/types/MockupTypes"
import { FontsTemplateMockupParams } from "@/utils/mockupBuilder/types/FontsMockupTypes"
import DesignMask from "./images/design_mask.png"
import FrontBagMask from "./images/frontbag_mask.png"
import LeftBagMask from "./images/leftbag_mask.png"
import RightBagMask from "./images/rightbag_mask.png"
import Shadow from "./images/shadow.png"
import DesignLabelMask from "./images/designlabel_mask.png"
import Placeholder from "./images/placeholder.svg"
import React from "react"

//------- DESCRIPTION ------- //
//- Figma link:
//https://www.figma.com/file/DYWqSJfZnrSqdOmweKf6Qk/FontMuse-(new)?type=design&node-id=745-1324

const mockupId = FontsPackagingMockupID.fonts_packaging_mockup_1
const mockupWidth = 2110
const mockupHeight = 2110

const getMockupImage = async (props: GetMockupImageProps<FontsTemplateMockupParams>) => {
	const { params, app, } = props

	const ret = await props.semaphore.add(async () => {
		const [
			design1Sprite,
			design2Sprite,
			design3Sprite,
			frontBagSprite, leftBagSprite,
			rightBagSprite, shadowSprite,
			designLabelSprite,
		] = await MockupUtils.loadAssets([
			DesignMask, DesignMask, DesignMask,
			FrontBagMask, LeftBagMask,
			RightBagMask, Shadow,
			DesignLabelMask,])

		const mockupContainer = new PIXI.Container()
		app.stage.addChild(mockupContainer)

		const mockWidth = 2110

		// Background
		const background = MockupUtils.drawRect({
			width: mockWidth,
			height: mockWidth,
			color: "#EEE8EB",
		})
		mockupContainer.addChild(background)

		const buildBagContainer = async (subtitleText: string, bagColor: string, labelSprite: PIXI.Sprite, designSprite: PIXI.Sprite,) => {
			const mockWidth = 2110
			const textPadding = 200
			const bagWidth = 1261
			const bagHeight = 2084
			const leftTextX = 33.11
			const leftTextY = 593.59
			const rightTextX = 920
			const rightTextPaddingTop = 32
			const titleY = 79.78
			const subtitleY = 410
			const sloganX = 140
			const sloganY = 1634
			const designContainerX = 140
			const designContainerY = 718

			//bag background
			const bagContainer = new PIXI.Container()
			const bagGraphic = MockupUtils.drawRect({
				color: bagColor,
				width: bagWidth,
				height: bagHeight,
			})
			bagContainer.addChild(bagGraphic)

			// Label
			const labelContainer = new PIXI.Container()
			labelContainer.position.set(0, 0)

			const labelGraphic = MockupUtils.drawRect({
				color: "#2F3031",
				width: mockWidth,
				height: mockWidth,
			})
			labelContainer.addChild(labelSprite)
			labelContainer.mask = labelSprite
			labelContainer.addChild(labelGraphic)
			bagContainer.addChild(labelContainer)

			// Logo inside label
			const designContainer = new PIXI.Container()
			const designGraphic = MockupUtils.drawRect({
				color: "#080F0F",
				width: mockWidth,
				height: mockWidth,
			})
			designContainer.addChild(designGraphic)
			designContainer.addChild(designSprite)
			designContainer.mask = designSprite
			designContainer.position.set(designContainerX, designContainerY)

			const designTexts = new PIXI.Container()
			designTexts.position.set(designContainerX, designContainerY)
			const leftText = await MockupUtils.createText({
				text: "GULP",
				fontFamily: params.title.font,
				fontWeights: params.title.weights,
				fontWeight: 700,
				color: "#080F0F",
				fontSize: 28,
				padding: 4,
				fitText: {
					containerWidth: designSprite.height - textPadding,
					respectTextMaxSize: true,
				},
			})

			leftText.position.set(leftTextX, leftTextY)
			leftText.angle = -90

			const rightText = await MockupUtils.createText({
				text: "GULP",
				fontFamily: params.title.font,
				fontWeights: params.title.weights,
				fontWeight: 700,
				color: "#080F0F",
				fontSize: 28,
				padding: 4,
				fitText: {
					containerWidth: designSprite.height - textPadding,
					respectTextMaxSize: true,
				},
			})

			rightText.position.set(rightTextX, rightTextPaddingTop + rightText.width)
			rightText.angle = -90

			designTexts.addChild(rightText)
			designTexts.addChild(leftText)
			bagContainer.addChild(designTexts)

			bagContainer.addChild(designContainer)

			// header text

			const title = await MockupUtils.createText({
				text: "GULP",
				fontFamily: params.title.font,
				fontWeights: params.title.weights,
				fontWeight: 900,
				color: "#080F0F",
				fontSize: 80,
				padding: 4,
				fitText: {
					containerWidth: labelSprite.width - textPadding,
					respectTextMaxSize: true,
				},
			})
			const titleX = (labelSprite.width / 2) - (title.width / 2)
			bagContainer.addChild(title)
			title.position.set(titleX, titleY)

			// header text

			const subtitle = await MockupUtils.createText({
				text: subtitleText,
				fontFamily: params.title.font,
				fontWeights: params.title.weights,
				fontWeight: 900,
				color: "#FFF",
				fontSize: 124,
				padding: 4,
				fitText: {
					containerWidth: labelSprite.width - textPadding,
					respectTextMaxSize: true,
				},
			})
			const subtitleX = (labelSprite.width / 2) - (subtitle.width / 2)
			bagContainer.addChild(subtitle)
			subtitle.position.set(subtitleX, subtitleY)

			// header text

			const slogan = await MockupUtils.createText({
				text: "Street food flavours in all your meals",
				fontFamily: params.body.font,
				fontWeights: params.body.weights,
				fontWeight: 500,
				color: "#FFF",
				fontSize: 80,
				padding: 4,
				fitText: {
					containerHeight: 240,
					respectTextMaxSize: true,
				},
				wrapText: true,
				wordWrapWidth: 612,
			})

			bagContainer.addChild(slogan)
			slogan.position.set(sloganX, sloganY)


			return bagContainer
		}

		// Left bag
		const leftBagContainer = await buildBagContainer("ROBUSTA", "#CAE54D", designLabelSprite, design2Sprite)
		const frontBagContainer = await buildBagContainer("COLOMBIAN", "#FC77C4", designLabelSprite, design1Sprite)
		const rightBagContainer = await buildBagContainer("ETHIOPIAN", "#FF6E73", designLabelSprite, design3Sprite)

		// Left Projection
		const leftBagP = MockupUtils.project({
			app: app,
			container: leftBagContainer,
			projectionPoints: {
				topLeft: { x: 362, y: 581, },
				topRight: { x: 993, y: 571.81, },
				bottomRight: { x: 991.1, y: 1658, },
				bottomLeft: { x: 423.44, y: 1658, },
			},
		})

		// Right Projection
		const rightBagP = MockupUtils.project({
			app: app,
			container: rightBagContainer,
			projectionPoints: {
				topLeft: { x: 1186.36, y: 576.5, },
				topRight: { x: 1834.16, y: 569.22, },
				bottomRight: { x: 1776.2, y: 1651.95, },
				bottomLeft: { x: 1186.36, y: 1651.95, },
			},
		})

		// Front Projection
		const frontBagP = MockupUtils.project({
			app: app,
			container: frontBagContainer,
			projectionPoints: {
				topLeft: { x: 818, y: 652, },
				topRight: { x: 1475, y: 645, },
				bottomRight: { x: 1416.62, y: 1733.22, },
				bottomLeft: { x: 875, y: 1733.22, },
			},
		})

		const leftBag = new PIXI.Container()
		leftBag.addChild(leftBagP)
		leftBag.addChild(leftBagSprite)
		leftBag.mask = leftBagSprite
		mockupContainer.addChild(leftBag)

		const rightBag = new PIXI.Container()
		rightBag.addChild(rightBagP)
		rightBag.addChild(rightBagSprite)
		rightBag.mask = rightBagSprite
		mockupContainer.addChild(rightBag)

		const frontBag = new PIXI.Container()
		frontBag.addChild(frontBagP)
		frontBag.addChild(frontBagSprite)
		frontBag.mask = frontBagSprite

		mockupContainer.addChild(frontBag)

		shadowSprite.blendMode = PIXI.BLEND_MODES.MULTIPLY

		mockupContainer.addChild(shadowSprite)

		const imageData = MockupUtils.exportImage({
			app: app,
			container: mockupContainer,
			width: mockupWidth,
			height: mockupHeight,
			imageCosts: null,
		})

		return imageData


	})

	return ret!


}

export const Fonts_Mockup_Packaging_1: Mockup<FontsTemplateMockupParams> = {
	placeholder: <Placeholder className="disabled_custom_color" />,
	getMockupImage,
	mockupId,
	description: `
	The mockup features three standing pouches, each with a different colored section at the top and center. The pouches are black and arranged in a staggered formation. The pouch on the left has a bright green section at the top and a green rectangle in the center displaying simple illustrated hands gesturing various signs. The middle pouch has a pink top and a pink rectangle in the center with similar hand illustrations. The rightmost pouch features a red top and a red rectangle, also showcasing illustrated hands making different gestures. The pouches convey a vibrant and playful design aesthetic.`,
}
