import React, { useContext, useRef, } from "react"
import Styles from "./CurrentConversation.module.scss"
import { useRecoilValue } from "recoil"
import PromptToolbar from "./PromptToolbar/PromptToolbar"
import classNames from "classnames"
import { AnimatePresence, m, } from "framer-motion"
import { UserContext } from "@/providers/UserProvider"
import { selectorsConversations } from "@/recoil/ConversationsRecord/Selectors/Conversations"
import InitialMessage from "./InitialMessage/InitialMessage"
import MessageList from "./MessageList/MessageList"
import TermsMessage from "./TermsMessage/TermsMessage"
import { promptToolbarProps } from "./CurrentConversationAnimation"
import { selectorsUITemporary } from "@/recoil/Ui/Temporary/Selectors"
import { selectorsMessages } from "@/recoil/ConversationsRecord/Selectors/Messages"

const CurrentConversation = () => {

	const activeConversationId = useRecoilValue(selectorsConversations.activeConversationId)
	const showNewChat = useRecoilValue(selectorsConversations.showNewChat)
	const chatAmount = useRecoilValue(selectorsConversations.chatAmount)
	const areMessagesLoading = useRecoilValue(selectorsMessages.someMessageIsLoading)
	const userContext = useContext(UserContext)
	const chatContainerRef = useRef<null | HTMLDivElement>(null)
	const isInstagramBannerOpen = useRecoilValue(selectorsUITemporary.isInstagramBannerOpen)

	const conversationClases = classNames(
		Styles.conversation_container,
		{
			[Styles.not_new_chat]: !(showNewChat),
		}
	)

	const spaceToolbarClasses = classNames(
		Styles.space_prompt_toolbar,
		{
			[Styles.has_footer]: isInstagramBannerOpen,
			[Styles.has_privacy_police]: showNewChat,
		}
	)

	const spaceChatClasses = classNames(
		Styles.space_chat_area,
		{
			[Styles.is_loading]: areMessagesLoading,
		}
	)

	const showInitialMessage = userContext.isNewUser && chatAmount === 0

	return (
		<section className={conversationClases}>
			<section className={spaceChatClasses}
				key={activeConversationId}
				ref={chatContainerRef}
			>
				{
					showNewChat
						? <InitialMessage />
						: <MessageList
							htmlConversationContainerRef={chatContainerRef}
						/>
				}
			</section >

			<section className={spaceToolbarClasses}>
				<section className={Styles.wrapper_toolbar}>
					<section className={Styles.content_toolbar}>
						<AnimatePresence>
							{!showInitialMessage
								&& <m.div {...promptToolbarProps}>
									<PromptToolbar />
								</m.div>
							}
						</AnimatePresence>
					</section>
				</section>

			</section>

			{showNewChat && <TermsMessage />}
		</section>
	)
}

export default CurrentConversation
