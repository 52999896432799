/* eslint-disable no-useless-escape */
/* eslint-disable max-lines */
/* eslint-disable max-statements */
/* eslint-disable max-len */
/* eslint-disable max-lines-per-function */
import * as PIXI from "pixi.js"
import { ColorUIMockupID } from "deblank-api-types"
import { MockupUtils } from "@/utils/mockupBuilder/utils/MockupUtils"
import { GetMockupImageProps, Mockup, OpenAiCosts } from "@/utils/mockupBuilder/types/MockupTypes"
import { ColorsTemplateMockupParams } from "@/utils/mockupBuilder/types/ColorsMockupTypes"
import { Mockup_SVG } from "./Mockup_SVG"
import { ColorUtils } from "@/utils/mockupBuilder/utils/ColorUtils"
import CellphoneBackMask from "./images/cellphone_back_mask.png"
import CellphoneFrontMask from "./images/cellphone_front_mask.png"
import CellphoneBorderLight from "./images/cellphone_border_light.png"
import CellphoneScreenLight from "./images/cellphone_screen_light.png"
import CellphoneShadows from "./images/cellphone_shadows.png"
import Images from "./images/images.png"
import Shoe from "./images/shoe.png"
import Placeholder from "./images/placeholder.svg"
import React from "react"

// ------- DESCRIPTION ------- //
//- Figma link: https://www.figma.com/file/DYWqSJfZnrSqdOmweKf6Qk/FontMuse-(new)?type=design&node-id=822-1351&mode=design&t=QDo8iGvib0i6nIP2-0

const SCALE = 0.5

const mockupWidth = 3000 * SCALE
const mockupHeight = 1727 * SCALE

const mockupId = ColorUIMockupID.colors_ui_mockup_2

type ColorAssignmentType = {
	frontDesign: { colorA: string, colorB: string, colorC: string, colorD: string, textColor: string, invertedTextColor: string, bottomTextColor: string, },
	backDesign: { colorA: string, colorB: string, bottomTextColor: string, },
	mainBackgroundColor: string,
}

const getColors = async (params: {
	mockupParams: ColorsTemplateMockupParams,
	minColors: number,
}): Promise<{ colorAssignment: ColorAssignmentType, openAiCosts: OpenAiCosts, }> => {
	const { getColorPalette, getTextColor, getBackgroundColor, } = ColorUtils
	const { getGroupedColors, type, ...colorPalette } = params.mockupParams
	const { usage, ...colorP } = colorPalette
	const colors = Object.values(colorP).filter(c => c)

	const backgroundColor = getBackgroundColor({
		colorPalette: colors,
		backgroundLuminance: 1,
	})

	const designColorPalette = getColorPalette({
		colors: colors,
		backgroundColor: backgroundColor,
		minColors: params.minColors,
	})

	const frontColorPaletteWithoutContrastColor = designColorPalette.filter((color) => color !== backgroundColor)

	const palette = await getGroupedColors({
		palette: frontColorPaletteWithoutContrastColor,
		imageSvg: Mockup_SVG.simpleFrontDesignSvg,
		backgroundColor: backgroundColor,
		numberOfGroups: params.minColors,
	},)

	const textColor = getTextColor({
		backgroundColor: palette.groupD!,
		colorPalette: ["#FFFFFF", "#000000",],
	})

	const bottomTextColor = getTextColor({
		backgroundColor: palette.groupA!,
		colorPalette: ["#FFFFFF", "#000000",],
	})

	return {
		colorAssignment: {
			frontDesign: {
				colorA: palette.groupA!,
				colorB: palette.groupB!,
				colorC: palette.groupC!,
				colorD: palette.groupD!,
				textColor: textColor,
				invertedTextColor: textColor === "#FFFFFF" ? "#000000" : "#FFFFFF",
				bottomTextColor: bottomTextColor,
			},
			backDesign: {
				colorA: palette.groupA!,
				colorB: palette.groupD!,
				bottomTextColor: bottomTextColor,
			},
			mainBackgroundColor: "#FFFFFF",
		},
		openAiCosts: {
			completion_cost: palette.usage!.completion_cost,
			prompt_cost: palette.usage!.prompt_cost,
		},
	}
}

const getMockupImage = async (props: GetMockupImageProps<ColorsTemplateMockupParams>) => {
	const { app, semaphore, } = props
	const ret = await semaphore.add(async () => {
		const { colorAssignment, openAiCosts, } = await getColors({
			mockupParams: props.params,
			minColors: 4,
		})

		const [cellphoneBackMaskSprite, cellphoneFrontMaskSprite,
			cellphoneBorderLightSprite, cellphoneScreenLightSprite,
			shadowSprite, shoeSprite, imagesSprite,
		] = await MockupUtils.loadAssets([
			CellphoneBackMask, CellphoneFrontMask, CellphoneBorderLight, CellphoneScreenLight,
			CellphoneShadows, Shoe, Images,])

		// Create mockup container
		const mockupContainer = new PIXI.Container()
		app.stage.addChild(mockupContainer)

		mockupContainer.width = mockupWidth
		mockupContainer.height = mockupHeight

		const maskGraphics = MockupUtils.drawRect({
			width: mockupWidth,
			height: mockupHeight,
			color: "#FFFFFF",
		})

		// Use the rounded rectangle as a mask for the container
		mockupContainer.addChild(maskGraphics)
		mockupContainer.mask = maskGraphics

		const backgroundMask = new PIXI.Container()
		const backgroundShape = MockupUtils.drawRect({
			width: mockupWidth,
			height: mockupHeight,
			color: colorAssignment.mainBackgroundColor,
		})
		backgroundMask.addChild(backgroundShape)
		mockupContainer.addChild(backgroundMask)

		// Add Shadows
		mockupContainer.addChild(shadowSprite)

		const getBackDesign = async () => {
			const designContainer = new PIXI.Container()
			const designTexture = await PIXI.Texture.fromURL(Mockup_SVG.backDesignSVG(colorAssignment!.backDesign).replace('\"', '"'))
			const designSprite = new PIXI.Sprite(designTexture)
			designContainer.addChild(designSprite)

			// Add images
			designContainer.addChild(imagesSprite)
			imagesSprite.position.set(44, 351)

			return designContainer
		}

		const getFrontDesign = async () => {
			const designContainer = new PIXI.Container()
			const designTexture = await PIXI.Texture.fromURL(Mockup_SVG.frontDesignSVG(colorAssignment!.frontDesign).replace('\"', '"'))
			const designSprite = new PIXI.Sprite(designTexture)
			designContainer.addChild(designSprite)

			// Add shoe
			designContainer.addChild(shoeSprite)

			shoeSprite.position.set(-18, 169)
			return designContainer

		}

		const backDesignMask = new PIXI.Container()
		const backDesignShape = MockupUtils.drawRect({
			width: mockupWidth,
			height: mockupHeight,
			color: "#141414",
		})
		backDesignMask.addChild(backDesignShape)

		const backDesign = await getBackDesign()
		const backCellphoneProjection = MockupUtils.project({
			app: app,
			container: backDesign,
			projectionPoints: {
				topLeft: { x: 592.4 * SCALE, y: 572.8 * SCALE, },
				topRight: { x: 1087.03 * SCALE, y: 295.49 * SCALE, },
				bottomRight: { x: 1872.51 * SCALE, y: 1103.75 * SCALE, },
				bottomLeft: { x: 1402.85 * SCALE, y: 1402.58 * SCALE, },
			},
		})
		const backCellphone = new PIXI.Container()
		backCellphone.addChild(backDesignMask)
		backCellphone.addChild(backCellphoneProjection)
		backCellphone.addChild(cellphoneBackMaskSprite)
		backCellphone.mask = cellphoneBackMaskSprite
		mockupContainer.addChild(backCellphone)

		const frontDesignMask = new PIXI.Container()
		const frontDesignShape = MockupUtils.drawRect({
			width: mockupWidth,
			height: mockupHeight,
			color: "#141414",
		})
		frontDesignMask.addChild(frontDesignShape)

		const frontDesign = await getFrontDesign()
		const frontCellphoneProjection = MockupUtils.project({
			app: app,
			container: frontDesign,
			projectionPoints: {
				topLeft: { x: 1172.6 * SCALE, y: 426.3 * SCALE, },
				topRight: { x: 1649.52 * SCALE, y: 153.4 * SCALE, },
				bottomRight: { x: 2532.28 * SCALE, y: 931.39 * SCALE, },
				bottomLeft: { x: 2041.5 * SCALE, y: 1254.82 * SCALE, },
			},
		})
		const frontCellphone = new PIXI.Container()
		frontCellphone.addChild(frontDesignMask)
		frontCellphone.addChild(frontCellphoneProjection)
		frontCellphone.addChild(cellphoneFrontMaskSprite)
		frontCellphone.mask = cellphoneFrontMaskSprite

		mockupContainer.addChild(frontCellphone)

		//Add shadowSprite
		cellphoneScreenLightSprite.blendMode = PIXI.BLEND_MODES.SCREEN
		mockupContainer.addChild(cellphoneScreenLightSprite)

		// Add cellphoneBorderLight
		mockupContainer.addChild(cellphoneBorderLightSprite)

		const imageData = MockupUtils.exportImage({
			app: app,
			container: mockupContainer,
			height: mockupHeight,
			width: mockupWidth,
			imageCosts: openAiCosts,
		})

		return imageData

	})

	return ret!
}

export const Color_Mockup_UI_2: Mockup<ColorsTemplateMockupParams> = {
	placeholder: <Placeholder className="disabled_custom_color" />,
	getMockupImage,
	mockupId,
	description: `The mockup features two smartphones placed side-by-side, showing different screens of a shopping app.

- The screen on the left displays a "Top sales" section with a grid layout featuring multiple product cards. Each card has an image of the product, a title, and a "Buy now" button. Categories are listed at the top of the screen, such as Home, Gaming, and Contact, with Home seemingly selected.

- The screen on the right shows a detailed page for a specific product, a pair of shoes. The top of the screen includes an image of the shoes with a colorful wavy band behind it for a dynamic effect. Below the image, there's descriptive text with the heading "The future," followed by a detailed product description. The screen also includes interactive buttons for selecting size options, a "Buy now" button, and a "Favorite" button. There is a section at the bottom for navigating through more pages.

Both screens have a modern and user-friendly design, utilizing clear typography and well-organized layouts to make navigation and product selection straightforward for the user.`,
}
