/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
import React, { useContext } from "react"
import Styles from "./AlphaUsersModal.module.scss"
import { m } from "framer-motion"
import { usePreventScroll } from "deblank-common/src/hooks/usePreventScroll"
import Button from "@/components/Buttons/Button/Button"
import InstagramFooter from "../../../../../components/InstagramFooter/InstagramFooter"
import { TrackConversationEventContext } from "@/pages/AssistantPage/TrackConversationEventProvider"

type Props = {
	onClose: () => void,
	onInteract: () => void,
}

const AlphaUsersModal = (props: Props) => {
	usePreventScroll({ prevent: true, })
	const { trackConversationEvent, } = useContext(TrackConversationEventContext)

	const handleScheduleCall = () => {
		props.onInteract()
		trackConversationEvent({
			eventName: "ClickLeaveFeedback",
		})
		window.open("https://ks2131zyx1t.typeform.com/deblank-alpha",
			"_blank")
		props.onClose()
	}

	const handleClose = () => {
		props.onInteract()
		trackConversationEvent({
			eventName: "CloseLeaveFeedbackModal",
		})
		props.onClose()
	}

	return (
		<m.section
			className={Styles.modal_wrapper}
			initial={{ opacity: 0, scale: 0.9, }}
			animate={{ opacity: 1, scale: 1, }}
			exit={{ opacity: 0, scale: 0.9, }}
		>
			<div className={Styles.content}>
				<div className={Styles.text_content}>
					<div className={Styles.title_content}>
						<h1 className={`${Styles.title} ${Styles.bold}`}>Hey there, </h1>
						<h1 className={Styles.title}>Looks like you've given Deblank a few great prompts!</h1>
					</div>
					<p>We're keen to make this design assistant even more powerful, and your feedback is key. You can share direct feedback by filling out this survey.</p>
					<p>Let's make Deblank even better, together!</p>
				</div>
				<div className={Styles.actions}>
					<Button
						type="button"
						onClick={handleScheduleCall}
						customStyles={{
							variant: "primary",
							size: "large",

						}}>
						Leave feedback
					</Button>
					<Button
						type="button"
						onClick={handleClose}
						customStyles={{
							variant: "secondary",
							size: "large",

						}}>
						Not now
					</Button>
				</div>
			</div>
			<InstagramFooter disableCloseButton />
		</m.section>
	)
}

export default AlphaUsersModal
