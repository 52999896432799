import { produce } from "immer"
import { useRecoilCallback } from "recoil"
import { atomConversationsRecord } from "../Atom"

const useSetCurrentIntroQuestions = () => {
	return useRecoilCallback(({ set, }) => (value: number | null) => {
		set(atomConversationsRecord, previous => produce(previous, draft => {
			const currentConversation = draft.conversations[draft.activeConversationId!]
			currentConversation.currentIntroQuestion = value

			draft.conversationStateSyncStatus = "pendingSave"
		}))

	})
}

export const settersIntroQuestions = {
	useSetCurrentIntroQuestions,
}
