import { DateTime, } from "luxon"
import { UserHistoryConversations } from "deblank-api-types"

type ConversationsGrouped = {
	today: UserHistoryConversations[],
	yesterday: UserHistoryConversations[],
	previous7Days: UserHistoryConversations[],
	older: UserHistoryConversations[],
}

type ConversationGroupedByDate = {
	label: string,
	items: UserHistoryConversations[],
}

const groupByDateRange = (items: UserHistoryConversations[]) => {
	const now = DateTime.utc()
	const todayStart = now.startOf("day")
	const yesterdayStart = todayStart.minus({ days: 1, })
	const weekAgoStart = todayStart.minus({ days: 7, })

	const conversationsByGroupDateRange: ConversationsGrouped = {
		today: [],
		yesterday: [],
		previous7Days: [],
		older: [],
	}

	items.forEach(item => {
		const itemDate = DateTime.fromISO(item.updatedAt, { zone: "utc", })

		if (itemDate.hasSame(todayStart, "day")) {
			conversationsByGroupDateRange.today.push(item)
		} else if (itemDate.hasSame(yesterdayStart, "day")) {
			conversationsByGroupDateRange.yesterday.push(item)
		} else if (itemDate >= weekAgoStart && itemDate < yesterdayStart) {
			conversationsByGroupDateRange.previous7Days.push(item)
		} else {
			conversationsByGroupDateRange.older.push(item)
		}
	})

	return conversationsByGroupDateRange
}

export const getConversationHistoryGroupedByDate = (
	history: UserHistoryConversations[]
): ConversationGroupedByDate[] => {

	const { today, yesterday, previous7Days, older, } = groupByDateRange(history)

	return [
		{ label: "Today", items: today, },
		{ label: "Yesterday", items: yesterday, },
		{ label: "Previous 7 days", items: previous7Days, },
		{ label: "Previous 30 days", items: older, },
	]
}
