/* eslint-disable no-implicit-coercion */
/* eslint-disable max-statements */
/* eslint-disable max-len */
/* eslint-disable max-lines-per-function */
import * as PIXI from "pixi.js"
import { ColorBrandingMockupID } from "deblank-api-types"
import { MockupUtils } from "@/utils/mockupBuilder/utils/MockupUtils"
import { GetMockupImageProps, Mockup, OpenAiCosts } from "@/utils/mockupBuilder/types/MockupTypes"
import { ColorsTemplateMockupParams, GroupedColorPalette } from "@/utils/mockupBuilder/types/ColorsMockupTypes"
import { Mockup_SVG } from "./Mockup_SVG"
import { ColorUtils } from "@/utils/mockupBuilder/utils/ColorUtils"
import Color1 from "./images/color_1.png"
import Color2 from "./images/color_2.png"
import Color3 from "./images/color_3.png"
import Color4 from "./images/color_4.png"
import Color5 from "./images/color_5.png"
import Color6 from "./images/color_6.png"
import DesignMask from "./images/design_mask.png"
import Lights from "./images/lights.png"
import Shadow from "./images/shadow.png"
import Placeholder from "./images/placeholder.svg"
import React from "react"

// ------- DESCRIPTION ------- //
//- Figma link: https://www.figma.com/file/DYWqSJfZnrSqdOmweKf6Qk/FontMuse-(new)?type=design&node-id=822-1351&mode=design&t=QDo8iGvib0i6nIP2-0

const SCALE = 0.5

const mockupWidth = 3000 * SCALE
const mockupHeight = 2250 * SCALE

const mockupId = ColorBrandingMockupID.colors_branding_mockup_2

type ColorAssignmentType = {
	backgroundColor: string,
	designPalette: GroupedColorPalette,
}

const getColors = async (params: {
	mockupParams: ColorsTemplateMockupParams,
	minColors: number,
}): Promise<{ colorAssignment: ColorAssignmentType, openAiCosts: OpenAiCosts, }> => {
	const { getBackgroundColor, getColorPalette, } = ColorUtils
	const { getGroupedColors, type, ...colorPalette } = params.mockupParams
	const { usage, ...colorP } = colorPalette
	const colors = Object.values(colorP).filter(c => c)

	const backgroundColor = getBackgroundColor({
		colorPalette: colors,
		backgroundLuminance: 1,
	})

	const designColorPalette = getColorPalette({
		colors: colors,
		backgroundColor: backgroundColor,
		minColors: params.minColors,
	})

	const colorPaletteWithoutContrastColor = designColorPalette.filter((color) => color !== backgroundColor)

	const designPalette = await getGroupedColors({
		palette: colorPaletteWithoutContrastColor,
		imageSvg: Mockup_SVG.designSvg,
		backgroundColor: backgroundColor,
		numberOfGroups: params.minColors,
	},)


	return {
		colorAssignment: {
			backgroundColor,
			designPalette,
		},
		openAiCosts: {
			completion_cost: designPalette.usage!.completion_cost,
			prompt_cost: designPalette.usage!.prompt_cost,
		},
	}
}

const getMockupImage = async (props: GetMockupImageProps<ColorsTemplateMockupParams>) => {
	const { app, semaphore, } = props
	const ret = await semaphore.add(async () => {
		const { colorAssignment, openAiCosts, } = await getColors({
			mockupParams: props.params,
			minColors: 6,
		})
		const [color1Sprite, color2Sprite,
			color3Sprite, color4Sprite, color5Sprite,
			color6Sprite, designMaskSprite,
			lightsSprite, shadowSprite,] = await MockupUtils.loadAssets([
				Color1, Color2, Color3, Color4, Color5, Color6,
				DesignMask, Lights, Shadow,
			])

		// Create mockup container
		const mockupContainer = new PIXI.Container()
		app.stage.addChild(mockupContainer)

		const designWidth = 3575 * SCALE
		const designHeight = 342 * SCALE

		mockupContainer.width = mockupWidth
		mockupContainer.height = mockupHeight

		const maskGraphics = MockupUtils.drawRect({
			color: "#FFFFFF",
			width: mockupWidth,
			height: mockupHeight,
		})

		// Use the rounded rectangle as a mask for the container
		mockupContainer.addChild(maskGraphics)
		mockupContainer.mask = maskGraphics

		// Add Background

		const backgroundShape = MockupUtils.drawRect({
			color: colorAssignment.backgroundColor,
			width: mockupWidth,
			height: mockupHeight,
		})
		mockupContainer.addChild(backgroundShape)

		const generateTapeContainer = (
			colors: {
				design: string,
				background: string,
			},
			sprites: {
				design: PIXI.Sprite,
				background: PIXI.Sprite,
			},
			projection: {
				topLeft: { x: number, y: number, },
				topRight: { x: number, y: number, },
				bottomRight: { x: number, y: number, },
				bottomLeft: { x: number, y: number, },
			},
		) => {

			const color1Container = new PIXI.Container()
			const color1Shape = MockupUtils.drawRect({
				color: colors.design,
				width: designWidth,
				height: designHeight,
			})
			color1Container.addChild(color1Shape)
			color1Container.addChild(sprites.design)
			color1Container.mask = sprites.design


			const design1Projection = MockupUtils.project({
				app: app,
				container: color1Container,
				projectionPoints: {
					topLeft: projection.topLeft,
					topRight: projection.topRight,
					bottomRight: projection.bottomRight,
					bottomLeft: projection.bottomLeft,
				},
			})

			const design1Container = new PIXI.Container()
			const design1Shape = MockupUtils.drawRect({
				color: colors.background,
				width: mockupWidth,
				height: mockupHeight,
			})
			design1Container.addChild(design1Shape)
			design1Container.addChild(sprites.background)
			design1Container.mask = sprites.background
			design1Container.addChild(design1Projection)

			return design1Container
		}

		// Add tapes

		const tape1Container = generateTapeContainer(
			{
				design: colorAssignment.designPalette.groupE!,
				background: colorAssignment.designPalette.groupD!,
			},
			{
				design: designMaskSprite,
				background: color1Sprite,
			},
			{
				topLeft: { x: 0 * SCALE, y: 767 * SCALE, },
				topRight: { x: 3000 * SCALE, y: 761 * SCALE, },
				bottomRight: { x: 3000 * SCALE, y: 1109 * SCALE, },
				bottomLeft: { x: 0 * SCALE, y: 1115 * SCALE, },
			},
		)

		const tape2Container = generateTapeContainer(
			{
				design: colorAssignment.backgroundColor!,
				background: colorAssignment.designPalette.groupC!,
			},
			{
				design: designMaskSprite,
				background: color2Sprite,
			},
			{
				topLeft: { x: 0 * SCALE, y: 38 * SCALE, },
				topRight: { x: 3136 * SCALE, y: 1333 * SCALE, },
				bottomRight: { x: 3001 * SCALE, y: 1653 * SCALE, },
				bottomLeft: { x: -137 * SCALE, y: 357 * SCALE, },
			},
		)

		const tape3Container = generateTapeContainer(
			{
				design: colorAssignment.designPalette.groupB!,
				background: colorAssignment.designPalette.groupA!,
			},
			{
				design: designMaskSprite,
				background: color3Sprite,
			},
			{
				topLeft: { x: -80 * SCALE, y: 2209 * SCALE, },
				topRight: { x: 1165 * SCALE, y: -161 * SCALE, },
				bottomRight: { x: 1471.7 * SCALE, y: 1 * SCALE, },
				bottomLeft: { x: 227 * SCALE, y: 2371 * SCALE, },
			},
		)

		const tape4Container = generateTapeContainer(
			{
				design: colorAssignment.backgroundColor!,
				background: colorAssignment.designPalette.groupC!,
			},
			{
				design: designMaskSprite,
				background: color4Sprite,
			},
			{
				topLeft: { x: 1836 * SCALE, y: 2311 * SCALE, },
				topRight: { x: 1424 * SCALE, y: 0 * SCALE, },
				bottomRight: { x: 1766 * SCALE, y: -61 * SCALE, },
				bottomLeft: { x: 2177 * SCALE, y: 2250 * SCALE, },
			},
		)

		const tape5Container = generateTapeContainer(
			{
				design: colorAssignment.designPalette.groupB!,
				background: colorAssignment.designPalette.groupA!,
			},
			{
				design: designMaskSprite,
				background: color5Sprite,
			},
			{
				topLeft: { x: 0 * SCALE, y: 1224 * SCALE, },
				topRight: { x: 3062.1 * SCALE, y: 1793.06 * SCALE, },
				bottomRight: { x: 2999.7 * SCALE, y: 2134.15 * SCALE, },
				bottomLeft: { x: -62.8 * SCALE, y: 1565.81 * SCALE, },
			},
		)

		const tape6Container = generateTapeContainer(
			{
				design: colorAssignment.designPalette.groupE!,
				background: colorAssignment.designPalette.groupD!,
			},
			{
				design: designMaskSprite,
				background: color6Sprite,
			},
			{
				topLeft: { x: 860 * SCALE, y: 2250 * SCALE, },
				topRight: { x: 2928.19 * SCALE, y: -66.15 * SCALE, },
				bottomRight: { x: 3187.5 * SCALE, y: 165.3 * SCALE, },
				bottomLeft: { x: 1119.8 * SCALE, y: 2481.44 * SCALE, },
			},
		)

		const tapesContainer = new PIXI.Container()
		tapesContainer.addChild(tape1Container)
		tapesContainer.addChild(tape2Container)
		tapesContainer.addChild(tape3Container)
		tapesContainer.addChild(tape4Container)
		tapesContainer.addChild(tape5Container)
		tapesContainer.addChild(tape6Container)

		mockupContainer.addChild(tapesContainer)



		const shadowsAndLightsContainer = new PIXI.Container()

		// Add Shadows
		shadowSprite.blendMode = PIXI.BLEND_MODES.MULTIPLY
		shadowsAndLightsContainer.addChild(shadowSprite)

		// Add Lights
		lightsSprite.blendMode = PIXI.BLEND_MODES.SCREEN
		shadowsAndLightsContainer.addChild(lightsSprite)
		mockupContainer.addChild(shadowsAndLightsContainer)

		const imageData = MockupUtils.exportImage({
			app: app,
			container: mockupContainer,
			width: mockupWidth,
			height: mockupHeight,
			imageCosts: openAiCosts,
		})


		return imageData
	})
	return ret!



}

export const Color_Mockup_Branding_2: Mockup<ColorsTemplateMockupParams> = {
	getMockupImage,
	placeholder: <Placeholder className="disabled_custom_color" />,
	mockupId,
	description: "The image features several diagonal strips overlapping each other. Each strip has the word \"NEW\" prominently displayed, along with smaller text that reads \"designed and edited by debbank.\" The strips are arranged in a crisscross pattern against a plain background.",
}
