import React, { useEffect } from "react"
import Styles from "./WidgetRelatedActionImage.module.scss"
import { useRecoilValue } from "recoil"
import classNames from "classnames"
import { ImageSelectValue } from "../WidgetImagesTypes"
import { DisplayWidgetType, IdRefWidget } from "deblank-api-types"
import { selectorsRelatedActions } from "@/recoil/ConversationsRecord/Selectors/RelatedActions"
import ImageByWidgetType from "@/components/ImageByWidgetType/ImageByWidgetType"

type Props = {
	value: ImageSelectValue,
	onSelect: (value: ImageSelectValue,) => void,
	onNextAction: () => void,
	idRef: IdRefWidget,
}

const WidgetRelatedActionImage = (props: Props) => {
	const widgetItems = useRecoilValue(selectorsRelatedActions.widgetItemsForRelatedActions(props.idRef))

	// If there is only one option, select it and go to the next action
	// This is going to set the state and activate a flag that executes the next action.
	useEffect(() => {
		if (widgetItems && widgetItems.length === 1) {
			const widget = widgetItems[0].type === DisplayWidgetType.Images && widgetItems[0]
			if (widget) {
				props.onSelect({
					idRef: widget.idRef,
					widgetItemDetails: widget.widgetData,
					widgetType: widget.type,
				})
				props.onNextAction()
			}

		}
	}, [widgetItems,])


	const handleSelect = (
		idRef: ImageSelectValue["idRef"],
		widgetItemDetails: ImageSelectValue["widgetItemDetails"],
		widgetType: ImageSelectValue["widgetType"],
	) => (e: React.MouseEvent) => {
		e.preventDefault()
		e.stopPropagation()
		props.onSelect({
			idRef,
			widgetItemDetails,
			widgetType,
		})
	}

	return (
		<>
			{widgetItems && widgetItems.map((widget) => {
				if (widget.type !== DisplayWidgetType.Images) {
					return null
				}

				const wrapperClasses = classNames({
					[Styles.image_button]: true,
					[Styles.is_selected]: props.value.idRef.widgetItemId === widget.idRef.widgetItemId,
				})
				return (
					<li key={widget.idRef.widgetItemId} className={Styles.list_item}>
						<button
							onClick={handleSelect(
								widget.idRef,
								widget.widgetData,
								widget.type,
							)}
							className={wrapperClasses}
						>
							<ImageByWidgetType widgetType={widget.type}
								widgetDetails={widget.widgetData}
							/>
						</button>
					</li>
				)
			})}
		</>
	)
}

export default WidgetRelatedActionImage
