import { selector } from "recoil"
import { prefixRecoilKey } from "./Common"
import { atomConversationsRecord } from "../Atom"

const currentIntroQuestion = selector<number | null>({
	key: prefixRecoilKey("currentIntroQuestion"),
	get: ({ get, }) => {
		const { activeConversationId, conversations, } = get(atomConversationsRecord)
		if (!activeConversationId || !conversations[activeConversationId]) {
			return 0
		}
		return conversations[activeConversationId].currentIntroQuestion
	},
})


export const selectorsIntroQuestions = {
	currentIntroQuestion,
}
