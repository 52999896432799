import { useRecoilCallback } from "recoil"
import { atomConversationsRecord } from "../Atom"
import { produce } from "immer"
import {
	IdRefWidgetWithPaginationItem,
} from "deblank-api-types"


const useSetFontPlaygroundIdRef = () => {
	return useRecoilCallback(({ set, }) => (params: {
		idRef: IdRefWidgetWithPaginationItem | null,
	}) => {
		set(atomConversationsRecord, previous => produce(previous, draft => {
			const currentConversation = draft.conversations[draft.activeConversationId!]
			currentConversation.fontPlaygroundIdRef = params.idRef
		}))
	})
}

const useSetColorPlaygroundIdRef = () => {
	return useRecoilCallback(({ set, }) => (params: {
		idRef: IdRefWidgetWithPaginationItem | null,
	}) => {
		set(atomConversationsRecord, previous => produce(previous, draft => {
			const currentConversation = draft.conversations[draft.activeConversationId!]
			currentConversation.colorsPlaygroundIdRef = params.idRef
		}))
	})
}

const useSetImageModalIdRef = () => {
	return useRecoilCallback(({ set, }) => (params: {
		idRef: IdRefWidgetWithPaginationItem | null,
	}) => {
		set(atomConversationsRecord, previous => produce(previous, draft => {
			const currentConversation = draft.conversations[draft.activeConversationId!]
			currentConversation.imageModalIdRef = params.idRef
		}))
	})
}


export const settersCommon = {
	useSetFontPlaygroundIdRef,
	useSetColorPlaygroundIdRef,
	useSetImageModalIdRef,
}
