import React, { useState } from "react"
import Styles from "./ImageCard.module.scss"
import { selectorsConversations } from "@/recoil/ConversationsRecord/Selectors/Conversations"
import { useRecoilValue } from "recoil"
import Spinner, { SpinnerSize } from "../Spinner/Spinner"
import ReloadIcon from "deblank-common/src/assets/images/icon-library/regenerate.svg"
import ImageManager from "@/utils/ImageManager"
import { useGetData } from "@/hooks/getData/useGetData"
import * as Sentry from "@sentry/react"
import { simplifyAspectRatio } from "@/utils/simplifyAspectRatio"
import classNames from "classnames"

type Props = {
	imageFileName: string,
	altText: string,
	width?: number,
	height?: number,
}

const ImageFromStore = (props: Props) => {

	const [imageFailedToLoad, setImageFailedToLoad,] = useState<boolean>(false)
	const [imageLoaded, setImageLoaded,] = useState<boolean>(false)

	const conversationId = useRecoilValue(selectorsConversations.activeConversationId)

	const imageClasses = classNames(
		Styles.image,
		{
			[Styles.image_loaded]: imageLoaded,
		}
	)

	const handleOnRegisterError = (error: unknown) => {
		Sentry.captureException(error)
		console.error(error)
	}

	const memoizedQuery = React.useMemo(
		() => ({
			imageFileName: props.imageFileName,
			conversationId: conversationId!,
			key: props.imageFileName,
		}),
		[props.imageFileName, conversationId,]
	)

	const imageSync = useGetData({
		query: memoizedQuery,
		getData: ImageManager.getImageUrl,
		onRegisterError: handleOnRegisterError,
	})

	const handleDisableClick = (e: React.MouseEvent) => {
		e.stopPropagation()
		e.preventDefault()
	}

	const handleRetry = () => {
		setImageFailedToLoad(false)
		imageSync.mutate()
	}

	const aspectRatioStyle: React.CSSProperties | undefined = props.height
		? {
			aspectRatio: simplifyAspectRatio({
				width: props.width || null,
				height: props.height,
			}),
		}
		: undefined

	if (imageSync.loading) {
		return (
			<div className={Styles.wrapper_loading} style={aspectRatioStyle}>
				<Spinner size={SpinnerSize.Small} />
			</div>
		)
	}

	if (imageSync.error) {
		return (
			<div className={Styles.wrapper_loading}
				style={aspectRatioStyle}
				aria-hidden="true"
				onClick={handleDisableClick}
			>
				<span className={Styles.retry_button}
					onClick={handleRetry}
					aria-hidden="true">
					<ReloadIcon />
				</span>
			</div>
		)
	}

	if (imageFailedToLoad) {
		return (
			<div className={Styles.wrapper_loading}
				style={aspectRatioStyle}
				aria-hidden="true"
				onClick={handleDisableClick}
			>
				<span>
					Image failed to load
				</span>
			</div>
		)
	}

	if (!imageSync.data) {
		return null
	}

	return (
		<img className={imageClasses}
			src={imageSync.data.signedUrl}
			alt={props.altText}
			style={aspectRatioStyle}
			onError={() => { setImageFailedToLoad(true) }}
			onLoad={() => { setImageLoaded(true) }}
		/>
	)
}

export default ImageFromStore
