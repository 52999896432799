/* eslint-disable max-statements */
/* eslint-disable max-len */
/* eslint-disable max-lines-per-function */
import * as PIXI from "pixi.js"
import { ColorBrandingMockupID } from "deblank-api-types"
import { MockupUtils } from "@/utils/mockupBuilder/utils/MockupUtils"
import { GetMockupImageProps, Mockup, OpenAiCosts } from "@/utils/mockupBuilder/types/MockupTypes"
import { ColorsTemplateMockupParams } from "@/utils/mockupBuilder/types/ColorsMockupTypes"
import { Mockup_SVG } from "./Mockup_SVG"
import { ColorUtils } from "@/utils/mockupBuilder/utils/ColorUtils"
import MiddleStickerBottomMask from "./images/middleSticker/bottom_mask.png"
import MiddleStickerDesignMask from "./images/middleSticker/design_mask.png"
import MiddleStickerLight from "./images/middleSticker/light.png"
import MiddleStickerShadow from "./images/middleSticker/shadow.png"
import SideStickerMask from "./images/sideSticker/mask.png"
import SideStickerShadow from "./images/sideSticker/shadow.png"
import SideStickerLight from "./images/sideSticker/light.png"
import Placeholder from "./images/placeholder.svg"
import React from "react"

// ------- DESCRIPTION ------- //
//- Figma link: https://www.figma.com/file/DYWqSJfZnrSqdOmweKf6Qk/FontMuse-(new)?type=design&node-id=822-1351&mode=design&t=QDo8iGvib0i6nIP2-0

const mockupWidth = 4845
const mockupHeight = 1615

const mockupId = ColorBrandingMockupID.colors_branding_mockup_3

type ColorAssignmentType = {
	middleBackgroundColor: string,
	middleDesign: { colorA: string, colorB: string, colorC: string, colorD: string, },
	leftDesign: {
		color_1: string,
		color_2: string,
		color_3: string,
		color_4: string,
		background: string,
	},
	rightDesign: {
		color_2: string,
		color_1: string,
		color_3: string,
		color_4: string,
		background: string,
	},
}

const getColors = async (params: {
	mockupParams: ColorsTemplateMockupParams,
	minColors: number,
}): Promise<{ colorAssignment: ColorAssignmentType, openAiCosts: OpenAiCosts, }> => {
	const { getBackgroundColor, getColorPalette, } = ColorUtils
	const { getGroupedColors, type, ...colorPalette } = params.mockupParams
	const { usage, ...colorP } = colorPalette
	const colors = Object.values(colorP).filter(c => c)

	const middleBackgroundColor = getBackgroundColor({
		colorPalette: colors,
		backgroundLuminance: 0.5,
	})

	const middleDesignColorPalette = getColorPalette({
		colors: colors,
		backgroundColor: middleBackgroundColor,
		minColors: params.minColors,
	})

	const middleColorPaletteWithoutContrastColor = middleDesignColorPalette.filter((color) => color !== middleBackgroundColor)

	const middleDesignPalettePromise = getGroupedColors({
		palette: middleColorPaletteWithoutContrastColor,
		imageSvg: Mockup_SVG.simpleMiddleDesignSvg,
		backgroundColor: middleBackgroundColor,
		numberOfGroups: params.minColors,
	},
	)

	const colorsWithoutMiddleBackground = colors.filter((color) => color !== middleBackgroundColor)

	const rightSideBackgroundColor = getBackgroundColor({
		colorPalette: colorsWithoutMiddleBackground,
		backgroundLuminance: 1,
	})

	const rightSideDesignColorPalette = getColorPalette({
		colors: colors,
		backgroundColor: rightSideBackgroundColor,
		minColors: params.minColors,
	})

	const rightSideColorPaletteWithoutContrastColor = rightSideDesignColorPalette.filter((color) => color !== rightSideBackgroundColor)

	const rightDesignPalettePromise = getGroupedColors({
		palette: rightSideColorPaletteWithoutContrastColor,
		imageSvg: Mockup_SVG.simplesideDesignSvg,
		backgroundColor: rightSideBackgroundColor,
		numberOfGroups: params.minColors,
	},
	)

	const leftSideBackgroundColor = getBackgroundColor({
		colorPalette: colorsWithoutMiddleBackground,
		backgroundLuminance: 0,
	})

	const leftSideDesignColorPalette = getColorPalette({
		colors: colors,
		backgroundColor: leftSideBackgroundColor,
		minColors: params.minColors,
	})


	const leftSideColorPaletteWithoutContrastColor = leftSideDesignColorPalette.filter((color) => color !== leftSideBackgroundColor)

	const leftSideDesignPalettePromise = getGroupedColors({
		palette: leftSideColorPaletteWithoutContrastColor,
		imageSvg: Mockup_SVG.simplesideDesignSvg,
		backgroundColor: leftSideBackgroundColor,
		numberOfGroups: params.minColors,
	},
	)

	const [middleDesignPalette, rightDesignPalette, leftSideDesignPalette,] = await Promise.all([
		middleDesignPalettePromise,
		rightDesignPalettePromise,
		leftSideDesignPalettePromise,
	])

	return {
		colorAssignment: {
			middleBackgroundColor,
			middleDesign: {
				colorA: middleDesignPalette.groupA!,
				colorB: middleDesignPalette.groupB!,
				colorC: middleDesignPalette.groupC!,
				colorD: middleDesignPalette.groupD!,
			},
			leftDesign: {
				color_1: leftSideDesignPalette.groupC!,
				color_2: leftSideDesignPalette.groupA!,
				color_3: leftSideDesignPalette.groupB!,
				color_4: leftSideDesignPalette.groupD!,
				background: leftSideBackgroundColor,
			},
			rightDesign: {
				color_1: rightDesignPalette.groupC!,
				color_2: rightDesignPalette.groupA!,
				color_3: rightDesignPalette.groupB!,
				color_4: leftSideDesignPalette.groupD!,
				background: rightSideBackgroundColor,
			},
		},
		openAiCosts: {
			completion_cost: middleDesignPalette.usage!.completion_cost + rightDesignPalette.usage!.completion_cost + leftSideDesignPalette.usage!.completion_cost,
			prompt_cost: middleDesignPalette.usage!.prompt_cost + rightDesignPalette.usage!.prompt_cost + leftSideDesignPalette.usage!.prompt_cost,
		},
	}

}

const getMockupImage = async (props: GetMockupImageProps<ColorsTemplateMockupParams>) => {
	const { app, semaphore, } = props
	const ret = await semaphore.add(async () => {
		const { colorAssignment, openAiCosts, } = await getColors({
			mockupParams: props.params,
			minColors: 5,
		})

		const [middleStickerDesignMaskSprite, middleStickerBottomMaskSprite,
			middleStickerLightSprite, middleStickerShadowSprite,
			sideStickerMaskSprite, sideStickerLightSprite,
			sideStickerShadowSprite, sideSticker2MaskSprite, sideSticker2LightSprite,
			sideSticker2ShadowSprite,
		] = await MockupUtils.loadAssets([
			MiddleStickerDesignMask, MiddleStickerBottomMask, MiddleStickerLight, MiddleStickerShadow,
			SideStickerMask, SideStickerLight, SideStickerShadow,
			SideStickerMask, SideStickerLight, SideStickerShadow,
		])

		// Create mockup container
		const mockupContainer = new PIXI.Container()
		app.stage.addChild(mockupContainer)

		const stickerWidth = mockupHeight

		mockupContainer.width = mockupWidth
		mockupContainer.height = mockupHeight

		const maskGraphics = MockupUtils.drawRect({
			color: "#FFFFFF",
			width: mockupWidth,
			height: mockupHeight,
		})

		// Use the rounded rectangle as a mask for the container
		mockupContainer.addChild(maskGraphics)
		mockupContainer.mask = maskGraphics

		// Add Background

		const backgroundShape = MockupUtils.drawRect({
			color: colorAssignment.middleDesign.colorA,
			width: mockupWidth,
			height: mockupHeight,
		})
		mockupContainer.addChild(backgroundShape)

		const createSideSticker = async (
			colors: { color_1: string, color_2: string, color_3: string, color_4: string, background: string, },
			sprites: { shadow: PIXI.Sprite, light: PIXI.Sprite, mask: PIXI.Sprite, }
		) => {
			const stickerContainer = new PIXI.Container()

			const stickerShape = MockupUtils.drawRect({
				color: colors.background,
				width: stickerWidth,
				height: mockupHeight,
			})

			stickerContainer.addChild(stickerShape)

			const stickerDesign = new PIXI.Container()
			const stickerTexture = await PIXI.Texture.fromURL(Mockup_SVG.sideDesignSVG({ colorA: colors.color_2, colorB: colors.color_1, colorC: colors.color_4, colorD: colors.color_3, }))
			const stickerSprite = new PIXI.Sprite(stickerTexture)
			stickerDesign.addChild(stickerSprite)

			const stickerprojection = MockupUtils.project({
				app: app,
				container: stickerDesign,
				projectionPoints: {
					topLeft: { x: 276, y: 281, },
					topRight: { x: 1323, y: 281, },
					bottomRight: { x: 1323, y: 1328, },
					bottomLeft: { x: 276, y: 1328, },
				},
			})

			const designMask = new PIXI.Container()
			const designMaskShape = MockupUtils.drawRect({
				color: colors.color_2,
				width: stickerWidth,
				height: mockupHeight,
			})

			designMask.addChild(designMaskShape)
			designMask.addChild(stickerprojection)

			designMask.addChild(sprites.mask)
			designMask.mask = sprites.mask

			stickerContainer.addChild(designMask)

			//Add Shadows
			sprites.shadow.blendMode = PIXI.BLEND_MODES.MULTIPLY
			stickerContainer.addChild(sprites.shadow)

			// Add Lights
			sprites.light.blendMode = PIXI.BLEND_MODES.SCREEN
			stickerContainer.addChild(sprites.light)

			return stickerContainer
		}

		// Add middle sticker

		const middleStickerContainer = new PIXI.Container()
		const middleStickerShape = MockupUtils.drawRect({
			color: colorAssignment.middleBackgroundColor,
			width: stickerWidth,
			height: mockupHeight,
		})
		middleStickerContainer.addChild(middleStickerShape)

		const middleStickerDesign = new PIXI.Container()
		const middleStickerTexture = await PIXI.Texture.fromURL(Mockup_SVG.middleDesignSVG(colorAssignment.middleDesign))
		const middleStickerSprite = new PIXI.Sprite(middleStickerTexture)
		middleStickerDesign.addChild(middleStickerSprite)

		const middleStickerprojection = MockupUtils.project({
			app: app,
			container: middleStickerDesign,
			projectionPoints: {
				topLeft: { x: 276, y: 281, },
				topRight: { x: 1323, y: 281, },
				bottomRight: { x: 1323, y: 1328, },
				bottomLeft: { x: 276, y: 1328, },
			},
		})

		const middleDesignMask = new PIXI.Container()
		const middleDesignMaskShape = MockupUtils.drawRect({
			color: colorAssignment.middleDesign.colorA,
			width: stickerWidth,
			height: mockupHeight,
		})
		middleDesignMask.addChild(middleDesignMaskShape)
		middleDesignMask.addChild(middleStickerprojection)

		middleDesignMask.addChild(middleStickerDesignMaskSprite)
		middleDesignMask.mask = middleStickerDesignMaskSprite

		middleStickerContainer.addChild(middleDesignMask)

		const bottomMask = new PIXI.Container()
		const bottomMaskShape = MockupUtils.drawRect({
			color: "#AAAAAA",
			width: stickerWidth,
			height: mockupHeight,

		})
		bottomMask.addChild(bottomMaskShape)
		bottomMask.addChild(middleStickerBottomMaskSprite)
		bottomMask.mask = middleStickerBottomMaskSprite

		middleStickerContainer.addChild(bottomMask)

		//Add Shadows
		middleStickerShadowSprite.blendMode = PIXI.BLEND_MODES.MULTIPLY
		middleStickerContainer.addChild(middleStickerShadowSprite)

		// Add Lights
		middleStickerLightSprite.blendMode = PIXI.BLEND_MODES.SCREEN
		middleStickerContainer.addChild(middleStickerLightSprite)

		// Add side stickers

		const leftSticker = await createSideSticker(colorAssignment.leftDesign,
			{ light: sideStickerLightSprite, shadow: sideStickerShadowSprite, mask: sideStickerMaskSprite, })

		const rightSticker = await createSideSticker(colorAssignment.rightDesign,
			{ light: sideSticker2LightSprite, shadow: sideSticker2ShadowSprite, mask: sideSticker2MaskSprite, })

		mockupContainer.addChild(leftSticker)
		mockupContainer.addChild(middleStickerContainer)
		mockupContainer.addChild(rightSticker)

		leftSticker.position.set(0, 0)
		middleStickerContainer.position.set(mockupHeight, 0)
		rightSticker.position.set(3230, 0)

		const imageData = MockupUtils.exportImage({
			app: app,
			container: mockupContainer,
			width: mockupWidth,
			height: mockupHeight,
			imageCosts: openAiCosts,
		})

		return imageData
	})

	return ret!

}

export const Color_Mockup_Branding_3: Mockup<ColorsTemplateMockupParams> = {
	placeholder: <Placeholder className="disabled_custom_color" />,
	getMockupImage,
	mockupId,
	description: `The mockup displays three circular stickers side-by-side. Each sticker features a different design element in the center.

1. The first sticker on the left showcases a floral motif.
2. The middle sticker is partially peeling off to reveal a rainbow design underneath.
3. The third sticker on the right also features a floral motif, but with a slightly different design or color scheme than the first.

The background of each sticker contrasts with the color of the stickers themselves.`,
}
