/* eslint-disable max-statements */
/* eslint-disable max-len */
/* eslint-disable max-lines-per-function */
import * as PIXI from "pixi.js"
import { ColorBrandingMockupID } from "deblank-api-types"
import { MockupUtils } from "@/utils/mockupBuilder/utils/MockupUtils"
import { GetMockupImageProps, Mockup, OpenAiCosts } from "@/utils/mockupBuilder/types/MockupTypes"
import { ColorsTemplateMockupParams, GroupedColorPalette } from "@/utils/mockupBuilder/types/ColorsMockupTypes"
import { Mockup_SVG } from "./Mockup_SVG"
import { ColorUtils } from "@/utils/mockupBuilder/utils/ColorUtils"
import BackBottomMask from "./images/back_bottom_mask.png"
import BackTopMask from "./images/back_top_mask.png"
import FrontBottomMask from "./images/front_bottom_mask.png"
import FrontTopMask from "./images/front_top_mask.png"
import Lights from "./images/lights.png"
import Shadows from "./images/shadows.png"
import Placeholder from "./images/placeholder.svg"
import React from "react"
// ------- DESCRIPTION ------- //
//- Figma link: https://www.figma.com/file/DYWqSJfZnrSqdOmweKf6Qk/FontMuse-(new)?type=design&node-id=822-1351&mode=design&t=QDo8iGvib0i6nIP2-0

const SCALE = 0.25

const mockupWidth = 4000 * SCALE
const mockupHeight = 3000 * SCALE

const mockupId = ColorBrandingMockupID.colors_branding_mockup_1

type ColorAssignmentType = {
	backPalette: GroupedColorPalette,
	frontPalette: GroupedColorPalette,
	backDesignTextColor: string,
	frontDesignTextColor: string,
	invertedFrontDesignTextColor: string,
}

const getColors = async (params: {
	mockupParams: ColorsTemplateMockupParams,
	minColors: number,
}): Promise<{ colorAssignment: ColorAssignmentType, openAiCosts: OpenAiCosts, }> => {
	const { getBackgroundColor, getColorPalette, getTextColor, } = ColorUtils
	const { getGroupedColors, type, ...colorPalette } = params.mockupParams
	const { usage, ...colorP } = colorPalette

	const colors = Object.values(colorP).filter(c => c)

	const backBackgroundColor = getBackgroundColor({
		colorPalette: colors,
		backgroundLuminance: 0.5,
	})

	const backDesignColorPalette = getColorPalette({
		colors: colors,
		backgroundColor: backBackgroundColor,
		minColors: params.minColors,
	})

	const backColorPaletteWithoutContrastColor = backDesignColorPalette.filter((color) => color !== backBackgroundColor)

	const backPalettePromise = getGroupedColors({
		palette: backColorPaletteWithoutContrastColor,
		imageSvg: Mockup_SVG.simpleBackDesignSvg,
		backgroundColor: backBackgroundColor,
		numberOfGroups: params.minColors,
	},)

	const frontBackgroundColor = getBackgroundColor({
		colorPalette: colors,
		backgroundLuminance: 0,
	})

	const frontDesignColorPalette = getColorPalette({
		colors: colors,
		backgroundColor: frontBackgroundColor,
		minColors: params.minColors,
	})

	const frontColorPaletteWithoutContrastColor = frontDesignColorPalette.filter((color) => color !== frontBackgroundColor)

	const frontPalettePromise = getGroupedColors({
		palette: frontColorPaletteWithoutContrastColor,
		imageSvg: Mockup_SVG.simpleFrontDesignSvg,
		backgroundColor: frontBackgroundColor,
		numberOfGroups: params.minColors,
	},
	)

	const [backPalette, frontPalette,] = await Promise.all([backPalettePromise, frontPalettePromise,])


	const backDesignTextColor = getTextColor({
		backgroundColor: frontPalette.groupA!,
		colorPalette: ["#FFFFFF", "#000000",],
	})

	const frontDesignTextColor = getTextColor({
		backgroundColor: frontPalette.groupB!,
		colorPalette: ["#FFFFFF", "#000000",],
	})


	return {
		colorAssignment: {
			backDesignTextColor,
			frontDesignTextColor,
			invertedFrontDesignTextColor: frontDesignTextColor === "#FFFFFF" ? "#000000" : "#FFFFFF",
			backPalette,
			frontPalette,
		},
		openAiCosts: {
			completion_cost: backPalette.usage!.completion_cost + frontPalette.usage!.completion_cost,
			prompt_cost: backPalette.usage!.prompt_cost + frontPalette.usage!.prompt_cost,
		},

	}
}



const getMockupImage = async (props: GetMockupImageProps<ColorsTemplateMockupParams>) => {
	const { app, semaphore, } = props

	const ret = await semaphore.add(async () => {
		const { colorAssignment, openAiCosts, } = await getColors({
			mockupParams: props.params,
			minColors: 5,
		})

		const [backBottomMaskSprite, backTopMaskSprite,
			frontBottomMaskSprite, frontTopMaskSprite,
			lightsSprite, shadowSprite,] = await MockupUtils.loadAssets([
				BackBottomMask, BackTopMask, FrontBottomMask,
				FrontTopMask, Lights, Shadows,
			])

		// Create mockup container
		const mockupContainer = new PIXI.Container()
		app.stage.addChild(mockupContainer)

		mockupContainer.width = mockupWidth
		mockupContainer.height = mockupHeight

		const maskGraphics = MockupUtils.drawRect({
			color: "#FFFFFF",
			width: mockupWidth,
			height: mockupHeight,
			rounding: 16,
		})

		// Use the rounded rectangle as a mask for the container
		mockupContainer.addChild(maskGraphics)
		mockupContainer.mask = maskGraphics

		// Add Background
		const backgroundShape = MockupUtils.drawRect({
			color: "#FFFFFF",
			width: mockupWidth,
			height: mockupHeight,
		})
		mockupContainer.addChild(backgroundShape)


		// Add back design
		const backDesignContainer = new PIXI.Container()

		const backDesignTexture = await PIXI.Texture.fromURL(Mockup_SVG.backDesignSvg({
			colorA: colorAssignment!.frontPalette.groupA!,
			colorB: colorAssignment!.backDesignTextColor,
			// eslint-disable-next-line no-useless-escape
		}).replace('\"', '"'))

		const backDesignSprite = new PIXI.Sprite(backDesignTexture)
		backDesignContainer.addChild(backDesignSprite)

		const backDesignProjection = MockupUtils.project({
			app: app,
			container: backDesignContainer,
			projectionPoints: {
				topLeft: { x: 966.2 * SCALE, y: 1310.65 * SCALE, },
				topRight: { x: 1691.9 * SCALE, y: 862.34 * SCALE, },
				bottomRight: { x: 2539.75 * SCALE, y: 2182.46 * SCALE, },
				bottomLeft: { x: 1776.18 * SCALE, y: 2655.99 * SCALE, },
			},
		})

		const backShape = MockupUtils.drawRect({
			color: "#FFFFFF",
			width: mockupWidth,
			height: mockupHeight,
		})

		const backDesign = MockupUtils.mask({
			mask: backTopMaskSprite,
			elements: [backShape, backDesignProjection,],
		})

		mockupContainer.addChild(backDesign)


		// Add back bottom design
		const backBottomDesignColor1 = MockupUtils.drawRect({
			color: "#FFFFFF",
			width: mockupWidth,
			height: mockupHeight,
		})
		const backBottomDesign = MockupUtils.mask({
			mask: backBottomMaskSprite,
			elements: [backBottomDesignColor1,],
		})

		mockupContainer.addChild(backBottomDesign)


		// Add Front Design
		const frontDesignContainer = new PIXI.Container()
		const designTexture = await PIXI.Texture.fromURL(Mockup_SVG.frontDesignSvg({
			colorA: colorAssignment!.frontPalette.groupA!,
			colorB: colorAssignment!.frontPalette.groupB!,
			colorC: colorAssignment!.frontPalette.groupC!,
			colorD: colorAssignment!.frontPalette.groupD!,
			colorE: colorAssignment!.frontDesignTextColor,
			colorF: colorAssignment!.invertedFrontDesignTextColor,

			// eslint-disable-next-line no-useless-escape
		}).replace('\"', '"'))
		const designSprite = new PIXI.Sprite(designTexture)
		frontDesignContainer.addChild(designSprite)

		const frontDesignProjection = MockupUtils.project({
			app: app,
			container: frontDesignContainer,
			projectionPoints: {
				topLeft: { x: 1436 * SCALE, y: 863 * SCALE, },
				topRight: { x: 2155 * SCALE, y: 400 * SCALE, },
				bottomRight: { x: 3086 * SCALE, y: 1705 * SCALE, },
				bottomLeft: { x: 2306 * SCALE, y: 2216 * SCALE, },
			},
		})

		const frontShape = MockupUtils.drawRect({
			color: "#FFFFFF",
			width: mockupWidth,
			height: mockupHeight,
		})

		const frontDesign = MockupUtils.mask({
			mask: frontTopMaskSprite,
			elements: [frontShape,],
		})

		frontDesign.addChild(frontDesignProjection)

		mockupContainer.addChild(frontDesign)

		const frontBottomDesign = new PIXI.Container()
		frontBottomDesign.addChild(frontBottomMaskSprite)
		frontBottomDesign.mask = frontBottomMaskSprite

		mockupContainer.addChild(frontBottomDesign)

		//Add Shadows
		shadowSprite.blendMode = PIXI.BLEND_MODES.MULTIPLY
		mockupContainer.addChild(shadowSprite)

		// Add Lights
		lightsSprite.blendMode = PIXI.BLEND_MODES.SCREEN
		mockupContainer.addChild(lightsSprite)

		const imageData = MockupUtils.exportImage({
			app: app,
			container: mockupContainer,
			width: mockupWidth,
			height: mockupHeight,
			imageCosts: openAiCosts,
		})

		return imageData

	})

	return ret!
}

export const Color_Mockup_Branding_1: Mockup<ColorsTemplateMockupParams> = {
	getMockupImage,
	placeholder: <Placeholder className="disabled_custom_color" />,
	mockupId,
	description: "The image shows a mockup featuring two overlapping rectangular cards with a floral pattern. One card displays stylized text and a web address, while both cards share a similar design theme. The cards are placed on a plain background.",

}
