import React from "react"
import Styles from "./WidgetBubbleUserMessageColor.module.scss"
import { CommonWidgetColorItemProps } from "../WidgetColorsTypes"

const WidgetBubbleUserMessageColor = (props: CommonWidgetColorItemProps) => {

	return (
		<div
			className={Styles.selected_color_palette}
			style={{ "--color_amount": props.widgetItemDetails.palette.length, } as React.CSSProperties}
		>
			{props.widgetItemDetails.palette.map((color, index) => {
				return <div key={`${index}-${color}`}
					className={Styles.selected_color_palette_color_item}
					style={{ backgroundColor: color, }}
				/>
			})}
		</div>
	)
}

export default WidgetBubbleUserMessageColor
