export const simplifyAspectRatio = (params: {
	width: number | null,
	height: number,
}) => {
	if (!params.width) {
		return "1/1"
	}

	const gcd = (a: number, b: number): number => {
		return b ? gcd(b, a % b) : a
	}
	const divisor = gcd(params.width, params.height)

	const simplifiedWidth = params.width / divisor
	const simplifiedHeight = params.height / divisor

	return `${simplifiedWidth} / ${simplifiedHeight}`
}
