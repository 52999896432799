import React from "react"
import Logo from "deblank-common/src/assets/images/brand/deblank.svg"
import Styles from "./Header.module.scss"
import ButtonIcon from "@/components/Buttons/ButtonIcon/ButtonIcon"
import SideBarIcon from "deblank-common/src/assets/images/icon-library/sidebar.svg"
import NewChatIcon from "deblank-common/src/assets/images/icon-library/new-chat.svg"
import PinIcon from "deblank-common/src/assets/images/icon-library/pin.svg"
import { settersUiTemporary } from "@/recoil/Ui/Temporary/Setters"
import { useRecoilValue } from "recoil"
import { selectorsMessages } from "@/recoil/ConversationsRecord/Selectors/Messages"
import { selectorsConversations } from "@/recoil/ConversationsRecord/Selectors/Conversations"
import { settersConversations } from "@/recoil/ConversationsRecord/Setters/Conversations"
import Button from "../Buttons/Button/Button"
import SavingIcon from "deblank-common/src/assets/images/icon-library/saving.svg"
import ErrorSaving from "deblank-common/src/assets/images/icon-library/saving-error.svg"

const Header = () => {
	const someMessageIsLoading = useRecoilValue(selectorsMessages.someMessageIsLoading)
	const showNewChat = useRecoilValue(selectorsConversations.showNewChat)
	const conversationSaveState = useRecoilValue(selectorsConversations.conversationSaveState)
	const isSavingNewConversationName = useRecoilValue(selectorsConversations.isSavingNewConversationName)

	const setConversationSaveState = settersConversations.useSetConversationSaveState()
	const toggleSidebar = settersUiTemporary.useToggleIsOpenSidebar()
	const togglePinned = settersUiTemporary.useToggleIsOpenPinned()
	const setShowNewChat = settersConversations.useSetShowNewChat()

	const handleRetrySave = () => {
		setConversationSaveState("pendingSave")
	}

	return (
		<header className={Styles.header}>
			<div className={Styles.mobile_actions}>
				<ButtonIcon
					type="button"
					onClick={toggleSidebar}
					iconSVGComponent={SideBarIcon}
					customStyles={{
						variant: "ghost",
					}}
				/>
				<ButtonIcon
					type="button"
					onClick={() => setShowNewChat({ showNewChatView: true, })}
					iconSVGComponent={NewChatIcon}
					customStyles={{
						variant: "ghost",
						background: true,
					}}
					disabled={someMessageIsLoading || showNewChat || isSavingNewConversationName}
				/>
			</div>
			<div className={Styles.logo_container}>
				<Logo className={Styles.logo} />
			</div>

			<div className={Styles.content_mobile_actions_and_save}>
				{
					(conversationSaveState === "saving" || conversationSaveState === "saveError")
					&& <section className={Styles.wrapper_saving}>
						{(conversationSaveState === "saving")
							&& <article className={Styles.content_saving}>
								<SavingIcon />
								<span>
									Saving...
								</span>
							</article>
						}
						{(conversationSaveState === "saveError")
							&& <article className={Styles.content_error_saving}>
								<ErrorSaving />
								Error saving
								<Button type="button"
									onClick={handleRetrySave}
									customStyles={{
										variant: "ghost",
										size: "small",
									}}
								>
									Retry
								</Button>
							</article>
						}
					</section>
				}
				<div className={Styles.mobile_actions}>
					<ButtonIcon
						type="button"
						onClick={togglePinned}
						iconSVGComponent={PinIcon}
						customStyles={{
							variant: "ghost",
						}}
					/>
				</div>
			</div>
		</header>
	)
}

export default Header
