/* eslint-disable max-statements */
/* eslint-disable max-len */
/* eslint-disable max-lines-per-function */
import * as PIXI from "pixi.js"
import { FontsBrandingMockupID } from "deblank-api-types"
import { MockupUtils } from "@/utils/mockupBuilder/utils/MockupUtils"
import { GetMockupImageProps, Mockup } from "@/utils/mockupBuilder/types/MockupTypes"
import { FontsTemplateMockupParams } from "@/utils/mockupBuilder/types/FontsMockupTypes"
import Background from "./images/background.png"
import BackLogo from "./images/back_logo.png"
import BackMask from "./images/back_mask.png"
import CardsLight from "./images/cards_lights.png"
import CardsShadow from "./images/cards_shadows.png"
import FrontLogo from "./images/front_logo.png"
import FrontMask from "./images/front_mask.png"
import Placeholder from "./images/placeholder.svg"
import React from "react"


//------- DESCRIPTION ------- //
//- Figma link: https://www.figma.com/file/DYWqSJfZnrSqdOmweKf6Qk/FontMuse-(new)?type=design&node-id=745-1324

const mockupId = FontsBrandingMockupID.fonts_branding_mockup_1
const mockupWidth = 2110
const mockupHeight = 1583

const getMockupImage = async (props: GetMockupImageProps<FontsTemplateMockupParams>) => {
	const { params, app, } = props
	const ret = await props.semaphore.add(async () => {

		const [
			backgroundSprite,
			backMaskSprite, cardsLightSprite,
			cardsShadowSprite, frontMaskSprite,
			backLogoSprite, frontLogoSprite,
		] = await MockupUtils.loadAssets([
			Background, BackMask, CardsLight, CardsShadow,
			FrontMask, BackLogo, FrontLogo,])

		// Create mockup container
		const cardWidth = 1050
		const cardHeight = 600
		const backLogoWidth = 130
		const backLogoHeight = 132
		const backLogoX = 686.41
		const backLogoY = 363
		const backPhraseX = 80.41
		const backPhraseY = 88
		const backTagX = 80.41
		const backTagY = 463
		const frontLogoWidth = 576
		const frontLogoHeight = 576
		const frontLogoX = 410.41
		const frontLogoY = 23
		const frontBrandX = 79.41
		const frontBrandY = 64
		const frontSubtitleX = 79.41
		const frontSubtitleY = 214

		const mockupContainer = new PIXI.Container()
		const mockupShape = MockupUtils.drawRect({
			width: mockupWidth,
			height: mockupHeight,
			color: "#D7D7D7",
		})
		mockupContainer.addChild(mockupShape)

		app.stage.addChild(mockupContainer)

		// Background

		mockupContainer.addChild(backgroundSprite)

		// Back Card Design
		const designContainer = new PIXI.Container()
		designContainer.width = cardWidth
		designContainer.height = cardHeight
		const dGraphic = MockupUtils.drawRect({
			color: "#FFFFFF",
			width: cardWidth,
			height: cardHeight,
		})
		designContainer.addChild(dGraphic)

		const backLogoContainer = new PIXI.Container()
		backLogoSprite.width = backLogoWidth
		backLogoSprite.height = backLogoHeight
		const backLogoGraphic = MockupUtils.drawRect({
			color: "#080F0F",
			width: backLogoWidth,
			height: backLogoHeight,
		})

		backLogoContainer.addChild(backLogoGraphic)
		backLogoContainer.addChild(backLogoSprite)
		backLogoContainer.mask = backLogoSprite
		backLogoContainer.position.set(backLogoX, backLogoY)

		designContainer.addChild(backLogoContainer)


		const backPhrase = await MockupUtils.createText({
			text: "Street food flavours in all yout meals",
			fontFamily: params.title.font,
			fontWeight: 400,
			fontWeights: params.title.weights,
			color: "#080F0F",
			fontSize: 80,
			wrapText: true,
			width: 500,
			wordWrapWidth: 500,
			trim: true,
			padding: 400,
			fitText: {
				respectTextMaxSize: true,
				containerHeight: 350,
			},
		})

		designContainer.addChild(backPhrase)
		backPhrase.position.set(backPhraseX, backPhraseY)

		const backTag = await MockupUtils.createText({
			text: "@deblank",
			fontFamily: params.body.font,
			fontWeight: 400,
			fontWeights: params.body.weights,
			color: "#080F0F",
			fontSize: 32,
			borderWidth: 3,
		})

		designContainer.addChild(backTag)
		backTag.position.set(backTagX, backTagY)

		// Back Design Projection

		const backDesignProjection = MockupUtils.project({
			app: app,
			container: designContainer,
			projectionPoints: {
				topLeft: { x: 514.43, y: 581.95, },
				topRight: { x: 1276.72, y: 856.18, },
				bottomRight: { x: 1073.2, y: 1313.2, },
				bottomLeft: { x: 273, y: 1006.2, },
			},
		})

		const backCont = new PIXI.Container()
		backCont.addChild(backDesignProjection)
		backCont.mask = backMaskSprite
		backCont.addChild(backMaskSprite)

		mockupContainer.addChild(backCont)


		// Front Card

		const frontDesignContainer = new PIXI.Container()
		const frontGraphic = MockupUtils.drawRect({
			color: "#FFB44D",
			width: cardWidth,
			height: cardHeight,
		})
		frontDesignContainer.addChild(frontGraphic)

		const frontLogoContainer = new PIXI.Container()
		frontLogoSprite.width = frontLogoWidth
		frontLogoSprite.height = frontLogoHeight
		const frontLogoGraphic = MockupUtils.drawRect({
			color: "#000000",
			width: frontLogoWidth,
			height: frontLogoHeight,
		})
		frontLogoContainer.addChild(frontLogoGraphic)
		frontLogoContainer.addChild(frontLogoSprite)
		frontLogoContainer.mask = frontLogoSprite
		frontLogoContainer.position.set(frontLogoX, frontLogoY)
		frontDesignContainer.addChild(frontLogoContainer)

		const brand = await MockupUtils.createText({
			text: "deblank",
			fontFamily: params.title.font,
			fontWeights: params.title.weights,
			fontWeight: 400,
			color: "#080F0F",
			fontSize: 115,
			trim: true,
			padding: 400,
		})

		frontDesignContainer.addChild(brand)
		brand.position.set(frontBrandX, frontBrandY)

		const subtitle = await MockupUtils.createText({
			text: "Substance use support customized for you",
			fontFamily: params.body.font,
			fontWeight: 500,
			fontWeights: params.body.weights,
			color: "#080F0F",
			fontSize: 32,
			wrapText: true,
			wordWrapWidth: 300,
			width: 300,
		})

		frontDesignContainer.addChild(subtitle)
		subtitle.position.set(frontSubtitleX, frontSubtitleY)


		// Front Card Proyection

		const frontDesignProjection = MockupUtils.project({
			app: app,
			container: frontDesignContainer,
			projectionPoints: {
				topLeft: { x: 1107.7, y: 390.53, },
				topRight: { x: 1903.68, y: 664.22, },
				bottomRight: { x: 1699.25, y: 1107.97, },
				bottomLeft: { x: 883.5, y: 806.15, },
			},
		})
		const frontCont = new PIXI.Container()
		frontCont.addChild(frontDesignProjection)
		frontCont.mask = frontMaskSprite
		frontCont.addChild(frontMaskSprite)

		mockupContainer.addChild(frontCont)

		cardsLightSprite.blendMode = PIXI.BLEND_MODES.SCREEN
		cardsShadowSprite.blendMode = PIXI.BLEND_MODES.MULTIPLY

		mockupContainer.addChild(cardsLightSprite)
		mockupContainer.addChild(cardsShadowSprite)

		const imageData = MockupUtils.exportImage({
			app: app,
			container: mockupContainer,
			height: mockupHeight,
			width: mockupWidth,
			imageCosts: null,
		})


		return imageData
	})
	return ret!

}

export const Fonts_Mockup_Branding_1: Mockup<FontsTemplateMockupParams> = {
	placeholder: <Placeholder className="disabled_custom_color" />,
	getMockupImage,
	mockupId,
	description: `
	This mockup features two rounded-corner cards partially overlapping each other on a light gray background.

1. The top card:
   - Has a vibrant green color.
   - Displays a line drawing of a person riding a bicycle accompanied by a dog.

2. The bottom card:
   - Is primarily white.
   - Features a simple line illustration of a dog standing upright in the bottom right corner.

The overall design is clean, with lively illustrations that add a playful touch to the cards.`,
}
