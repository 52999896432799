import React, { useContext, useState } from "react"
import Styles from "./BrandCard.module.scss"
import {
	DisplayWidgetType, EntryBrandsResponse, IdRefWidgetWithPaginationItem
} from "deblank-api-types"
import LinkIcon from "deblank-common/src/assets/images/icon-library/link.svg"
import LeftArrowIcon from "deblank-common/src/assets/images/icon-library/arrow-left.svg"
import RightArrowIcon from "deblank-common/src/assets/images/icon-library/arrow-right.svg"
import Actions from "@/components/Actions/Actions"
import ColorItem from "../ColorItem/ColorItem"
import usePresetActions from "@/components/Actions/usePresetActions"
import { widgetAction } from "@/pages/AssistantPage/components/CurrentConversation/MessageList/WidgetSelector/WidgetsTypes"
import { TrackConversationEventContext } from "@/pages/AssistantPage/TrackConversationEventProvider"
import { DownloadHelper } from "@/utils/exportHelper"
import classNames from "classnames"
import ButtonIcon from "@/components/Buttons/ButtonIcon/ButtonIcon"
import { settersCommon } from "@/recoil/ConversationsRecord/Setters/Common"
import DownloadIcon from "deblank-common/src/assets/images/icon-library/download.svg"

type Props = {
	idRef: IdRefWidgetWithPaginationItem,
	widgetDetail: EntryBrandsResponse,
	widgetType: DisplayWidgetType,
	onNextCard: {
		onClick: () => void,
		isDisabled: boolean,
	},
	onPreviousCard: {
		onClick: () => void,
		isDisabled: boolean,
	},
	onClickExternalLink: (params: { brandId: string, link: string, }) => void,
	isActive: boolean,
	showArrowsActions: boolean,
	minHeight: number,
	cardPosition: number,
}

const BrandCard = React.forwardRef((
	props: Props,
	ref: React.ForwardedRef<HTMLDivElement>,
) => {

	const [actionsOpen, setActionsOpen,] = useState<boolean>(false)

	const paletteRef = React.useRef<HTMLDivElement>(null)

	const setImageModal = settersCommon.useSetImageModalIdRef()

	const { trackConversationEvent, } = useContext(TrackConversationEventContext)

	const { pinPresetAction, } = usePresetActions({
		idRef: props.idRef,
		widgetType: props.widgetType,
	})

	const handleDownload = async () => {
		await DownloadHelper.fromRefTemplate(paletteRef)
		trackConversationEvent({
			eventName: "Download",
			widgetType: props.widgetType,
			responseId: props.idRef.messageId,
			outputId: props.idRef.widgetItemId,
		})
	}

	const handleOpenImageModal = () => {
		trackConversationEvent({
			eventName: "OpenImageModal",
			widgetType: props.widgetType,
			responseId: props.idRef.widgetId,
		})
		setImageModal({ idRef: props.idRef, })
	}

	const actionList: widgetAction[] = [
		pinPresetAction,
		{
			text: "Download",
			icon: DownloadIcon,
			onClick: handleDownload,
		},
	]

	const containerClasses = classNames(
		Styles.container,
		{
			[Styles.active]: props.isActive,
		}
	)

	const headerContainerClasses = classNames(
		Styles.header_container,
		"actions_handler",
	)

	const handleOnClickExternalLink = () => {
		props.onClickExternalLink({
			brandId: props.widgetDetail.id,
			link: props.widgetDetail.link!,
		})
	}

	return (
		<section ref={ref}
			className={containerClasses}
			style={{
				"minHeight": `${props.minHeight}px`,
				"--card_position": `-${props.cardPosition}px`,
				"--card_level": `${props.cardPosition}`,
			} as React.CSSProperties}
		>
			<section className={Styles.content_description}>
				<div className={headerContainerClasses}>

					<div className={Styles.top_actions}>
						<Actions
							actionList={actionList}
							onSetActionsOpen={setActionsOpen}
							customStyles={{
								actionType: "brands",
							}}
						/>
						{props.showArrowsActions
							&& <div className={Styles.content_prev_next}>
								<ButtonIcon
									type="button"
									disabled={props.onPreviousCard.isDisabled}
									onClick={props.onPreviousCard.onClick}
									iconSVGComponent={LeftArrowIcon}
									customStyles={{ variant: "ghost", }}
								/>
								<ButtonIcon
									type="button"
									disabled={props.onNextCard.isDisabled}
									onClick={props.onNextCard.onClick}
									iconSVGComponent={RightArrowIcon}
									customStyles={{ variant: "ghost", }}
								/>
							</div>
						}
					</div>

					<div className={Styles.title_container}>
						<p className={Styles.title}>
							{props.widgetDetail.name}
						</p>

						{props.widgetDetail.link
							&& <button className={Styles.icon_link}
								onClick={handleOnClickExternalLink}>
								<LinkIcon />
							</button>
						}
					</div>
				</div>

				<p className={Styles.brand_information}>
					{props.widgetDetail.description}
				</p>
			</section>

			<section className={Styles.assets_container}>
				<button type="button"
					className={Styles.image_wrapper}
					onClick={handleOpenImageModal} >
					<img className={Styles.image}
						src={props.widgetDetail.logoUrl}
						alt={props.widgetDetail.name}
					/>
				</button>

				<div ref={paletteRef}>
					<ColorItem
						key={props.widgetDetail.id}
						palette={props.widgetDetail.colors}
					/>
				</div>
			</section>
		</section>
	)
})

BrandCard.displayName = "BrandCard"

export default BrandCard
