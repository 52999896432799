/* eslint-disable max-statements */
/* eslint-disable max-len */
/* eslint-disable max-lines-per-function */
import * as PIXI from "pixi.js"
import { FontsPosterMockupID } from "deblank-api-types"
import { MockupUtils } from "@/utils/mockupBuilder/utils/MockupUtils"
import { GetMockupImageProps, Mockup } from "@/utils/mockupBuilder/types/MockupTypes"
import { FontsTemplateMockupParams } from "@/utils/mockupBuilder/types/FontsMockupTypes"
import Background from "./images/background.png"
import LogoMask from "./images/logo_mask.png"
import Mask from "./images/mask.png"
import Shadow from "./images/shadow.png"
import Placeholder from "./images/placeholder.svg"
import React from "react"

// ------- DESCRIPTION ------- //
//- Figma link: https://www.figma.com/file/DYWqSJfZnrSqdOmweKf6Qk/FontMuse-(new)?type=design&node-id=1023-3517&mode=design&t=a57C1AjO0TOFlVmU-0

const mockupId = FontsPosterMockupID.fonts_poster_mockup_2
const mockupWidth = 2110
const mockupHeight = 2116

// Based on: https://jsfiddle.net/themoonrat/br35x20j/ + CHATGPT to calculate arc points.
const getCurvedText = async (params: {
	text: string,
	font: string,
	weights: number[],
	color: string,
	containerWidth: number,
}) => {
	const textContainer = new PIXI.Container()

	const text = await MockupUtils.createText({
		text: `${params.text} · ${params.text}`,
		fontFamily: params.font,
		fontWeight: 700,
		fontWeights: params.weights,
		color: params.color,
		fontSize: 82,
		padding: 50,
		trim: true,
	})
	text.updateText(false)
	const radius = 300
	const maxArcPoints = 100
	const startAngle = -Math.PI / -2 // Adjust the starting angle as needed
	const endAngle = Math.PI / -2   // Adjust the ending angle as needed

	const step = (endAngle - startAngle) / maxArcPoints

	const points = []
	for (let i = 0; i <= maxArcPoints; i++) {
		const angle = startAngle + (step * i)
		const x = radius * Math.cos(angle)
		const y = radius * Math.sin(angle)
		points.push(new PIXI.Point(x, -y)) // Negate y to match your coordinate system
	}

	const rope = new PIXI.SimpleRope(text.texture, points)

	textContainer.addChild(rope)
	textContainer.angle = -90
	textContainer.pivot.set(0, 0)
	const curvedTextTop = 150
	textContainer.position.set((textContainer.width / 2) + ((params.containerWidth - textContainer.width) / 2), (textContainer.height / 2) + curvedTextTop)

	return textContainer
}

const getMockupImage = async (props: GetMockupImageProps<FontsTemplateMockupParams>) => {
	const { params, app, } = props
	const ret = await props.semaphore.add(async () => {
		const [
			backgroundSprite,
			logoMaskSprite, maskSprite,
			shadowSprite,
		] = await MockupUtils.loadAssets([
			Background, LogoMask, Mask, Shadow,])

		// Create mockup container
		const mockupContainer = new PIXI.Container()
		app.stage.addChild(mockupContainer)


		mockupContainer.width = mockupWidth
		mockupContainer.height = mockupHeight

		// Background
		mockupContainer.addChild(backgroundSprite)

		//Poster
		const posterContainer = new PIXI.Container()
		const posterWidth = 980
		const posterHeight = 1019.26

		posterContainer.width = posterWidth
		posterContainer.height = posterHeight

		const posterBackground = MockupUtils.drawRect({
			width: posterWidth,
			height: posterHeight,
			color: "#00AF8A",
		})
		posterContainer.addChild(posterBackground)

		//Logo
		const logoBackground = MockupUtils.drawRect({
			width: mockupWidth,
			height: mockupHeight,
			color: "#B2DDDA",
		})

		const logoMask = new PIXI.Container()
		logoMask.addChild(logoMaskSprite)
		logoMask.addChild(logoBackground)
		logoMask.mask = logoMaskSprite
		posterContainer.addChild(logoMask)

		//Main Text
		const curvedText = await getCurvedText({
			text: "GOOD VIBES",
			font: params.title.font,
			weights: params.title.weights,
			color: "#B2DDDA",
			containerWidth: posterWidth,
		})

		posterContainer.addChild(curvedText)

		//Subtitle
		const subtitle = await MockupUtils.createText({
			text: "AS THE SUN ROSE OVER THE HORIZON",
			fontFamily: params.body.font,
			fontWeights: params.body.weights,
			fontWeight: 500,
			color: "#B2DDDA",
			fontSize: 32,
		})
		const subtitleBottom = 170
		subtitle.position.set((posterWidth - subtitle.width) / 2, posterHeight - subtitle.height - subtitleBottom)
		posterContainer.addChild(subtitle)


		const posterSprite = MockupUtils.project({
			app: app,
			container: posterContainer,
			projectionPoints: {
				topLeft: { x: 652, y: 431, },
				topRight: { x: 1632, y: 425, },
				bottomRight: { x: 1631, y: 1448, },
				bottomLeft: { x: 648, y: 1450, },
			},
		})

		//Mask
		const maskContainer = new PIXI.Container()
		maskContainer.addChild(maskSprite)
		maskContainer.addChild(posterSprite)
		maskContainer.mask = maskSprite
		mockupContainer.addChild(maskContainer)

		//Shadow
		shadowSprite.blendMode = PIXI.BLEND_MODES.MULTIPLY
		mockupContainer.addChild(shadowSprite)

		const imageData = MockupUtils.exportImage({
			app: app,
			container: mockupContainer,
			height: mockupHeight,
			width: mockupWidth,
			imageCosts: null,
		})

		return imageData
	})

	return ret!


}

export const Fonts_Mockup_Poster_2: Mockup<FontsTemplateMockupParams> = {
	placeholder: <Placeholder className="disabled_custom_color" />,
	getMockupImage,
	mockupId,
	description: `
	The mockup features a large, vibrant pink banner affixed to the facade of a multi-story brick building. The banner takes up a significant portion of the wall, framed by weathered architecture with fire escapes and windows. At the center of the pink banner is a prominent white illustration of a hand making a peace sign. The overall setting appears to be an urban environment with a mix of older and slightly worn buildings lining the street. The juxtaposition between the bright banner and the aged brick facade creates a visually striking and optimistic contrast.`,
}
