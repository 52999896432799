/* eslint-disable max-statements */
/* eslint-disable max-len */
/* eslint-disable max-lines-per-function */
import * as PIXI from "pixi.js"
import { FontsPosterMockupID } from "deblank-api-types"
import { MockupUtils } from "@/utils/mockupBuilder/utils/MockupUtils"
import { GetMockupImageProps, Mockup } from "@/utils/mockupBuilder/types/MockupTypes"
import { FontsTemplateMockupParams } from "@/utils/mockupBuilder/types/FontsMockupTypes"
import Background from "./images/background.png"
import Mask from "./images/mask.png"
import PosterImage from "./images/poster_image.png"
import Shadow from "./images/shadow.png"
import LogoMask from "./images/logo_mask.png"
import Placeholder from "./images/placeholder.svg"
import React from "react"

//------- DESCRIPTION ------- //
//- Figma link: https://www.figma.com/file/DYWqSJfZnrSqdOmweKf6Qk/FontMuse-(new)?type=design&node-id=745-1324


const mockupId = FontsPosterMockupID.fonts_poster_mockup_1

const mockupWidth = 2110
const mockupHeight = 1667

const getMockupImage = async (props: GetMockupImageProps<FontsTemplateMockupParams>) => {
	const { params, app, } = props
	const ret = await props.semaphore.add(async () => {

		const [
			backgroundSprite,
			shadowSprite, maskSprite,
			posterImageSprite, logoMaskSprite,
		] = await MockupUtils.loadAssets([
			Background, Shadow, Mask, PosterImage, LogoMask,])

		// Create mockup container
		const mockupContainer = new PIXI.Container()
		app.stage.addChild(mockupContainer)


		// Add Background
		mockupContainer.addChild(backgroundSprite)

		//------- POSTER ------- //
		// Build poster
		const posterContainer = new PIXI.Container()
		const posterWidth = 1129
		const posterHeight = 752


		// Build background
		const backgroundShape = MockupUtils.drawRect({
			color: "#164021",
			width: posterWidth,
			height: posterHeight,
		})
		posterContainer.addChild(backgroundShape)


		// Left Column
		const leftColumnContainer = new PIXI.Container()
		leftColumnContainer.position.set(24, 24)
		const leftColumnWidth = 507
		const leftColumnHeight = 704
		leftColumnContainer.width = leftColumnWidth
		leftColumnContainer.height = leftColumnHeight

		const leftColumnBackgroundShape = MockupUtils.drawRect({
			color: "#CFF06B",
			width: leftColumnWidth,
			height: leftColumnHeight,
			rounding: 12,
		})

		leftColumnContainer.addChild(leftColumnBackgroundShape)

		const mainText = await MockupUtils.createText({
			text: "THE FUTURE OF YOUR BRAND",
			fontFamily: params.title.font,
			fontWeight: 700,
			fontWeights: params.title.weights,
			color: "#164021",
			fontSize: 64,
			wrapText: true,
			wordWrapWidth: 400,
			trim: true,
		})

		//position text related to text height
		mainText.position.set(48, leftColumnHeight - mainText.height - 48)

		leftColumnContainer.addChild(mainText)


		//Logo
		const logoShape = MockupUtils.drawRect({
			color: "#164021",
			width: 49,
			height: 56,
		})

		const logoMask = MockupUtils.mask({
			mask: logoMaskSprite,
			elements: [logoShape,],
		})
		logoMask.position.set(48, 48)

		leftColumnContainer.addChild(logoMask)

		posterContainer.addChild(leftColumnContainer)


		// Right Column
		const rightColumnContainer = new PIXI.Container()
		const rightColumnWidth = 550
		const rightColumnHeight = 704
		rightColumnContainer.width = rightColumnWidth
		rightColumnContainer.height = rightColumnHeight
		rightColumnContainer.position.set(555, 24)
		rightColumnContainer.addChild(posterImageSprite)
		posterContainer.addChild(rightColumnContainer)

		const verticalText = await MockupUtils.createText({
			text: "In the still of the night",
			fontFamily: params.body.font,
			fontWeight: 500,
			fontWeights: params.body.weights,
			color: "#164021",
			fontSize: 24,
		})

		verticalText.angle = 90
		verticalText.position.set(rightColumnWidth - verticalText.height - 36, rightColumnHeight - verticalText.width - 64)
		rightColumnContainer.addChild(verticalText)


		const horizontalText = await MockupUtils.createText({
			text: "pelago.com",
			fontFamily: params.body.font,
			fontWeights: params.body.weights,
			fontWeight: 500,
			color: "#164021",
			fontSize: 24,
		})

		horizontalText.position.set(rightColumnWidth - horizontalText.width - 64 - verticalText.height, rightColumnHeight - horizontalText.height - 36)
		rightColumnContainer.addChild(horizontalText)


		// Projection
		const posterSprite = MockupUtils.project({
			app: app,
			container: posterContainer,
			projectionPoints: {
				topLeft: { x: 708, y: 427, },
				topRight: { x: 1649, y: 237, },
				bottomRight: { x: 1662, y: 1100, },
				bottomLeft: { x: 710, y: 1150, },
			},
		})


		// Apply mask
		const posterMask = MockupUtils.mask({
			mask: maskSprite,
			elements: [posterSprite,],
		})

		shadowSprite.blendMode = PIXI.BLEND_MODES.MULTIPLY
		posterMask.addChild(shadowSprite)

		mockupContainer.addChild(posterMask)


		const imageData = MockupUtils.exportImage({
			app: app,
			container: mockupContainer,
			height: mockupHeight,
			width: mockupWidth,
			imageCosts: null,
		})

		return imageData
	})
	return ret!
}

export const Fonts_Mockup_Poster_1: Mockup<FontsTemplateMockupParams> = {
	placeholder: <Placeholder className="disabled_custom_color" />,
	getMockupImage,
	mockupId,
	description: `
	The mockup features a large rectangular billboard positioned outdoors, set against a clear blue sky with a modern building in the background. The billboard is divided vertically into two sections with a bright yellow border around it.

- The left section has a dark green background with a small, minimalist logo in the top-left corner.
- The right section showcases a high-resolution aerial photograph of a rugged, mountainous landscape in shades of brown and tan, with dramatic light and shadow contrasts.

The overall design of the billboard is modern and striking, drawing attention through the contrasting visuals and bold colors.`,
}
