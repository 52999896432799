import React, { useEffect, useMemo } from "react"
import { selectorsFontCache } from "@/recoil/FontCache/Selectors"
import { useRecoilValue } from "recoil"
import * as Sentry from "@sentry/react"
import { trpc } from "@/providers/TRPCProvider"
import { settersFontCache } from "@/recoil/FontCache/Setters"
import { FontUtils } from "@/utils/FontUtils"

const FontsCacheManager = () => {

	const getFontsMetadata = trpc.assistant.fonts.getFontsMetadata.useMutation()

	const addFontsToCache = settersFontCache.useAddFontsToCache()
	const updateFontLoadState = settersFontCache.useUpdateFontLoadState()

	const pendingSlugs = useRecoilValue(selectorsFontCache.pendingSlugs)
	const fontList = useRecoilValue(selectorsFontCache.fontList)

	const getFontsMetadataFromSlugs = async () => {
		if (pendingSlugs.length > 0) {
			const fontsMetadata = await getFontsMetadata.mutateAsync({
				slugs: pendingSlugs,
			})

			if (getFontsMetadata) {
				addFontsToCache(fontsMetadata)
			}
		}
	}

	useEffect(() => {
		if (pendingSlugs.length > 0) {
			getFontsMetadataFromSlugs()
		}
	}, [pendingSlugs,])


	const fontUrls = useMemo(() => fontList.map(font => {
		const href = FontUtils.getFontHref(font)

		return {
			name: font.name,
			slug: font.slug,
			isCSSLoaded: font.isCSSLoaded,
			vendor: font.vendor,
			href,
			weights: font.weights,
		}
	}), [fontList,])

	useEffect(() => {
		const loadFonts = async () => {
			for (const font of fontUrls) {
				if (!font.isCSSLoaded) {
					let fontWeightsLoaded = false
					for (const weight of font.weights) {
						const isLoaded = await FontUtils.isFontLoaded(font.name, weight.code)
						if (isLoaded) {
							fontWeightsLoaded = true
						} else {
							const errorMessage = `Error loading font ${font.slug}, weight ${weight.code}, from ${font.href}`
							console.error(errorMessage)
							Sentry.captureMessage(errorMessage)
						}
					}
					if (fontWeightsLoaded) {
						updateFontLoadState(font.slug)
					}
				}
			}
		}
		loadFonts()
	}, [fontUrls,])

	return (
		<span id="loaded-fonts">
			{fontUrls.map(({ href, vendor, }) => (
				<link key={href}
					href={href}
					rel="stylesheet"
					crossOrigin={vendor === "google" ? "anonymous" : undefined}
				/>
			))}
		</span>
	)
}

export default FontsCacheManager
