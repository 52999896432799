enum Transition {
	DataModified = "DataModified",
	SaveSucceeded = "SaveSucceeded",
	SaveFailed = "SaveFailed",
}

export enum SaveState {
	Idle = "Idle",
	Saving = "Saving",
	SaveQueued = "SaveQueued",
	Error = "Error",
}

export class SaveStateMachine<T> {
	private pendingData?: T
	private saveFunction: (data: T) => Promise<void>
	private notifyStateChange: (state: SaveState) => void
	private currentState: SaveState = SaveState.Idle

	constructor(params: {
		saveFunction: (data: T) => Promise<void>,
		notifyStateChange: (state: SaveState) => void,
	}) {
		this.saveFunction = params.saveFunction
		this.notifyStateChange = params.notifyStateChange
	}

	public onDataModified(data: T) {
		this.pendingData = data
		this.transition(Transition.DataModified)
	}

	private stateHandlers = {
		[SaveState.Saving]: async () => {
			if (!this.pendingData) {
				throw new Error("No data to save")
			}
			try {
				await this.saveFunction(this.pendingData)
				this.transition(Transition.SaveSucceeded)
			} catch (e) {
				this.transition(Transition.SaveFailed)
			}
		},
		[SaveState.SaveQueued]: () => {
			// Save is already in progress; data is queued
		},
		[SaveState.Error]: () => {
			// Handle error state: maybe retry logic
		},
		[SaveState.Idle]: () => {
			this.pendingData = undefined
		},
	}

	private updateState(state: SaveState) {
		this.currentState = state
		this.notifyStateChange(state)
		this.stateHandlers[state]()
	}

	private transitions = {
		[Transition.DataModified]: {
			[SaveState.Idle]: SaveState.Saving,
			[SaveState.Error]: SaveState.Saving,
			[SaveState.Saving]: SaveState.SaveQueued,
			[SaveState.SaveQueued]: SaveState.SaveQueued,
		},
		[Transition.SaveSucceeded]: {
			[SaveState.Saving]: SaveState.Idle,
			[SaveState.SaveQueued]: SaveState.Saving,
		},
		[Transition.SaveFailed]: {
			[SaveState.Saving]: SaveState.Error,
			[SaveState.SaveQueued]: SaveState.Error,
		},
	}

	private transition = (transition: Transition) => {
		const options = this.transitions[transition] as Record<SaveState, SaveState>
		const nextState = options[this.currentState]
		if (!nextState) {
			throw new Error(`Invalid transition ${transition} from ${this.currentState}`)
		}
		this.updateState(nextState)
	}
}
