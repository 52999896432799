import * as Sentry from "@sentry/react"
import {
	IdRefWidgetWithPaginationItem, DisplayWidgetType, EntryColorsResponse,
	FontResponse, FontsMetadata, ImageResponse, IdRefType, IdRefWidget
} from "deblank-api-types"
import { selectorFamily } from "recoil"
import { atomConversationsRecord } from "../Atom"
import { prefixRecoilKey } from "./Common"
import { selectorsMessages } from "./Messages"


type RelatedActionsColors = {
	idRef: IdRefWidgetWithPaginationItem,
} & ({
	type: DisplayWidgetType.Colors,
	widgetData: EntryColorsResponse,
} | {
	type: DisplayWidgetType.Fonts,
	widgetData: FontResponse,
	metadata: FontsMetadata,
} | {
	type: DisplayWidgetType.Images,
	widgetData: ImageResponse,
})

const widgetItemsForRelatedActions = selectorFamily<RelatedActionsColors[] | null, IdRefWidget>({
	key: prefixRecoilKey("widgetItemsForRelatedActions"),
	get: (originIdRef) => ({ get, }) => {
		const { activeConversationId, conversations, } = get(atomConversationsRecord)
		if (!activeConversationId) {
			return null
		}
		const currentConversation = conversations[activeConversationId]

		const message = get(selectorsMessages.messageById(originIdRef.messageId))
		const widgetItems: RelatedActionsColors[] = []

		if (message) {

			message.outputsIdsByIterations.forEach((iteration) => {
				const widgetById = currentConversation.outputs![iteration.outputId]
				const widgetType = widgetById.type
				const activePageIndex = iteration.activePageIndex || 0

				switch (widgetType) {
					case DisplayWidgetType.Colors:
						return widgetById.data.pages[activePageIndex]
							.results.forEach((color, index) => {
								widgetItems.push({
									type: widgetById.type,
									idRef: {
										type: IdRefType.WidgetWithPaginationItem,
										messageId: message.id,
										widgetId: iteration.outputId,
										widgetPageIndex: activePageIndex,
										widgetItemIndex: index,
										widgetItemId: color.id,
									},
									widgetData: color,
								})
							})
					case DisplayWidgetType.AccessibilityColors:
						return widgetById.data
							.results.forEach((color, index) => {
								widgetItems.push({
									//Set this type manually because to use the same type as the color relateds.
									type: DisplayWidgetType.Colors,
									idRef: {
										type: IdRefType.WidgetWithPaginationItem,
										messageId: message.id,
										widgetId: iteration.outputId,
										widgetPageIndex: 0,
										widgetItemIndex: index,
										widgetItemId: color.id,
									},
									widgetData: {
										id: color.id,
										palette: color.palette,
									},
								})
							})
					case DisplayWidgetType.ColorsExplanations:
						return widgetById.data
							.results.forEach((color, index) => {
								widgetItems.push({
									//Set this type manually because to use the same type as the color relateds.
									type: DisplayWidgetType.Colors,
									idRef: {
										type: IdRefType.WidgetWithPaginationItem,
										messageId: message.id,
										widgetId: iteration.outputId,
										widgetPageIndex: 0,
										widgetItemIndex: index,
										widgetItemId: color.id,
									},
									widgetData: {
										id: color.id,
										palette: color.palette,
									},
								})
							})
					case DisplayWidgetType.Fonts:
						return widgetById.data.pages[activePageIndex]
							.results.forEach((font, index) => {
								widgetItems.push({
									type: widgetById.type,
									idRef: {
										type: IdRefType.WidgetWithPaginationItem,
										messageId: message.id,
										widgetId: iteration.outputId,
										widgetPageIndex: activePageIndex,
										widgetItemIndex: index,
										widgetItemId: font.id,
									},
									metadata: widgetById.metadata,
									widgetData: font,
								})
							})
					case DisplayWidgetType.FontsExplanations:
						return widgetById.data
							.results.forEach((font, index) => {
								widgetItems.push({
									//Set this type manually because to use the same type as the font relateds.
									type: DisplayWidgetType.Fonts,
									idRef: {
										type: IdRefType.WidgetWithPaginationItem,
										messageId: message.id,
										widgetId: iteration.outputId,
										widgetPageIndex: 0,
										widgetItemIndex: index,
										widgetItemId: font.id,
									},
									metadata: widgetById.metadata,
									widgetData: font,
								})
							})
					case DisplayWidgetType.Images:
						return widgetById.data.pages[activePageIndex]
							.results.forEach((image, index) => {
								widgetItems.push({
									type: widgetById.type,
									idRef: {
										type: IdRefType.WidgetWithPaginationItem,
										messageId: message.id,
										widgetId: iteration.outputId,
										widgetPageIndex: activePageIndex,
										widgetItemIndex: index,
										widgetItemId: image.id,
									},
									widgetData: image,
								})
							})
					case DisplayWidgetType.MockupColors:
					case DisplayWidgetType.MockupFonts:
					case DisplayWidgetType.Text:
					case DisplayWidgetType.Searches:
					case DisplayWidgetType.Brands:
					case DisplayWidgetType.Question:
						throw new Error(`Item type ${widgetType} is not supported in Related Actions`)
					default: {
						const _exhaustiveCheck: never = widgetType
						const errorMessage = `Unknown widget type in Related Actions: ${_exhaustiveCheck}`
						Sentry.captureMessage(errorMessage)
						throw new Error(errorMessage)
					}
				}
			})
		}
		return widgetItems
	},
})

export const selectorsRelatedActions = {
	widgetItemsForRelatedActions,
}
