import { RelatedActions } from "deblank-api-types"
import { produce } from "immer"
import { useRecoilCallback } from "recoil"
import { atomConversationsRecord } from "../Atom"


const useAddRelatedActionsToMessage = () => {
	return useRecoilCallback(({ set, }) => (params: {
		messageId: string,
		relatedActions: RelatedActions[],
	}) => {
		set(atomConversationsRecord, previous => produce(previous, draft => {
			const currentConversation = draft.conversations[draft.activeConversationId!]
			currentConversation.messages[params.messageId].relatedActions = params.relatedActions
		}))
	})
}

export const settersRelatedActions = {
	useAddRelatedActionsToMessage,
}
