import React, { DependencyList, useEffect, useRef } from "react"
import Styles from "./TextArea.module.scss"
import classNames from "classnames"

type CustomStyles = {
	withoutHorizontalPadding?: boolean,
}

type Props = {
	onChange: (value: string) => void,
	onEnterKeyDown: () => void,
	value: string,
	disabled?: boolean,
	customStyles?: CustomStyles,
	placeholder: string,
	onMouseEnter?: () => void,
	onMouseLeave?: () => void,
	onFocus?: () => void,
	truncateToOneLine?: boolean,
	maxLength?: number,
	triggerFocusDeps: boolean[],
}

const TextArea = (
	props: Props,
) => {
	const textAreaRef = useRef<HTMLTextAreaElement>(null)

	const calculateHeight = () => {
		if (textAreaRef.current) {
			textAreaRef.current.style.height = "auto"
			const scrollHeight = textAreaRef.current.scrollHeight
			textAreaRef.current.style.height = `${scrollHeight}px`
		}
	}

	useEffect(() => {
		calculateHeight()
	}, [props.value, props.truncateToOneLine,])

	useEffect(() => {
		if (textAreaRef.current) {
			if (props.triggerFocusDeps.some(dep => dep)) {
				textAreaRef.current.focus()
			}
		}
	}, [props.triggerFocusDeps,])

	const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
		props.onChange(e.target.value)
		calculateHeight()
	}

	const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
		if (event.key === "Enter" && !event.shiftKey) {
			event.preventDefault()
			const sanitizedPrompt = props.value.replace(/(\r\n|\n|\r)/g, "").trim()
			const isDisabled = sanitizedPrompt.length === 0
			if (isDisabled) {
				return
			}
			props.onEnterKeyDown()
			textAreaRef.current?.blur()

		}
	}

	const handleMouseEnter = () => {
		if (props.onMouseEnter) {
			props.onMouseEnter()
		}
	}

	const handleOnFocus = () => {
		calculateHeight()
		if (props.onFocus) {
			props.onFocus()
		}
	}

	const textareaHelperClasses = classNames({
		[Styles.prompt_input]: true,
		[Styles.without_horizontal_padding]: props.customStyles?.withoutHorizontalPadding,
		[Styles.truncate_one_line]: props.truncateToOneLine,
	})

	return (
		<textarea ref={textAreaRef}
			className={textareaHelperClasses}
			placeholder={props.placeholder}
			value={props.value}
			onChange={handleChange}
			onKeyDown={handleKeyDown}
			onMouseEnter={handleMouseEnter}
			onMouseLeave={props.onMouseLeave}
			onFocus={handleOnFocus}
			disabled={props.disabled}
			rows={1}
			maxLength={props.maxLength}
		/>
	)
}

export default TextArea
