import React from "react"
import Styles from "./WidgetToolbarThumbImages.module.scss"
import { CommonWidgetImageItemProps } from "../WidgetImagesTypes"
import ButtonIcon from "@/components/Buttons/ButtonIcon/ButtonIcon"
import DeleteIcon from "deblank-common/src/assets/images/icon-library/Delete.svg"
import { settersUiTemporary } from "@/recoil/Ui/Temporary/Setters"
import ImageByWidgetType from "@/components/ImageByWidgetType/ImageByWidgetType"


const WidgetToolbarThumbImages = (props: CommonWidgetImageItemProps) => {

	const setOutputIdSelected = settersUiTemporary.useSetOutputIdSelected()

	const handleClick = () => {
		setOutputIdSelected({
			outputIdSelected: null,
		})
	}

	return (
		<figure className={Styles.wrapper}>
			<ImageByWidgetType widgetType={props.widgetType}
				widgetDetails={props.widgetItemDetails}
			/>
			<div className={Styles.overlay}>
				<ButtonIcon type="button"
					iconSVGComponent={DeleteIcon}
					onClick={handleClick}
					customStyles={{
						variant: "secondary",
					}}
				/>
			</div>
		</figure>
	)
}

export default WidgetToolbarThumbImages
