import { HTMLMotionProps, Variants } from "framer-motion"

const variantsPromptToolbar: Variants = {
	hidden: {
		opacity: 0,
		y: 100,
	},
	visible: {
		opacity: 1,
		y: 0,
	},
}

const transitionPromptToolbar: HTMLMotionProps<"section">["transition"] = {
	duration: 0.2,
	ease: "easeOut",
}

export const promptToolbarProps = {
	variants: variantsPromptToolbar,
	initial: "hidden",
	animate: "visible",
	exit: "hidden",
	transition: transitionPromptToolbar,
}
