import { HTMLMotionProps, Variants } from "framer-motion"


const variantsFooter: Variants = {
	hidden: {
		y: "100%",
		opacity: 0,
	},
	visible: {
		y: 0,
		opacity: 1,
	},
}

const transition: HTMLMotionProps<"section">["transition"] = {
	ease: [0.4, 0.14, 0.3, 1,],
	duration: 0.2,
}


export const footerMotionProps = {
	variants: variantsFooter,
	initial: "hidden",
	animate: "visible",
	exit: "hidden",
	transition: transition,
	key: "instagram-footer",
}
