/* eslint-disable max-len */
/* eslint-disable max-lines-per-function */
/* eslint-disable max-statements */
import { Application } from "pixi.js"
import { MockupID, ImageData, GetMockupImageProps, MockupParams, } from "./types/MockupTypes"
import PQueue from "p-queue"
import { FontsTemplateMockupParams } from "./types/FontsMockupTypes"
import { Fonts_Mockup_Preload } from "./mockups/fontsMockups/Fonts_Mockup_Preload/Fonts_Mockup_Preload"

class MockupBuilder {
	app: Application
	semaphore = new PQueue({ concurrency: 1, })

	constructor() {
		this.app = new Application({
			width: 1000,
			height: 1000,
			resolution: window.devicePixelRatio,
			preserveDrawingBuffer: true,
			antialias: false,
			autoDensity: true,
			backgroundAlpha: 0,
		})
	}

	destroy() {
		this.app.destroy()
	}

	async preloadFonts(params: {
		mockupParams: FontsTemplateMockupParams,
	}) {
		await Fonts_Mockup_Preload.preloadFonts({
			params: params.mockupParams,
			app: this.app,
			semaphore: this.semaphore,
		})
	}

	getMockupImages<P extends MockupParams>(params: {
		setLoading: (value: boolean) => void,
		setError: (value: boolean) => void,
		mockupParams: P,
		mockups: {
			getImage: (props: GetMockupImageProps<P>) => Promise<ImageData>,
			mockupId: MockupID,
			mockupDescription: string,
		}[],
	}) {
		let cancel = false

		const getImage = async (mockup: {
			getImage: (props: GetMockupImageProps<P>) => Promise<ImageData>,
			mockupId: MockupID,
			mockupDescription: string,
		}) => {
			try {
				// eslint-disable-next-line no-negated-condition
				if (!cancel) {
					const imageData = await mockup.getImage({
						params: params.mockupParams,
						app: this.app,
						semaphore: this.semaphore,
					})
					return {
						imageData,
						mockupId: mockup.mockupId,
						mockupDescription: mockup.mockupDescription,
					}
				}
			} catch (err) {
				console.error(err)
				params.setError(true)
				params.setLoading(false)
			}
		}

		const execute = async () => {
			cancel = false

			params.setLoading(true)

			try {
				const images = await Promise.all(params.mockups.map(getImage))
				return images
			} catch (e) {
				console.error(e)
				params.setError(true)
				params.setLoading(false)
			}
		}

		const cancelExecution = () => {
			cancel = true
		}

		return {
			done: execute(),
			cancel: cancelExecution,
		}

	}
}

export default MockupBuilder
