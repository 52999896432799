import { EmptyData, GetDataResponse, emptyData } from "./useGetDataTypes"

export const getResponse = <T>(params: {
	error: boolean,
	loading: boolean,
	response: T | EmptyData,
	mutate: GetDataResponse<unknown>["mutate"],

}) => {
	const {
		response,
		error,
		mutate,
		loading,
	} = params
	if (error) {
		return {
			loading: false,
			error: true,
			data: null,
			mutate,
		} as const
	} else if (loading || response === emptyData) {
		return {
			loading: true,
			error: false,
			data: null,
			mutate,
		} as const
	} else {
		return {
			loading: false,
			error: false,
			data: response,
			mutate,
		} as const
	}
}
