import { useState } from "react"

export const useRequestState = () => {
	const [
		{
			loading,
			error,
		},
		setState,
	] = useState<{
		loading: boolean,
		error: boolean,
	}>({
		loading: false,
		error: false,
	})

	const handleStart = () => {
		setState({
			loading: true,
			error: false,
		})
	}

	const handleSuccess = () => {
		setState({
			loading: false,
			error: false,
		})
	}

	const handleClear = () => {
		setState({
			loading: false,
			error: false,
		})
	}

	const handleError = () => {
		setState({
			loading: false,
			error: true,
		})
	}


	return {
		loading,
		error,
		handleStart,
		handleSuccess,
		handleError,
		handleClear,
	}
}
