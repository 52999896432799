import { DateTime, } from "luxon"

enum ImageStatus {
	PENDING = "pending",
	SUCCESS = "success",
	ERROR = "error",
}
type ImageMetadata = {
	signedUrl: string,
	expiryDate: string,
	imageWidth: number | null,
	imageHeight: number | null,
}

type PendingImageData = {
	status: ImageStatus.PENDING,
	promise: Promise<ImageMetadata>,
}

type SuccessImageData = {
	status: ImageStatus.SUCCESS,
} & ImageMetadata

type ErrorImageData = {
	status: ImageStatus.ERROR,
}

type CachedImageData = PendingImageData | SuccessImageData | ErrorImageData

type ImageCache = Record<string, Record<string, CachedImageData>>

export type FetchParams = {
	imageFileName: string,
	conversationId: string,
}

type FetchImageUrlFunction = (
	params: FetchParams
) => Promise<ImageMetadata>;

class ImageManager {
	private static instance: ImageManager
	private cachedImages: ImageCache = {}
	private fetchImageUrlFunction: FetchImageUrlFunction | null = null

	private constructor() { }

	public static getInstance(): ImageManager {
		if (!ImageManager.instance) {
			ImageManager.instance = new ImageManager()
		}
		return ImageManager.instance
	}

	public setFetchImageUrlFunction(fetchFunction: FetchImageUrlFunction): void {
		this.fetchImageUrlFunction = fetchFunction
	}

	private static isUrlExpired(expiryDate: string): boolean {
		const now = DateTime.utc()
		const expiryTime = DateTime.fromISO(expiryDate, { zone: "utc", })
		return now > expiryTime
	}

	public getImageUrl = (params: FetchParams): Promise<ImageMetadata> => {

		if (!this.cachedImages[params.conversationId]) {
			this.cachedImages[params.conversationId] = {}
		}

		const cachedImage = this.cachedImages[params.conversationId][params.imageFileName]

		if (cachedImage) {
			if (
				cachedImage.status === ImageStatus.SUCCESS
				&& !ImageManager.isUrlExpired(cachedImage.expiryDate)
			) {
				return Promise.resolve(cachedImage)
			} else if (
				cachedImage.status === ImageStatus.PENDING
				&& cachedImage.promise
			) {
				return cachedImage.promise
			} else {
				return this.fetchAndCacheImageUrl(params).promise
			}
		} else {
			return this.fetchAndCacheImageUrl(params).promise
		}
	}

	private fetchAndCacheImageUrl = (params: FetchParams): PendingImageData => {
		if (!this.fetchImageUrlFunction) {
			throw new Error("Fetch function not set")
		}

		if (!this.cachedImages[params.conversationId]) {
			this.cachedImages[params.conversationId] = {}
		}

		const fetchPromise = (async () => {
			try {
				const { signedUrl, expiryDate, imageWidth, imageHeight, } = await this.fetchImageUrlFunction!(params)
				const imageDetails: ImageMetadata = {
					signedUrl: signedUrl,
					imageWidth: imageWidth,
					imageHeight: imageHeight,
					expiryDate: expiryDate,
				}
				this.cachedImages[params.conversationId][params.imageFileName] = {
					status: ImageStatus.SUCCESS,
					...imageDetails,
				}
				return (imageDetails)
			} catch (error) {
				this.cachedImages[params.conversationId][params.imageFileName] = {
					status: ImageStatus.ERROR,
				}
				throw error
			}
		})()

		const pendingData: PendingImageData = {
			status: ImageStatus.PENDING,
			promise: fetchPromise,
		}
		this.cachedImages[params.conversationId][params.imageFileName] = pendingData
		return pendingData
	}
}

export default ImageManager.getInstance()
