
export enum TextAlign {
	left = "left",
	center = "center",
	right = "right",
	justify = "justify",
}


export type TextState = {
	fontSize: {
		value: number,
		max: number,
		min: number,
	},
	fontWeight: number,
	lineHeight: {
		value: number,
		max: number,
		min: number,
	},
	letterSpacing: {
		value: number,
		max: number,
		min: number,
	},
	textAlign: TextAlign,
}


//Choose fallback font weight if the selected one is not available
const getFontWeight = (fontWeights: number[]) => {
	if (fontWeights.includes(500)) {
		return 500
	} else {
		return fontWeights[Math.ceil(fontWeights.length / 2) - 1]
	}
}

export const getTitleDefaulState = (fontWeights: number[]) => {
	return {

		fontSize: {
			value: 70,
			max: 200,
			min: 0,
		},
		fontWeight: getFontWeight(fontWeights),
		lineHeight: {
			value: 100,
			max: 130,
			min: 0,
		},
		letterSpacing: {
			value: 0,
			max: 20,
			min: -20,
		},
		textAlign: TextAlign.left,
	}
}

export const getSubtitleDefaulState = (fontWeights: number[]) => {
	return {
		fontSize: {
			value: 35,
			max: 100,
			min: 0,
		},
		fontWeight: getFontWeight(fontWeights),
		lineHeight: {
			value: 130,
			max: 140,
			min: 40,
		},
		letterSpacing: {
			value: 0,
			max: 20,
			min: -20,
		},
		textAlign: TextAlign.left,
	}
}

export const getBodyDefaulState = (fontWeights: number[]) => {
	return {
		fontSize: {
			value: 16,
			max: 40,
			min: 0,
		},
		fontWeight: getFontWeight(fontWeights),
		lineHeight: {
			value: 180,
			max: 200,
			min: 40,
		},
		letterSpacing: {
			value: 0,
			max: 20,
			min: -20,
		},
		textAlign: TextAlign.left,
	}
}
