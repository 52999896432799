/* eslint-disable max-statements */
/* eslint-disable max-len */
/* eslint-disable max-lines-per-function */
import React from "react"
import * as PIXI from "pixi.js"
import { ColorPackagingMockupID } from "deblank-api-types"
import { MockupUtils } from "@/utils/mockupBuilder/utils/MockupUtils"
import { GetMockupImageProps, Mockup, OpenAiCosts } from "@/utils/mockupBuilder/types/MockupTypes"
import { ColorsTemplateMockupParams } from "@/utils/mockupBuilder/types/ColorsMockupTypes"
import { Mockup_SVG } from "./Mockup_SVG"
import { ColorUtils } from "@/utils/mockupBuilder/utils/ColorUtils"
import CanLightBackground from "./images/can_light_background.png"
import CanMask from "./images/can_mask.png"
import CanTop from "./images/can_top.png"
import CanShadow from "./images/can_shadow.png"
import Placeholder from "./images/placeholder.svg"

// ------- DESCRIPTION ------- //
//- Figma link: https://www.figma.com/file/DYWqSJfZnrSqdOmweKf6Qk/FontMuse-(new)?type=design&node-id=822-1351&mode=design&t=QDo8iGvib0i6nIP2-0

const mockupWidth = 1417
const mockupHeight = 1744

const mockupId = ColorPackagingMockupID.colors_packaging_mockup_4

type ColorAssignmentType = {
	background: { background: string, circle: string, },
	design: { background: string, text: string, },
}

const getColors = async (params: {
	mockupParams: ColorsTemplateMockupParams,
	minColors: number,
}): Promise<{ colorAssignment: ColorAssignmentType, openAiCosts: OpenAiCosts, }> => {
	const { getColorPalette, getBackgroundColor, getTextColor, } = ColorUtils
	const { getGroupedColors, type, ...colorPalette } = params.mockupParams
	const { usage, ...colorP } = colorPalette
	const colors = Object.values(colorP).filter(c => c)

	const backgroundColor = getBackgroundColor({
		colorPalette: colors,
		backgroundLuminance: 0.5,
	})

	const designColorPalette = getColorPalette({
		colors: colors,
		backgroundColor: backgroundColor,
		minColors: params.minColors - colors.length,
	})

	const colorPaletteWithoutContrastColor = designColorPalette.filter((color) => color !== backgroundColor)

	const palette = await getGroupedColors({
		palette: colorPaletteWithoutContrastColor,
		imageSvg: Mockup_SVG.simpleDesignSvg,
		backgroundColor: backgroundColor,
		numberOfGroups: params.minColors,
	},)


	const maybeCanTextColor = getTextColor({
		colorPalette: colorPaletteWithoutContrastColor,
		backgroundColor: palette.groupB!,
	})

	const canTextColor = maybeCanTextColor === "#000000" || maybeCanTextColor === "#FFFFFF" ? palette.groupA! : maybeCanTextColor

	return {
		colorAssignment: {
			background: { background: backgroundColor, circle: palette.groupA!, },
			design: { background: palette.groupB!, text: canTextColor, },
		},
		openAiCosts: {
			completion_cost: palette.usage!.completion_cost,
			prompt_cost: palette.usage!.prompt_cost,
		},
	}

}

const getMockupImage = async (props: GetMockupImageProps<ColorsTemplateMockupParams>) => {

	const { app, semaphore, } = props
	const ret = await semaphore.add(async () => {
		const { colorAssignment, openAiCosts, } = await getColors({
			mockupParams: props.params,
			minColors: 3,
		})
		const [shadowSprite, canMaskSprite,
			canTopSprite, canLightBackgroundSprite,] = await MockupUtils.loadAssets([
				CanShadow, CanMask, CanTop, CanLightBackground,])

		// Create mockup container
		const mockupContainer = new PIXI.Container()
		app.stage.addChild(mockupContainer)

		mockupContainer.width = mockupWidth
		mockupContainer.height = mockupHeight

		const maskGraphics = MockupUtils.drawRect({
			width: mockupWidth,
			height: mockupHeight,
			color: "#FFFFFF",
		})

		// Use the rounded rectangle as a mask for the container
		mockupContainer.addChild(maskGraphics)
		mockupContainer.mask = maskGraphics

		//Add Background
		const backgroundContainer = new PIXI.Container()
		const backgroundTexture = await PIXI.Texture.fromURL(Mockup_SVG.backgroundSVG(colorAssignment.background))
		const backgroundSprite = new PIXI.Sprite(backgroundTexture)
		backgroundContainer.addChild(backgroundSprite)

		mockupContainer.addChild(backgroundContainer)

		//Add Design
		const designShape = MockupUtils.drawRect({
			width: mockupWidth,
			height: mockupHeight,
			color: colorAssignment.design.background,
		})

		const designContainer = new PIXI.Container()
		const designTexture = await PIXI.Texture.fromURL(Mockup_SVG.designSVG(colorAssignment.design))
		const designSprite = new PIXI.Sprite(designTexture)
		designContainer.addChild(designSprite)

		const frontBagP = MockupUtils.project({
			app: app,
			container: designContainer,
			projectionPoints: {
				topLeft: { x: 604.9, y: 384.56, },
				topRight: { x: 1042.55, y: 627.1, },
				bottomRight: { x: 752.7, y: 1624.73, },
				bottomLeft: { x: 314.57, y: 1381.93, },
			},
		})
		const frontBag = new PIXI.Container()
		frontBag.addChild(designShape)
		frontBag.addChild(frontBagP)
		frontBag.addChild(canMaskSprite)
		mockupContainer.addChild(canLightBackgroundSprite)
		frontBag.mask = canMaskSprite

		mockupContainer.addChild(frontBag)

		// Add Face
		canTopSprite.blendMode = PIXI.BLEND_MODES.MULTIPLY
		mockupContainer.addChild(canTopSprite)

		//Add shadow
		shadowSprite.blendMode = PIXI.BLEND_MODES.SCREEN
		mockupContainer.addChild(shadowSprite)


		const imageData = MockupUtils.exportImage({
			app: app,
			container: mockupContainer,
			width: mockupWidth,
			height: mockupHeight,
			imageCosts: openAiCosts,
		})

		return imageData
	})
	return ret!


}

export const Color_Mockup_Packaging_4: Mockup<ColorsTemplateMockupParams> = {
	placeholder: <Placeholder className="disabled_custom_color" />,
	getMockupImage,
	mockupId,
	description: `
	The mockup displays a soda can tilted at an angle. The can features a sleek, minimalist design with the brand name "Bies." prominently printed vertically. The word "soda" appears in smaller text next to the brand name. The can has a smooth finish and is placed against a circular background that complements the overall presentation. The top of the can features a standard pull-tab opening.`,
}
