import React from "react"
import Styles from "./TermsMessage.module.scss"

const TermsMessage = () => {
	return (
		<section className={Styles.terms_container}>
			By sending a message to Deblank, you agree to our&nbsp;
			<a href="https://deblank.com/legal/user-terms" target="_blank" rel="noreferrer">Terms</a> and acknowledge
			that you have read our&nbsp;
			<a href="https://www.envato.com/privacy/" target="_blank" rel="noreferrer">Privacy Policy</a>.
		</section>
	)
}

export default TermsMessage
