import React from "react"
import { EntryTextResult, IdRefWidget } from "deblank-api-types"
import ChatBubbleAssistant from "@/components/Chat/BubbleAssistant/BubbleAssistant"


type Props = {
	widgetDetails: EntryTextResult,
	idRef: IdRefWidget,
}

const WidgetText = (props: Props) => {
	return (
		props.widgetDetails.data.results.map((widget) => (
			<ChatBubbleAssistant key={widget.id}
				idRef={props.idRef}
				title={widget.title}
				message={widget.message}
			/>
		))
	)
}

export default WidgetText
