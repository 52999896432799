/* eslint-disable max-lines */
/* eslint-disable max-statements */
/* eslint-disable max-len */
/* eslint-disable max-lines-per-function */
import * as PIXI from "pixi.js"
import { FontsUIMockupID } from "deblank-api-types"
import { MockupUtils } from "@/utils/mockupBuilder/utils/MockupUtils"
import { GetMockupImageProps, Mockup } from "@/utils/mockupBuilder/types/MockupTypes"
import { FontsTemplateMockupParams } from "@/utils/mockupBuilder/types/FontsMockupTypes"
import Background from "./images/background.png"
import Shadow from "./images/shadow.png"
import LeftDesign from "./images/left_design.png"
import RightDesign from "./images/right_design.png"
import LeftMask from "./images/left_mask.png"
import RightMask from "./images/right_mask.png"
import Placeholder from "./images/placeholder.svg"
import React from "react"


const mockupWidth = 1490.31
const mockupHeight = 1016.06

// ------- DESCRIPTION ------- //
//- Figma link: https://www.figma.com/design/y5UsL35vcLmufMsTJ93EOe/Assistant-Mockups?node-id=221-445&t=EDGfHMVUUeE3jw5g-0

const mockupId = FontsUIMockupID.fonts_ui_mockup_2


const getMockupImage = async (props: GetMockupImageProps<FontsTemplateMockupParams>) => {
	const { params, app, } = props

	const ret = await props.semaphore.add(async () => {
		const [
			backgroundSprite, shadowSprite, leftDesignSprite,
			rightDesignSprite, leftMaskSprite, rightMaskSprite,
		] = await MockupUtils.loadAssets([
			Background, Shadow, LeftDesign, RightDesign,
			LeftMask, RightMask,
		])

		// Create mockup container
		const mockupContainer = new PIXI.Container()
		app.stage.addChild(mockupContainer)

		mockupContainer.width = mockupWidth
		mockupContainer.height = mockupHeight

		//Add background
		mockupContainer.addChild(backgroundSprite)

		const screenWidth = 260.31
		const screenHeight = 564.33

		//Add left screen
		const leftDesignContainer = new PIXI.Container()
		leftDesignContainer.width = screenWidth
		leftDesignContainer.height = screenHeight

		const leftDesignText = await MockupUtils.createText({
			text: "All your workouts in one app",
			fontFamily: params.title.font,
			fontWeights: params.title.weights,
			fontWeight: 500,
			color: "#FFFFFF",
			fontSize: 18,
			align: "center",
			wrapText: true,
			wordWrapWidth: 190,
			trim: true,
		})
		leftDesignText.position.set((screenWidth - leftDesignText.width) / 2, 288)
		leftDesignContainer.addChild(leftDesignSprite)
		leftDesignContainer.addChild(leftDesignText)

		const leftScreenProjection = MockupUtils.project({
			app: app,
			container: leftDesignContainer,
			projectionPoints: {
				topLeft: { x: 390.13, y: 151.02, },
				topRight: { x: 719, y: 151.02, },
				bottomRight: { x: 719, y: 865, },
				bottomLeft: { x: 390.13, y: 865, },
			},
		})
		const leftMaskContaier = new PIXI.Container()
		leftMaskContaier.addChild(leftMaskSprite)
		leftMaskContaier.addChild(leftScreenProjection)
		leftMaskContaier.mask = leftMaskSprite


		mockupContainer.addChild(leftMaskContaier)

		//Add right screen
		const rightDesignContainer = new PIXI.Container()

		const rightDesignBrandText = await MockupUtils.createText({
			text: "Biesport",
			fontFamily: params.title.font,
			fontWeights: params.title.weights,
			fontWeight: 700,
			color: "#FFFFFF",
			fontSize: 16,
			trim: true,
		})
		rightDesignBrandText.position.set(50, 59.61 + 16 - (rightDesignBrandText.height / 2))

		const rightDesignCaloriesText = await MockupUtils.createText({
			text: "450",
			fontFamily: params.title.font,
			fontWeights: params.title.weights,
			fontWeight: 700,
			color: "#FFFFFF",
			fontSize: 22,
			trim: true,
		})
		rightDesignCaloriesText.position.set(26.16, 376.22)


		rightDesignContainer.addChild(rightDesignSprite)
		rightDesignContainer.addChild(rightDesignBrandText)
		rightDesignContainer.addChild(rightDesignCaloriesText)

		const rightScreenProjection = MockupUtils.project({
			app: app,
			container: rightDesignContainer,
			projectionPoints: {
				topLeft: { x: 771, y: 151.02, },
				topRight: { x: 1101, y: 151.02, },
				bottomRight: { x: 1101, y: 865, },
				bottomLeft: { x: 771, y: 865, },
			},
		})
		const rightMaskContainer = new PIXI.Container()
		rightMaskContainer.addChild(rightMaskSprite)
		rightMaskContainer.addChild(rightScreenProjection)
		rightMaskContainer.mask = rightMaskSprite

		mockupContainer.addChild(rightMaskContainer)

		//Add shadow
		mockupContainer.addChild(shadowSprite)



		const imageData = MockupUtils.exportImage({
			app: app,
			container: mockupContainer,
			width: mockupWidth,
			height: mockupHeight,
			imageCosts: null,
		})

		return imageData
	})

	return ret!




}

export const Fonts_Mockup_UI_2: Mockup<FontsTemplateMockupParams> = {
	placeholder: <Placeholder className="disabled_custom_color" />,
	getMockupImage,
	mockupId,
	description: `
	The mockup displays two smartphone app screens side by side, both with a sleek, dark theme and vibrant green accents.

- The screen on the left features a minimalist design with a dark gradient background fading into green at the bottom. A central, simple white star icon is positioned above a glowing green button at the bottom of the screen.

- The screen on the right presents a more detailed interface, designed for a fitness application. The top section includes light green tabs for different workout categories. Below that, there are multiple cards showing health and fitness data:
  - A card for "Start new training" with a plus icon on a white background.
  - A "Heart rate" card displaying a chart with green and yellow lines.
  - A bar chart displaying data about calories burned over the week.

The design uses a combination of black, white, and varying shades of green to create a visually appealing contrast. The bottom navigation bar features icons for home, favorites, and profile, each highlighted with a green accent. The overall design is modern, clean, and user-friendly.`,
}
