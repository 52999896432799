import React, { useEffect, useContext } from "react"
import { useConversationHandlers } from "@/hooks/useConversationHandlers"
import BubbleAssistantQuestion from "@/components/Chat/BubbleAssistantQuestion/BubbleAssistantQuestion"
import { IdRefType, introQuestions } from "deblank-api-types"
import { settersUiTemporary } from "@/recoil/Ui/Temporary/Setters"
import { TrackConversationEventContext } from "@/pages/AssistantPage/TrackConversationEventProvider"
import { useRecoilValue } from "recoil"
import { selectorsMessages } from "@/recoil/ConversationsRecord/Selectors/Messages"
import { SKIP_CONVERSATION_INTRO_NAME } from "@/recoil/ConversationsRecord/constants/introQuestions"

const InitialMessage = () => {

	const showInstagramBanner = settersUiTemporary.useShowInstagramBanner()
	const firstQuestion = introQuestions[0]
	const conversationHandlers = useConversationHandlers()
	const someMessageIsLoading = useRecoilValue(selectorsMessages.someMessageIsLoading)
	const { trackConversationEvent, } = useContext(TrackConversationEventContext)

	const onSkipIntroductionHandler = async () => {
		await conversationHandlers.onCreateNewConversation({
			userMessage: SKIP_CONVERSATION_INTRO_NAME,
			currentIntroQuestion: null,
			// eslint-disable-next-line max-len
			prompt: "The user skipped the initial questions. You must continue with the information collected or ask the user, 'Where would you like to begin?'",
		})

		trackConversationEvent({
			eventName: "SkipIntroductionQuestionsEvent",
			question: firstQuestion.question,
		})
	}

	useEffect(() => {
		showInstagramBanner({ showBanner: false, })
	}, [])

	return (
		<>
			<BubbleAssistantQuestion
				idRef={{
					type: IdRefType.Widget,
					messageId: "message-0",
					widgetId: "question-1",
				}}
				message={firstQuestion.question}
				example={firstQuestion.example}
				currentStep={0}
				totalSteps={introQuestions.length}
				isLastMessage={true}
				isLoading={someMessageIsLoading}
				onSkip={onSkipIntroductionHandler}
			/>
		</>
	)
}

export default InitialMessage
