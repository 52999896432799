import React from "react"
import Styles from "./ColorItem.module.scss"

type Props = {
	palette: string[],
}

const ColorItem = (props: Props) => {

	return (
		<div className={Styles.wrapper}>
			<article
				className={Styles.palette}>
				{props.palette.map((color, index) => (
					<div key={`${index}-${color}`}
						className={Styles.color_item}
						style={{ "--current_color": color, } as React.CSSProperties}
					>
					</div>
				))}
			</article>
		</div>
	)
}

export default ColorItem
