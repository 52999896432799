import { ConversationEventData } from "@/utils/mixpanel/events"
import { trackEvent } from "@/utils/mixpanel/mixpanel"
import React, { useEffect, useRef } from "react"
import { createContext } from "react"

export type TrackConversationEventContextType = {
	trackConversationEvent: (event: ConversationEventData) => Promise<void>,
}

export const TrackConversationEventContext = createContext<TrackConversationEventContextType>({
	trackConversationEvent: async () => { },
})

export const TrackConversationEventProvider = (props: { children: React.ReactNode, activeConversationId?: string, }) => {
	const activeConversationId = useRef<string | undefined>(props.activeConversationId)

	const handleTrackEvent = async (event: ConversationEventData) => {
		await trackEvent({
			...event,
			chatId: activeConversationId.current!,
		})
	}

	useEffect(() => {
		activeConversationId.current = props.activeConversationId
	}, [props,])

	return (
		<TrackConversationEventContext.Provider
			value={{
				trackConversationEvent: handleTrackEvent,
			}}
		>
			{props.children}
		</TrackConversationEventContext.Provider>
	)
}
