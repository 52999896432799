/* eslint-disable max-statements */
/* eslint-disable max-len */
/* eslint-disable max-lines-per-function */
import * as PIXI from "pixi.js"
import { ColorPosterMockupID } from "deblank-api-types"
import { MockupUtils } from "@/utils/mockupBuilder/utils/MockupUtils"
import { GetMockupImageProps, Mockup, OpenAiCosts } from "@/utils/mockupBuilder/types/MockupTypes"
import { ColorsTemplateMockupParams, } from "@/utils/mockupBuilder/types/ColorsMockupTypes"
import { Mockup_SVG } from "./Mockup_SVG"
import { ColorUtils } from "@/utils/mockupBuilder/utils/ColorUtils"
import BooksBackground from "./images/books_background.png"
import BooksCover from "./images/books_covers.png"
import BooksMask from "./images/books_mask.png"
import BooksShadows from "./images/books_shadows.png"
import Placeholder from "./images/placeholder.svg"
import React from "react"

// ------- DESCRIPTION ------- //
//- Figma link: https://www.figma.com/file/TUew0SeCWGow3Z5EUILdGt/Mockups?type=design&node-id=1796-2611&mode=design&t=Z9tPBHPyjbhUrEDs-0

const SCALE = 0.5

const mockupWidth = 3000 * SCALE
const mockupHeight = 2000 * SCALE

const mockupId = ColorPosterMockupID.colors_poster_mockup_1

type ColorAssignmentType = {
	backgroundShape: { backgroundShape: string, },
	booksDesign: { backgroundColor: string, colorB: string, colorC: string, colorD: string, colorE: string, },
}

const getColors = async (params: {
	mockupParams: ColorsTemplateMockupParams,
	minColors: number,
}): Promise<{ colorAssignment: ColorAssignmentType, openAiCosts: OpenAiCosts, }> => {
	const { getColorPalette, } = ColorUtils
	const { getGroupedColors, type, ...colorPalette } = params.mockupParams
	const { usage, ...colorP } = colorPalette

	const colors = Object.values(colorP).filter(c => c)

	const backgroundColor = "#FFFFFF"

	const designColorPalette = getColorPalette({
		colors: colors,
		backgroundColor: backgroundColor,
		minColors: params.minColors,
	})

	const colorPaletteWithoutContrastColor = designColorPalette.filter((color) => color !== backgroundColor)

	const paletteFromAi = await getGroupedColors({
		palette: colorPaletteWithoutContrastColor,
		imageSvg: Mockup_SVG.simpleDesignSvg,
		backgroundColor: backgroundColor,
		numberOfGroups: params.minColors,
	},
	)

	return {
		colorAssignment: {
			backgroundShape: { backgroundShape: paletteFromAi.groupA!, },
			booksDesign: { backgroundColor: paletteFromAi.groupA!, colorB: paletteFromAi.groupB!, colorC: paletteFromAi.groupC!, colorD: paletteFromAi.groupD!, colorE: paletteFromAi.groupE!, },
		},
		openAiCosts: {
			completion_cost: paletteFromAi.usage!.completion_cost,
			prompt_cost: paletteFromAi.usage!.prompt_cost,
		},
	}

}

const getMockupImage = async (
	props: GetMockupImageProps<ColorsTemplateMockupParams>
) => {
	const { app, semaphore, } = props
	const ret = await semaphore.add(async () => {

		const { colorAssignment, openAiCosts, } = await getColors({
			mockupParams: props.params,
			minColors: 5,
		})

		const [bookShadowsSprite, bookCoversSprite,
			bookMaskSprite, booksBackgroundSprite,] = await MockupUtils.loadAssets([
				BooksShadows, BooksCover, BooksMask, BooksBackground,])

		// Create mockup container
		const mockupContainer = new PIXI.Container()
		app.stage.addChild(mockupContainer)

		mockupContainer.width = mockupWidth
		mockupContainer.height = mockupHeight

		const maskGraphics = MockupUtils.drawRect({
			color: "#FFFFFF",
			width: mockupWidth,
			height: mockupHeight,
		})

		// Use the rounded rectangle as a mask for the container
		mockupContainer.addChild(maskGraphics)
		mockupContainer.mask = maskGraphics

		const backgroundShape = MockupUtils.drawRect({
			color: "#FFFFFF",
			width: mockupWidth,
			height: mockupHeight,
		})
		mockupContainer.addChild(backgroundShape)

		const backgroundContainer = new PIXI.Container()
		backgroundContainer.addChild(booksBackgroundSprite)
		backgroundContainer.mask = booksBackgroundSprite

		const backgroundBooksShape = MockupUtils.drawRect({
			color: colorAssignment.backgroundShape.backgroundShape,
			width: mockupWidth,
			height: mockupHeight,
		})
		backgroundContainer.addChild(backgroundBooksShape)

		mockupContainer.addChild(backgroundContainer)

		//Add sprites

		bookShadowsSprite.blendMode = PIXI.BLEND_MODES.MULTIPLY
		bookCoversSprite.blendMode = PIXI.BLEND_MODES.MULTIPLY

		const booksDesignTexture = await PIXI.Texture.fromURL(Mockup_SVG.design(colorAssignment.booksDesign))
		const booksDesignSprite = new PIXI.Sprite(booksDesignTexture)

		const booksContainer = new PIXI.Container()
		booksContainer.addChild(booksDesignSprite)

		const booksProjection = MockupUtils.project({
			app: app,
			container: booksContainer,
			projectionPoints: {
				topLeft: { x: 0 * SCALE, y: 0 * SCALE, },
				topRight: { x: 3000 * SCALE, y: 0 * SCALE, },
				bottomRight: { x: 3000 * SCALE, y: 2000 * SCALE, },
				bottomLeft: { x: 0 * SCALE, y: 2000 * SCALE, },
			},
		})

		const maskContainer = new PIXI.Container()
		maskContainer.addChild(bookMaskSprite)
		maskContainer.addChild(booksProjection)
		maskContainer.mask = bookMaskSprite

		mockupContainer.addChild(maskContainer)
		mockupContainer.addChild(bookCoversSprite)
		mockupContainer.addChild(bookShadowsSprite)

		const imageData = MockupUtils.exportImage({
			app: app,
			container: mockupContainer,
			height: mockupHeight,
			width: mockupWidth,
			imageCosts: openAiCosts,
		})

		return imageData

	})
	return ret!
}

export const Color_Mockup_Posters_1: Mockup<ColorsTemplateMockupParams> = {
	placeholder: <Placeholder className="disabled_custom_color" />,
	getMockupImage,
	mockupId,
	description: `The mockup features two side-by-side posters with distinct but complementary designs.

- The left poster includes large initials "DBK" at the top, accompanied by the repeated text "DEBLANK" and the phrase "INSPO FOR CREATORS." Towards the bottom, there are geometric shapes in a triangular arrangement. Additionally, a circular element on the right side of the poster contains the word "DEBLANK" written vertically.

- The right poster mirrors the left one but with differences in color scheme and geometric shapes. It also has the large initials "DBK," the same repeated text "DEBLANK," and the phrase "INSPO FOR CREATORS." Geometric shapes at the bottom are similar in style but are slightly varied in their arrangement.

Both posters are set against a two-tone background that enhances their visual appeal.`,
}
