/* eslint-disable max-statements */
/* eslint-disable max-len */
/* eslint-disable max-lines-per-function */
import * as PIXI from "pixi.js"
import { FontsPosterMockupID } from "deblank-api-types"
import { MockupUtils } from "@/utils/mockupBuilder/utils/MockupUtils"
import { GetMockupImageProps, Mockup } from "@/utils/mockupBuilder/types/MockupTypes"
import { FontsTemplateMockupParams } from "@/utils/mockupBuilder/types/FontsMockupTypes"
import FrameGlassLight from "./images/frame_glass_light.png"
import FrameLight from "./images/frame_light.png"
import FrameShadow from "./images/frame_shadow.png"
import Frame from "./images/frame.png"
import Design from "./images/design.png"
import Placeholder from "./images/placeholder.svg"
import React from "react"

// ------- DESCRIPTION ------- //
//- Figma link: https://www.figma.com/file/TUew0SeCWGow3Z5EUILdGt/Mockups?type=design&node-id=1796-2611&mode=design&t=Z9tPBHPyjbhUrEDs-0

const mockupWidth = 2649.44
const mockupHeight = 1987.08

const mockupId = FontsPosterMockupID.fonts_poster_mockup_3


const getMockupImage = async (
	props: GetMockupImageProps<FontsTemplateMockupParams>
) => {
	const { app, semaphore, params, } = props
	const ret = await semaphore.add(async () => {


		const [frameSprite, frameGlassLightSprite,
			frameShadowSprite, frameLightSprite,
			designSprite,] = await MockupUtils.loadAssets([
				Frame, FrameGlassLight, FrameShadow,
				FrameLight, Design,])

		// Create mockup container
		const mockupContainer = new PIXI.Container()
		app.stage.addChild(mockupContainer)

		mockupContainer.width = mockupWidth
		mockupContainer.height = mockupHeight

		const maskGraphics = MockupUtils.drawRect({
			color: "#FFFFFF",
			width: mockupWidth,
			height: mockupHeight,
		})

		// Use the rounded rectangle as a mask for the container
		mockupContainer.addChild(maskGraphics)
		mockupContainer.mask = maskGraphics

		const backgroundShape = MockupUtils.drawRect({
			color: "#FFFFFF",
			width: mockupWidth,
			height: mockupHeight,
		})

		mockupContainer.addChild(backgroundShape)

		//Add sprites
		frameSprite.blendMode = PIXI.BLEND_MODES.SCREEN
		frameGlassLightSprite.blendMode = PIXI.BLEND_MODES.MULTIPLY
		frameShadowSprite.blendMode = PIXI.BLEND_MODES.MULTIPLY
		frameLightSprite.blendMode = PIXI.BLEND_MODES.MULTIPLY

		const frameContainer = new PIXI.Container()
		const frameWidth = 731.91
		const frameHeight = 1051.83
		frameContainer.width = frameWidth
		frameContainer.height = frameHeight

		frameContainer.addChild(designSprite)

		const text1 = await MockupUtils.createText({
			text: "DBK",
			fontFamily: params.title.font,
			fontWeights: params.title.weights,
			fontWeight: 400,
			color: "#00FFC2",
			fontSize: 200,
			padding: 100,
			trim: true,
		})

		text1.position.set(40, 20)
		frameContainer.addChild(text1)

		const text2 = await MockupUtils.createText({
			text: "DEBLANK",
			fontFamily: params.title.font,
			fontWeights: params.title.weights,
			fontWeight: 300,
			color: "#00FFC2",
			padding: 100,
			fontSize: 52,
			trim: true,
		})
		text2.position.set(40, frameHeight - 385 - text2.height)
		frameContainer.addChild(text2)


		const text3 = await MockupUtils.createText({
			text: "DEBLANK",
			fontFamily: params.title.font,
			fontWeights: params.title.weights,
			fontWeight: 400,
			color: "#004039",
			padding: 100,
			fontSize: 52,
			trim: true,
		})
		text3.angle = -90
		text3.position.set(frameWidth - text3.height - 20, 412 + (text3.width / 2))
		frameContainer.addChild(text3)
		frameContainer.position.set(855.24, 329)

		const frameMaskContainer = new PIXI.Container()
		frameMaskContainer.addChild(frameContainer)

		const frameProjection = MockupUtils.project({
			app: app,
			container: frameMaskContainer,
			projectionPoints: {
				topLeft: { x: 855, y: 329, },
				topRight: { x: 1792, y: 329, },
				bottomRight: { x: 1793, y: 1668, },
				bottomLeft: { x: 855, y: 1668, },
			},
		})

		mockupContainer.addChild(frameProjection)
		mockupContainer.addChild(frameShadowSprite)
		mockupContainer.addChild(frameSprite)
		mockupContainer.addChild(frameLightSprite)
		mockupContainer.addChild(frameGlassLightSprite)


		const imageData = MockupUtils.exportImage({
			app: app,
			container: mockupContainer,
			height: mockupHeight,
			width: mockupWidth,
			imageCosts: null,
		})

		return imageData
	})
	return ret!
}

export const Fonts_Mockup_Poster_3: Mockup<FontsTemplateMockupParams> = {
	placeholder: <Placeholder className="disabled_custom_color" />,
	getMockupImage,
	mockupId,
	description: `
	The mockup displays a rectangular poster attached to a minimalist black frame structure. The poster has a dark green background with bright teal geometric elements, creating a striking contrast.

- In the top left corner, there are three small teal circles arranged vertically.
- The right side of the poster includes a large teal semi-circle shape partially visible.
- The lower portion of the poster features a series of teal triangular shapes, with one triangle overlaying another to create a layered and dynamic look.

The overall design is modern and bold, with a clear emphasis on geometric shapes and contrasting colors to attract attention.`,
}
