/* eslint-disable max-statements */
/* eslint-disable max-len */
/* eslint-disable max-lines-per-function */
import * as PIXI from "pixi.js"
import { FontsBrandingMockupID } from "deblank-api-types"
import { MockupUtils } from "@/utils/mockupBuilder/utils/MockupUtils"
import { GetMockupImageProps, Mockup } from "@/utils/mockupBuilder/types/MockupTypes"
import { FontsTemplateMockupParams } from "@/utils/mockupBuilder/types/FontsMockupTypes"
import Background from "./images/background.png"
import Logo from "./images/logo.png"
import ShadowSign from "./images/shadows_sign.png"
import Sign from "./images/sign.png"
import SignMask from "./images/sign_mask.png"
import ShadowLight from "./images/shadows_light.png"
import Placeholder from "./images/placeholder.svg"
import React from "react"

//------- DESCRIPTION ------- //
//- Figma link: https://www.figma.com/file/DYWqSJfZnrSqdOmweKf6Qk/FontMuse-(new)?type=design&node-id=745-1324

const mockupId = FontsBrandingMockupID.fonts_branding_mockup_2
const mockupWidth = 2110
const mockupHeight = 1583

const getMockupImage = async (props: GetMockupImageProps<FontsTemplateMockupParams>) => {
	const ret = await props.semaphore.add(async () => {

		const [
			backgroundSprite,
			shadowSignSprite, signMaskSprite,
			signSprite, logoSprite,
			lightSprite,
		] = await MockupUtils.loadAssets([
			Background, ShadowSign, SignMask,
			Sign, Logo, ShadowLight,])

		// Create mockup container
		const signWidth = 440.5
		const textPadding = 40
		const mockupContainer = new PIXI.Container()
		mockupContainer.width = mockupWidth
		mockupContainer.height = mockupHeight
		const mockupShape = MockupUtils.drawRect({
			color: "#FFFFFF",
			width: mockupWidth,
			height: mockupHeight,
		})
		mockupContainer.addChild(mockupShape)

		props.app.stage.addChild(mockupContainer)

		// Background
		mockupContainer.addChild(backgroundSprite)

		// Sign design
		const signContainer = new PIXI.Container()
		const signShape = MockupUtils.drawRect({
			color: "#FFFFFF",
			width: signWidth,
			height: signWidth,
		})
		signContainer.addChild(signShape)
		const logoContainer = new PIXI.Container()
		const logoShape = MockupUtils.drawRect({
			color: "#080F0F",
			width: signWidth,
			height: signWidth,
		})
		logoContainer.addChild(logoSprite)
		logoContainer.mask = logoSprite
		logoContainer.addChild(logoShape)
		signContainer.addChild(logoContainer)
		logoContainer.position.set((signWidth - logoSprite.width) / 2, 171)

		// Sign text
		const textContainer = new PIXI.Container()
		const text = await MockupUtils.createText({
			text: "GULP",
			fontFamily: props.params.title.font,
			fontWeights: props.params.title.weights,
			fontWeight: 900,
			color: "#080F0F",
			fontSize: 100,
			padding: 4,
			fitText: {
				respectTextMaxSize: true,
				containerWidth: signWidth - (textPadding * 2),
			},

		})
		text.position.set((signWidth - text.width) / 2, 71)
		textContainer.addChild(text)
		signContainer.addChild(textContainer)

		// Sign border

		const signBorderContainer = new PIXI.Container()
		const signBorderShape = MockupUtils.drawRect({
			color: "#505050",
			width: mockupWidth,
			height: mockupHeight,
		})
		signBorderContainer.mask = signMaskSprite
		signBorderContainer.addChild(signBorderShape)

		// Projection

		const signProjection = MockupUtils.project({
			app: props.app,
			container: signContainer,
			projectionPoints: {
				topLeft: { x: 688.5, y: 271.5, },
				topRight: { x: 1510, y: 453.65, },
				bottomRight: { x: 1532, y: 1262, },
				bottomLeft: { x: 671.83, y: 1102, },
			},
		})

		// Apply mask

		const posterMask = new PIXI.Container()
		posterMask.addChild(signProjection)

		lightSprite.blendMode = PIXI.BLEND_MODES.SCREEN
		shadowSignSprite.blendMode = PIXI.BLEND_MODES.MULTIPLY
		signSprite.blendMode = PIXI.BLEND_MODES.MULTIPLY

		mockupContainer.addChild(lightSprite)
		mockupContainer.addChild(posterMask)
		mockupContainer.addChild(signBorderContainer)
		mockupContainer.addChild(signSprite)
		mockupContainer.addChild(shadowSignSprite)

		const imageData = MockupUtils.exportImage({
			app: props.app,
			container: mockupContainer,
			height: mockupHeight,
			width: mockupWidth,
			imageCosts: null,
		})

		return imageData
	})
	return ret!

}

export const Fonts_Mockup_Branding_2: Mockup<FontsTemplateMockupParams> = {
	placeholder: <Placeholder className="disabled_custom_color" />,
	getMockupImage,
	mockupId,
	description: `
	The mockup displays a square sign mounted to the exterior wall of a building. The sign features a black frame with a white face. At the center of the sign is a line drawing depicting a person riding a bicycle with a dog perched on the back. The illustration is simple and evocative, providing a playful and lively visual element. The scene is set against a clean background, giving it a modern and minimalistic appearance.`,
}
