import React, { forwardRef, useContext, } from "react"
import Styles from "./PromptToolbar.module.scss"
import ButtonIcon from "@/components/Buttons/ButtonIcon/ButtonIcon"
import AirplaneIcon from "deblank-common/src/assets/images/icon-library/send.svg"
import CrossIcon from "deblank-common/src/assets/images/icon-library/x.svg"
import TextArea from "@/components/Form/TextArea/TextArea"
import classNames from "classnames"
import { selectorsUITemporary } from "@/recoil/Ui/Temporary/Selectors"
import { useRecoilState, useRecoilValue } from "recoil"
import { settersUiTemporary } from "@/recoil/Ui/Temporary/Setters"
import ThumbSelector from "./ThumbSelector/ThumbSelector"
import { selectorsConversations } from "@/recoil/ConversationsRecord/Selectors/Conversations"
import { selectorsMessages } from "@/recoil/ConversationsRecord/Selectors/Messages"
import { settersAssistantResponseTools } from "@/recoil/ConversationsRecord/Setters/AssistantResponseTools"
import { useConversationHandlers } from "@/hooks/useConversationHandlers"
import GradientLoadingAnimation from "@/components/GradientLoadingAnimation/GradientLoadingAnimation"
import { selectorsIntroQuestions } from "@/recoil/ConversationsRecord/Selectors/CurrentIntroQuestions"
import { TrackConversationEventContext } from "@/pages/AssistantPage/TrackConversationEventProvider"
import { introQuestions } from "deblank-api-types"
import { useWindowSize } from "@/hooks/useWindowsSize"
import { selectorsCommon } from "@/recoil/ConversationsRecord/Selectors/Common"

export type PromptAssistant = {
	prompt: string,
}

export type PromptToolbarHandle = {
	sendPrompt: (value: PromptAssistant) => void,
}

const PromptToolbar = forwardRef<PromptToolbarHandle>(() => {

	const conversationHandlers = useConversationHandlers()
	const { trackConversationEvent, } = useContext(TrackConversationEventContext)
	const [prompt, setPrompt,] = useRecoilState(selectorsUITemporary.userPromptState)
	const isPinnedListOpen = useRecoilValue(selectorsUITemporary.isPinnedListOpen)
	const isSidebarOpen = useRecoilValue(selectorsUITemporary.isSidebarOpen)
	const someMessageIsLoading = useRecoilValue(selectorsMessages.someMessageIsLoading)
	const isSavingNewConversationName = useRecoilValue(selectorsConversations.isSavingNewConversationName)
	const loadConversationState = useRecoilValue(selectorsConversations.loadConversationState)
	const outputIdSelected = useRecoilValue(selectorsUITemporary.userPromptOutputIdSelected)
	const currentIntroQuestion = useRecoilValue(selectorsIntroQuestions.currentIntroQuestion)

	const isColorsPlaygroundOpen = !!useRecoilValue(selectorsCommon.colorPlaygroundWidget)
	const isFontsPlaygroundOpen = !!useRecoilValue(selectorsCommon.fontPlaygroundWidget)
	const isImageModalOpen = !!useRecoilValue(selectorsCommon.imageModalWidget)

	const addPendingMessage = settersAssistantResponseTools.useAddPendingMessageToConversation()
	const showNewChat = useRecoilValue(selectorsConversations.showNewChat)
	const setOutputIdSelected = settersUiTemporary.useSetOutputIdSelected()

	const windowsSize = useWindowSize()
	const isMobile = windowsSize.isMobile || windowsSize.isTablet

	const handleClearOutputIdSelected = () => {
		setOutputIdSelected({
			outputIdSelected: null,
		})
	}

	const handleOnSend = async () => {
		if (showNewChat) {
			await conversationHandlers.onCreateNewConversation({
				userMessage: prompt,
				currentIntroQuestion: 0,
			})
		} else {
			addPendingMessage({
				userMessage: prompt,
				idRefs: outputIdSelected ? [outputIdSelected,] : undefined,
			})
		}

		if (currentIntroQuestion !== null) {
			trackConversationEvent({
				eventName: "IntroductionQuestionEvent",
				question: introQuestions[currentIntroQuestion].question,
				answer: prompt,
			})
		}

		setPrompt("")
		handleClearOutputIdSelected()
	}

	const handleOnChangePrompt = (value: string) => {
		setPrompt(value)
	}

	const renderActions = () => {

		const sanitizedPrompt = prompt.replace(/(\r\n|\n|\r)/g, "").trim()
		const isDisabled = sanitizedPrompt.length === 0
		return (
			<ButtonIcon
				type="button"
				iconSVGComponent={AirplaneIcon}
				onClick={handleOnSend}
				disabled={isDisabled || someMessageIsLoading || isSavingNewConversationName}
			/>
		)
	}

	const wrapperClasses = classNames(
		Styles.wrapper, {
		[Styles.with_selected_outputs]: outputIdSelected,
	})

	const containerClasses = classNames(
		Styles.container,
	)

	const RenderSelectedOutputs = () => {
		return (
			<>

				<section className={Styles.selected_outputs_header}>
					<p className={Styles.selected_outputs_title}>
						Send to chat
					</p>
					<span className={Styles.selected_outputs_separator} />
					<ButtonIcon type="button"
						iconSVGComponent={CrossIcon}
						onClick={handleClearOutputIdSelected}
						customStyles={{
							variant: "ghost",
						}}
					/>
				</section>
				<span className={Styles.selected_outputs_divider} />
				<section className={Styles.selected_content_widgets}>
					<ThumbSelector idRef={outputIdSelected!} />
				</section>
			</>
		)
	}

	const disabledTextArea = someMessageIsLoading
		|| loadConversationState
		|| ((isPinnedListOpen || isSidebarOpen) && isMobile)
		|| (isColorsPlaygroundOpen || isFontsPlaygroundOpen || isImageModalOpen)

	return (
		<>
			<section className={wrapperClasses}>
				{outputIdSelected && RenderSelectedOutputs()}
				<section className={containerClasses}>
					{someMessageIsLoading && <GradientLoadingAnimation />}
					<div className={Styles.input_container}>
						<TextArea
							placeholder={currentIntroQuestion === null
								? "Your message to the assistant" : "Enter your answer"}
							value={prompt}
							onChange={handleOnChangePrompt}
							onEnterKeyDown={handleOnSend}
							disabled={disabledTextArea}
							truncateToOneLine={false}
							maxLength={500}
							triggerFocusDeps={[!someMessageIsLoading, showNewChat, !!outputIdSelected,]}
						/>
					</div>
					<section className={Styles.actions_container} >
						<div className={Styles.primary_actions_container}>
							{renderActions()}
						</div>
					</section>
				</section>
			</section>
		</>
	)
})

PromptToolbar.displayName = "PromptToolbar"

export default PromptToolbar
