/* eslint-disable max-statements */
/* eslint-disable max-len */
/* eslint-disable max-lines-per-function */
import React from "react"
import * as PIXI from "pixi.js"
import { ColorPackagingMockupID } from "deblank-api-types"
import { MockupUtils } from "@/utils/mockupBuilder/utils/MockupUtils"
import { GetMockupImageProps, Mockup, OpenAiCosts } from "@/utils/mockupBuilder/types/MockupTypes"
import { ColorsTemplateMockupParams, } from "@/utils/mockupBuilder/types/ColorsMockupTypes"
import { Mockup_SVG } from "./Mockup_SVG"
import { ColorUtils } from "@/utils/mockupBuilder/utils/ColorUtils"
import BottomDesignMask from "./images/bottom_design_mask.png"
import BottomStripeMask from "./images/bottom_stripe_mask.png"
import Lights from "./images/lights.png"
import Shadows from "./images/shadows.png"
import TopDesignMask from "./images/top_design_mask.png"
import TopStripeMask from "./images/top_stripe_mask.png"
import Placeholder from "./images/placeholder.svg"

// ------- DESCRIPTION ------- //
//- Figma link: https://www.figma.com/file/DYWqSJfZnrSqdOmweKf6Qk/FontMuse-(new)?type=design&node-id=822-1351&mode=design&t=QDo8iGvib0i6nIP2-0

const SCALE = 0.5

const mockupWidth = 3000 * SCALE
const mockupHeight = 2250 * SCALE


const mockupId = ColorPackagingMockupID.colors_packaging_mockup_2

type ColorAssignmentType = {
	backgroundColor: string,
	topDesign: { color1: string, color2: string, color3: string, color4: string, color5: string, background: string, },
	bottomDesign: { color1: string, color2: string, color3: string, color4: string, color5: string, background: string, },
}

const getColors = async (params: {
	mockupParams: ColorsTemplateMockupParams,
	minColors: number,
}): Promise<{ colorAssignment: ColorAssignmentType, openAiCosts: OpenAiCosts, }> => {
	const { getColorPalette, getBackgroundColor, } = ColorUtils
	const { getGroupedColors, type, ...colorPalette } = params.mockupParams
	const { usage, ...colorP } = colorPalette
	const colors = Object.values(colorP).filter(c => c)

	const topBackgroundColor = getBackgroundColor({
		colorPalette: colors,
		backgroundLuminance: 1,
	})

	const topDesignColorPalette = getColorPalette({
		colors: colors,
		backgroundColor: topBackgroundColor,
		minColors: params.minColors,
	})


	const topColorPaletteWithoutContrastColor = topDesignColorPalette.filter((color) => color !== topBackgroundColor)

	const topPalettePromise = getGroupedColors({
		palette: topColorPaletteWithoutContrastColor,
		imageSvg: Mockup_SVG.simpleDesignSvg,
		backgroundColor: topBackgroundColor,
		numberOfGroups: params.minColors,
	},
	)

	const bottomBackgroundColor = getBackgroundColor({
		colorPalette: colors,
		backgroundLuminance: 0,
	})

	const bottomDesignColorPalette = getColorPalette({
		colors: colors,
		backgroundColor: bottomBackgroundColor,
		minColors: params.minColors,
	})

	const bottomColorPaletteWithoutContrastColor = bottomDesignColorPalette.filter((color) => color !== bottomBackgroundColor)

	const bottomPalettePromise = getGroupedColors({
		palette: bottomColorPaletteWithoutContrastColor,
		imageSvg: Mockup_SVG.simpleDesignSvg,
		backgroundColor: bottomBackgroundColor,
		numberOfGroups: params.minColors,
	},
	)

	const [topPalette, bottomPalette,] = await Promise.all([topPalettePromise, bottomPalettePromise,])

	const colorsWithoutTopSprite = colors.filter(c => c !== topPalette.groupA!)
	const colorsWithoutBottomSprite = colorsWithoutTopSprite.filter(c => c !== bottomPalette.groupA!)

	const backgroundColor = getBackgroundColor({
		colorPalette: colorsWithoutBottomSprite,
		backgroundLuminance: 1,
	})

	return {
		colorAssignment: {
			backgroundColor,
			topDesign: {
				color1: topPalette.groupD!,
				color2: topPalette.groupA!,
				color3: topPalette.groupC!,
				color4: topPalette.groupB!,
				color5: topBackgroundColor,
				background: topBackgroundColor,
			},
			bottomDesign: {
				color1: bottomPalette.groupD!,
				color2: bottomPalette.groupA!,
				color3: bottomPalette.groupC!,
				color4: bottomPalette.groupB!,
				color5: bottomBackgroundColor,
				background: bottomBackgroundColor,
			},
		},
		openAiCosts: {
			completion_cost: topPalette.usage!.completion_cost + bottomPalette.usage!.completion_cost,
			prompt_cost: topPalette.usage!.prompt_cost + bottomPalette.usage!.prompt_cost,
		},

	}

}

const getMockupImage = async (props: GetMockupImageProps<ColorsTemplateMockupParams>) => {

	const { app, semaphore, } = props
	const ret = await semaphore.add(async () => {
		const { colorAssignment, openAiCosts, } = await getColors({
			mockupParams: props.params,
			minColors: 5,
		})

		const [bottomDesignMaskSprite, topDesignMaskSprite,
			shadowSprite, lightsSprite, bottomStripeMaskSprite,
			topStripeMaskSprite,] = await MockupUtils.loadAssets([
				BottomDesignMask, TopDesignMask, Shadows, Lights,
				BottomStripeMask, TopStripeMask,])


		// Create mockup container
		const mockupContainer = new PIXI.Container()
		app.stage.addChild(mockupContainer)

		mockupContainer.width = mockupWidth
		mockupContainer.height = mockupHeight

		const maskGraphics = MockupUtils.drawRect({
			color: "#FFFFFF",
			width: mockupWidth,
			height: mockupHeight,
		})

		// Use the rounded rectangle as a mask for the container
		mockupContainer.addChild(maskGraphics)
		mockupContainer.mask = maskGraphics

		// Add Background
		const backgroundShape = MockupUtils.drawRect({
			color: colorAssignment.backgroundColor,
			width: mockupWidth,
			height: mockupHeight,
		})
		mockupContainer.addChild(backgroundShape)

		// Add top stripe
		const topStripeContainer = new PIXI.Container()
		const topStripeTexture = await PIXI.Texture.fromURL(Mockup_SVG.topStripeSVG({ color: colorAssignment.topDesign.color2, }))
		const topStripeSprite = new PIXI.Sprite(topStripeTexture)
		topStripeContainer.addChild(topStripeSprite)

		const topStripe = new PIXI.Container()

		const topStripeProjection = MockupUtils.project({
			app: app,
			container: topStripeContainer,
			projectionPoints: {
				topLeft: { x: 0, y: 0, },
				topRight: { x: 1500, y: 0, },
				bottomRight: { x: 1500, y: 1125, },
				bottomLeft: { x: 0, y: 1125, },
			},
		})

		topStripe.addChild(topStripeProjection)
		topStripe.addChild(topStripeMaskSprite)
		topStripe.mask = topStripeMaskSprite

		mockupContainer.addChild(topStripe)

		//Add bottom stripe

		const bottomStripeContainer = new PIXI.Container()
		const bottomStripeTexture = await PIXI.Texture.fromURL(Mockup_SVG.bottomStripeSVG({ color: colorAssignment.bottomDesign.color2, }))
		const bottomStripeSprite = new PIXI.Sprite(bottomStripeTexture)
		bottomStripeContainer.addChild(bottomStripeSprite)

		const bottomStripe = new PIXI.Container()

		const bottomStripeProjection = MockupUtils.project({
			app: app,
			container: bottomStripeContainer,
			projectionPoints: {
				topLeft: { x: 0, y: 0, },
				topRight: { x: 1500, y: 0, },
				bottomRight: { x: 1500, y: 1125, },
				bottomLeft: { x: 0, y: 1125, },
			},
		})
		bottomStripe.addChild(bottomStripeProjection)
		bottomStripe.addChild(bottomStripeMaskSprite)
		bottomStripe.mask = bottomStripeMaskSprite

		mockupContainer.addChild(bottomStripe)

		//Add designs
		const generateDesignContainer = async (colors: { color1: string, color2: string, color3: string, color4: string, color5: string, background: string, },) => {
			const designContainer = new PIXI.Container()
			const designTexture = await PIXI.Texture.fromURL(Mockup_SVG.designSVG(colors))
			const designSprite = new PIXI.Sprite(designTexture)
			designContainer.addChild(designSprite)
			return designContainer
		}

		const topDesignContainer = await generateDesignContainer(colorAssignment.topDesign)
		const bottomDesignContainer = await generateDesignContainer(colorAssignment.bottomDesign)

		const topDesignProjection = MockupUtils.project({
			app: app,
			container: topDesignContainer,
			projectionPoints: {
				topLeft: { x: 1118 * SCALE, y: 234.6 * SCALE, },
				topRight: { x: 2179 * SCALE, y: 796.1 * SCALE, },
				bottomRight: { x: 1912 * SCALE, y: 1304 * SCALE, },
				bottomLeft: { x: 852 * SCALE, y: 742 * SCALE, },
			},
		})

		const topDesign = new PIXI.Container()
		topDesign.addChild(topDesignProjection)
		topDesign.addChild(topDesignMaskSprite)
		topDesign.mask = topDesignMaskSprite

		mockupContainer.addChild(topDesign)

		const bottomDesignProjection = MockupUtils.project({
			app: app,
			container: bottomDesignContainer,
			projectionPoints: {
				topLeft: { x: 1094 * SCALE, y: 963.4 * SCALE, },
				topRight: { x: 2154 * SCALE, y: 1524.45 * SCALE, },
				bottomRight: { x: 1890 * SCALE, y: 2030 * SCALE, },
				bottomLeft: { x: 828 * SCALE, y: 1468 * SCALE, },
			},
		})

		const bottomDesign = new PIXI.Container()
		bottomDesign.addChild(bottomDesignProjection)
		bottomDesign.addChild(bottomDesignMaskSprite)
		bottomDesign.mask = bottomDesignMaskSprite

		mockupContainer.addChild(bottomDesign)

		//Add Shadows
		shadowSprite.blendMode = PIXI.BLEND_MODES.MULTIPLY
		mockupContainer.addChild(shadowSprite)

		//  Add Lights
		lightsSprite.blendMode = PIXI.BLEND_MODES.SCREEN
		mockupContainer.addChild(lightsSprite)


		const imageData = MockupUtils.exportImage({
			app: app,
			container: mockupContainer,
			width: mockupWidth,
			height: mockupHeight,
			imageCosts: openAiCosts,
		})

		return imageData
	})
	return ret!


}

export const Color_Mockup_Packaging_2: Mockup<ColorsTemplateMockupParams> = {
	placeholder: <Placeholder className="disabled_custom_color" />,
	getMockupImage,
	mockupId,
	description: `
	The mockup displays two packaged snack bars laid out diagonally in parallel, each with distinct designs.
The top bar features the brand name "Bies" prominently, along with the text "NUT BUTTER". It also has an abstract, organic pattern and a small circular label. The bottom bar, positioned parallel to the top one, has a similar design with the same brand name and text, but in a contrasting color scheme. Both bars have a sleek, modern look with their text and patterns standing out against solid backgrounds. The overall setting suggests a dynamic and visually appealing design for the product.`,
}
