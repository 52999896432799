/* eslint-disable max-lines-per-function */
/* eslint-disable max-len */
import { useEffect, useRef, useState } from "react"
import { Mockup, MockupParams, MockupParamsType } from "./types/MockupTypes"
import MockupBuilder from "./MockupBuilder"
import { FontsTemplateMockupParams } from "./types/FontsMockupTypes"

export type GenerateMockupParams<P extends MockupParams> = {
	mockupParams: P,
	mockups: Mockup<P>[],
}

export const useHandleMockupImages = <P extends MockupParams>() => {
	const [mockupBuilderRef, setMockupBuilderRef,] = useState<MockupBuilder | null>(null)

	const [loadingImages, setLoadingImages,] = useState(false)
	const [errorImages, setErrorImages,] = useState(false)
	const fontsPreloadTimeoutRef = useRef<NodeJS.Timeout | null>(null)

	useEffect(() => {
		setMockupBuilderRef(new MockupBuilder())

		return () => {
			if (mockupBuilderRef) {
				mockupBuilderRef.destroy()
			}
			if (fontsPreloadTimeoutRef.current) {
				clearTimeout(fontsPreloadTimeoutRef.current)
			}
		}
	}, [])

	const generateMockupImages = async (params: GenerateMockupParams<P>) => {
		try {
			if (params.mockupParams.type === MockupParamsType.Fonts) {
				// When is a font mockup, preload the fonts by using the fonts inside PIXI before generating the images.
				fontsPreloadTimeoutRef.current = setTimeout(async () => {
					await mockupBuilderRef!.preloadFonts({
						mockupParams: params.mockupParams as FontsTemplateMockupParams,
					})
				}, 3000)

			}

			const { done, } = mockupBuilderRef!.getMockupImages<P>({
				mockupParams: params.mockupParams,
				setLoading: setLoadingImages,
				setError: setErrorImages,
				mockups: params.mockups.map(mockup => {
					return {
						getImage: mockup.getMockupImage,
						mockupId: mockup.mockupId,
						mockupDescription: mockup.description,
					}
				}),
			})

			const images = await done

			return images
		} catch (e) {
			setLoadingImages(false)
			console.error(e)
		}
	}

	return {
		loadingImages,
		errorImages,
		generateMockupImages,
		isMockupBuilderReady: !!mockupBuilderRef,
	}

}
