
import chroma from "chroma-js"

/**
 * Decides between black or white
 * color depending on the background.
 *
 * @param bgColor - The hex color to check against
 * @returns Black or white depending on the result
 */
function getForegroundColor(bgColor: string): string {
	// Makes the input a six-character code
	if (bgColor.length === 4) {
		// eslint-disable-next-line no-param-reassign
		bgColor = bgColor + bgColor.slice(1)
	}

	if (bgColor.length === 7) {
		// Converts to RGB
		const r = parseInt(bgColor.slice(1, 3), 16)
		const g = parseInt(bgColor.slice(3, 5), 16)
		const b = parseInt(bgColor.slice(5), 16)

		// Gets YIQ ratio
		const yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000

		// Checks contrast
		return yiq >= 128 ? "#000000" : "#FFFFFF"
	} else {
		throw new Error("Please provide a valid HEX color")
	}
}


const getColorShades = (color: string) => {
	const tints = new Array(10).fill(0)
		.map((_, i) => chroma(color).tint(i / 10)
			.hex()
			.toUpperCase()
		)
		.filter(c => c !== color)

	const shades = new Array(10).fill(0)
		.map((_, i) => chroma(color).shade(i / 10)
			.hex()
			.toUpperCase()
		)
		.filter(c => c !== color)

	const colors = [color.toUpperCase(),]

	while (colors.length < 9) {
		if (tints.length > 0) {
			colors.push(tints.shift())
		}
		if (shades.length > 0) {
			colors.push(shades.shift())
		}
	}

	const orderedColors = [...new Set(colors.map(c => {
		return {
			color: c,
			luminance: chroma(c).luminance(),
		}
	})
		.sort((a, b) => b.luminance - a.luminance)
		.map(c => c.color)
	),]

	return orderedColors
}

type AccessibilityResult = {
	level: "AAA" | "AA" | "FAIL",
	contrast: number,
}

const AAA_THRESHOLD = 7
const AA_THRESHOLD = 4.5

const checkColorAccessibility = (color: string, backgroundColor: string): AccessibilityResult => {
	const contrastRatio = chroma.contrast(color, backgroundColor)

	if (contrastRatio >= AAA_THRESHOLD) {
		return { level: "AAA", contrast: contrastRatio, }
	} else if (contrastRatio >= AA_THRESHOLD) {
		return { level: "AA", contrast: contrastRatio, }
	} else {
		return { level: "FAIL", contrast: contrastRatio, }
	}
}

export const ColorUtils = {
	getForegroundColor,
	getColorShades,
	checkColorAccessibility,
}
