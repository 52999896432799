import React from "react"
import Styles from "./WidgetBubbleUserMessageMockups.module.scss"
import { CommonWidgetMockupsItemProps } from "../WidgetMockupsTypes"
import ImageByWidgetType from "@/components/ImageByWidgetType/ImageByWidgetType"


const WidgetBubbleUserMessageMockups = (props: CommonWidgetMockupsItemProps) => {

	return (
		<figure className={Styles.selected_image}>
			<ImageByWidgetType widgetType={props.widgetType} widgetDetails={props.widgetItemDetails} />
		</figure>
	)
}

export default WidgetBubbleUserMessageMockups
