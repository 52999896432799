import React from "react"
import Styles from "./WidgetBubbleUserMessageImage.module.scss"
import { CommonWidgetImageItemProps } from "../WidgetImagesTypes"
import ImageByWidgetType from "@/components/ImageByWidgetType/ImageByWidgetType"


const WidgetBubbleUserMessageImage = (props: CommonWidgetImageItemProps) => {

	return (
		<figure className={Styles.selected_image}>
			<ImageByWidgetType widgetType={props.widgetType}
				widgetDetails={props.widgetItemDetails}
			/>
		</figure>
	)
}

export default WidgetBubbleUserMessageImage
