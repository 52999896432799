import React from "react"
import Styles from "./BubbleAssistant.module.scss"
import Markdown from "marked-react"
import StarIcon from "deblank-common/src/assets/images/icon-library/star.svg"
import { IdRefWidget } from "deblank-api-types"

type ChatBubbleProps = {
	idRef: IdRefWidget,
	title: string,
	message: string,
}

const ChatBubbleAssistant = (props: ChatBubbleProps) => {

	return (
		<div className={Styles.bubble}
			id={props.idRef.widgetId}>
			<div className={Styles.widget_top_row}>
				<div className={Styles.title_container}>
					<StarIcon />
					<p>{props.title}</p>
				</div>
			</div>

			<div className={Styles.widget_answer_container}>
				<div className={Styles.left_column} />

				<div className={`${Styles.text_response} custom_markdown`}>
					<Markdown>{props.message}</Markdown>
				</div>
			</div>
		</div>
	)
}

export default ChatBubbleAssistant
