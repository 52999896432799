import { HTMLMotionProps, Variants } from "framer-motion"

const variantsLoadingMessage: Variants = {
	hidden: { opacity: 0, y: 20, },
	visible: { opacity: 1, y: 0, },
	exit: { opacity: 0, y: -20, },
}

const transitionLoadingMessage: HTMLMotionProps<"section">["transition"] = {
	duration: 0.2,
	ease: "easeOut",
}

export const loadingMessageProps = {
	variants: variantsLoadingMessage,
	initial: "hidden",
	animate: "visible",
	exit: "exit",
	transition: transitionLoadingMessage,
}

const variantsScrollButton: Variants = {
	hidden: { opacity: 0, },
	visible: { opacity: 1, },
}

const transitionScrollButton: HTMLMotionProps<"section">["transition"] = {
	duration: 0.15,
	ease: "linear",
}

export const scrollButtonProps = {
	variants: variantsScrollButton,
	initial: "hidden",
	exit: "hidden",
	animate: "visible",
	transition: transitionScrollButton,
}
