import { IdRefWidgetWithPaginationItem } from "deblank-api-types"
import { produce } from "immer"
import { useRecoilCallback } from "recoil"
import { atomConversationsRecord } from "../Atom"


const useTogglePinItem = () => {
	return useRecoilCallback(({ set, }) => (params: {
		idRef: IdRefWidgetWithPaginationItem,
	}) => {
		set(atomConversationsRecord, previous => produce(previous, draft => {
			const currentConversation = draft.conversations[draft.activeConversationId!]

			const itemIndex = currentConversation.pinnedItems.findIndex((item) => (
				item.widgetItemId === params.idRef.widgetItemId
			))

			if (itemIndex === -1) {
				currentConversation.pinnedItems.push(params.idRef)
			} else {
				currentConversation.pinnedItems.splice(itemIndex, 1)
			}
			draft.conversationStateSyncStatus = "pendingSave"
		}))
	})
}

export const settersPinned = {
	useTogglePinItem,
}
