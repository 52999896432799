import React, { useContext, useState } from "react"
import Styles from "./WidgetPinnedBrands.module.scss"
import Actions from "../../../Actions/Actions"
import classNames from "classnames"
import usePresetActions from "@/components/Actions/usePresetActions"
import DownloadIcon from "deblank-common/src/assets/images/icon-library/download.svg"
import { TrackConversationEventContext } from "@/pages/AssistantPage/TrackConversationEventProvider"
import { DownloadHelper } from "@/utils/exportHelper"
import { ScreenshotIdManager, WidgetUiSectionId } from "@/utils/ScreenshotIdManager"
import { CommonWidgetBrandsItemProps } from "../WidgetBrandsTypes"
import { widgetAction } from "@/pages/AssistantPage/components/CurrentConversation/MessageList/WidgetSelector/WidgetsTypes"
import { settersCommon } from "@/recoil/ConversationsRecord/Setters/Common"


const WidgetPinnedBrands = (props: CommonWidgetBrandsItemProps) => {

	const [actionsOpen, setActionsOpen,] = useState<boolean>(false)

	const setImageModal = settersCommon.useSetImageModalIdRef()
	const { trackConversationEvent, } = useContext(TrackConversationEventContext)
	const { pinPresetAction, } = usePresetActions({
		idRef: props.idRef,
		widgetType: props.widgetType,
	})

	const handleDownload = async () => {
		const widgetIdDom = ScreenshotIdManager.getElementFromDocument({
			widgetUiSection: WidgetUiSectionId.Pin,
			idRef: props.idRef,
		})!
		await DownloadHelper.fromTemplate(widgetIdDom)
		trackConversationEvent({
			eventName: "Download",
			widgetType: props.widgetType,
			responseId: props.idRef.messageId,
			outputId: props.widgetType,
		})
	}

	const actionList: widgetAction[] = [
		pinPresetAction,
		{
			text: "Download",
			icon: DownloadIcon,
			onClick: handleDownload,
		},
	]

	const brandWrapperClasses = classNames(
		Styles.container,
		"actions_handler",
		{
			[Styles.is_open]: actionsOpen,
		}
	)

	const handleOpenModal = () => {
		trackConversationEvent({
			eventName: "OpenImageModal",
			widgetType: props.widgetType,
			responseId: props.idRef.widgetId,
		})
		setImageModal({ idRef: props.idRef, })
	}

	return (<>
		<section className={brandWrapperClasses}>
			<Actions
				actionList={actionList}
				onSetActionsOpen={setActionsOpen}
				customStyles={{
					actionType: "pinColor",
				}}
			/>
			<p className={Styles.title}>{props.widgetItemDetails.name}</p>
			<section className={Styles.brand_assets}>
				<button
					type="button"
					className={Styles.image_wrapper}
					onClick={handleOpenModal}
				>
					<img className={Styles.image} src={props.widgetItemDetails.logoUrl} alt={props.widgetItemDetails.name} />
				</button>
				<div className={Styles.palette}
					id={
						ScreenshotIdManager.assignId({
							widgetUiSection: WidgetUiSectionId.Pin,
							idRef: props.idRef,
						})
					}
				>
					{
						props.widgetItemDetails.colors.map((color, index) => (
							<span key={`${index}-${color}`}
								className={Styles.palette_item}
								style={{ "--palette_item": color, } as React.CSSProperties}
							/>
						))
					}
				</div>
			</section>

		</section>

	</>
	)
}

export default WidgetPinnedBrands
