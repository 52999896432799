import React, { useState } from "react"
import Styles from "./AccessibilityCard.module.scss"
import FailIcon from "deblank-common/src/assets/images/icon-library/cross.svg"
import SuccessIcon from "deblank-common/src/assets/images/icon-library/check-filled.svg"
import classNames from "classnames"
import { IdRefWidgetWithPaginationItem, EntryAccessibilityColorsResponse, DisplayWidgetType } from "deblank-api-types"
import { ColorUtils } from "@/utils/ColorUtils"
import { useWindowSize } from "@/hooks/useWindowsSize"

type Props = {
	idRef: IdRefWidgetWithPaginationItem,
	widgetData: EntryAccessibilityColorsResponse,
	type: DisplayWidgetType,
}

const AccessibilityCard = (props: Props) => {
	const additionalColors = ["#FFFFFF", "#000000",]
	const [selectedColor, setSelectedColor,] = useState<string>(props.widgetData.palette[0] || "#000000")
	const windowSize = useWindowSize()
	const isMobile = windowSize.isTablet || windowSize.isMobile

	const colorsClasses = classNames(
		Styles.colors_select,
		{
			[Styles.color_selector_full_width]: props.widgetData.palette.length > 8 && !isMobile,
		},
	)

	const renderColorButton = (color: string, index: number, keyPrefix: string) => {
		const colorClasses = classNames(
			Styles.color_button,
			{
				[Styles.selected]: selectedColor === color,
			},
		)
		return (
			<button
				className={colorClasses}
				key={`${keyPrefix}-${index}`}
				onClick={() => setSelectedColor(color)}
				style={{ backgroundColor: color, }}
			/>
		)
	}

	const renderColorChecker = (color: string, index: number, keyPrefix: string) => {

		const colorAccessibility = ColorUtils.checkColorAccessibility(selectedColor, color)
		const textAccessibilityCheck = ColorUtils.checkColorAccessibility(color, "#FFFFFF")

		const resultClasses = classNames(
			Styles.accessibility_result,
			{
				[Styles.text_dark]: textAccessibilityCheck.contrast < 3,
				[Styles.text_light]: textAccessibilityCheck.contrast >= 3,
			},
		)

		return (
			<div
				className={Styles.color}
				key={`${keyPrefix}-${index}`}
				style={{ backgroundColor: color, }}
			>
				<span
					className={Styles.color_text}
					style={{ color: selectedColor, }}
				>
					{isMobile ? "Create." : "Create without limits."}
				</span>
				<div className={resultClasses}>
					<div className={Styles.level}>
						<div className={Styles.icon}>{colorAccessibility.contrast < 4.5 ? <FailIcon /> : <SuccessIcon />}</div>
						{colorAccessibility.level}
					</div>
					<div>{colorAccessibility.contrast.toFixed(2)}</div>
				</div>
			</div>
		)
	}

	return (
		<section className={Styles.container}>
			<section className={Styles.select_color}>
				<span className={Styles.select_text}>Select text color</span>
				<div
					className={colorsClasses}>
					{props.widgetData.palette.map((color, index) =>
						renderColorButton(color, index, "select-accessibility")
					)}
					{!isMobile && <div className={Styles.separator} />}
					{additionalColors.map((color, index) => renderColorButton(color, index, "select-additional"))}
				</div>
			</section>
			<section className={Styles.accessibility_checker_container}>
				<div className={Styles.accessibility_checker_palette}>
					{props.widgetData.palette.map((color, index) =>
						renderColorChecker(color, index, "checker-accessibility"))}
				</div>
				<div className={Styles.accessibility_checker_palette}>
					{additionalColors.map((color, index) => renderColorChecker(color, index, "checker-additional"))}
				</div>
			</section>
		</section>
	)
}

export default AccessibilityCard
